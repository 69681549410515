// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostGenerateSentimentRequest {
  region: 'eu' | 'us';
  userAuth: string;
  resultsPayload: unknown;
  userPrompt: string;
  ablyChannelId: string;
  requestId?: string;
}

export function postGenerateSentimentRequest(
  requestParams: PostGenerateSentimentRequest,
): Request {
  const data = {
    results_payload: requestParams.resultsPayload,
    user_prompt: toSnakeKeys(requestParams.userPrompt),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/generate-sentiment`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
