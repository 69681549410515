import { type GenerateSlideResponse } from '@mentimeter/ai-api-streamer';
import type { Question } from '@mentimeter/http-clients';

export function toCoreQuestion(data: GenerateSlideResponse): Partial<Question> {
  const questionByType: Partial<Question> = toCoreQuestionByType(data);
  const commonQuestionProps: Partial<Question> = {};

  if (data.speaker_notes) {
    commonQuestionProps.speaker_notes = data.speaker_notes;
  }

  return { ...commonQuestionProps, ...questionByType };
}

const toCoreQuestionByType = (
  data: GenerateSlideResponse,
): Partial<Question> => {
  switch (data.type) {
    case 'choices':
      return {
        type: 'choices',
        question: data.content.title,
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item ?? `Option ${i}`,
          correct_answer: false,
        })),
      };
    case 'scales':
      return {
        type: 'scales',
        question: data.content.title,
        choices: data.content.items.map((s, i) => ({
          id: i,
          position: i,
          label: s.item,
          correct_answer: false,
        })),
        dimensions: [
          {
            min: data.content.labels.min,
            max: data.content.labels.max,
            mid_values: [],
          },
          {
            min: data.content.labels.min,
            max: data.content.labels.max,
            mid_values: [],
          },
        ],
      };
    case 'open_ended':
      return {
        type: 'open',
        question: data.content.title,
        question_description: data.content.subtitle ?? '',
      };
    case 'ranking':
      return {
        type: 'ranking',
        question: data.content.title,
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item,
          correct_answer: false,
        })),
      };
    case 'big':
      return {
        type: 'slide',
        slide_type: 'big',
        question: data.content.title,
      };
    case 'paragraph':
      return {
        type: 'slide',
        slide_type: 'paragraph',
        question: data.content.title,
        question_description: data.content.title,
      };
    case 'heading':
      return {
        type: 'slide',
        slide_type: 'heading',
        question: data.content.title,
        question_description: data.content.subtitle,
      };
    case 'wordcloud':
      return {
        type: 'wordcloud',
        question: data.content.title,
      };
    case 'quiz':
      return {
        type: 'quiz',
        question: data.content.title,
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item,
          correct_answer: item.correct,
        })),
      };
    case 'quiz_open':
      return {
        type: 'open',
        question: data.content.title,
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item,
          correct_answer: true,
        })),
      };
    case 'quiz_leaderboard':
      return {
        type: 'quiz_leaderboard',
        question: data.content.title,
      };
    case 'true_or_false':
      return {
        type: 'choices',
        question: data.content.title,
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item,
          correct_answer: item.correct,
        })),
      };
    case 'prioritisation':
      return {
        type: 'prioritisation',
        question: data.content.title ?? '',
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item,
          correct_answer: false,
        })),
      };
    case 'bullets':
      return {
        type: 'slide',
        slide_type: 'bullets',
        question: data.content.title ?? '',
        choices: data.content.items.map((item, i) => ({
          id: i,
          position: i,
          label: item.item,
          correct_answer: false,
        })),
      };
    case 'quote':
      return {
        type: 'slide',
        slide_type: 'quote',
        question: data.content.title,
        question_description: data.content.subtitle ?? 'Unknown',
      };
    default:
      throw new Error('Unknown type', data);
  }
};
