import type { UserT } from '@mentimeter/user';

export const getAiAccess = (
  user: UserT | null,
): {
  canAutoOptIn: boolean;
  enforcedLevel: 'workspace' | 'user' | 'domain';
  authorizedToChangeAiSetting: boolean;
  aiDomainEnabled: boolean;
  aiEnabled: boolean;
} => {
  const aiDomainEnabled = !!user?.features.ai_domain_enabled;

  if (!aiDomainEnabled) {
    return {
      aiDomainEnabled: false,
      canAutoOptIn: false,
      enforcedLevel: 'domain',
      authorizedToChangeAiSetting: false,
      aiEnabled: false,
    };
  }

  const aiEnabled = !!user?.features.ai_enabled;
  const belongsToWorkspace = user?.workspaceId;
  const isWorkspaceOwner = user?.role === 'owner';

  const enforcedLevel = belongsToWorkspace ? 'workspace' : 'user';

  const authorizedToChangeAiSetting = belongsToWorkspace
    ? isWorkspaceOwner
    : true;

  return {
    aiDomainEnabled: true,
    canAutoOptIn: enforcedLevel === 'user',
    enforcedLevel,
    authorizedToChangeAiSetting,
    aiEnabled,
  };
};
