import React from 'react';
import { TrackingContext } from '@mentimeter/http-clients';
import type { IconT } from '@mentimeter/ragnar-visuals';
import { PlusIcon } from '@mentimeter/ragnar-visuals';
import { trackUser } from '@api/tracking/client';
import type { ButtonT, VariantT } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';
import type { Action } from './CreateNew';

interface CreateButtonUIProps {
  children: React.ReactNode;
  iconLeading?: (props: IconT) => React.JSX.Element;
  variant?: VariantT;
  onClick?: (() => void) | undefined;
  href?: string | undefined;
}

export const CreateButtonUI = React.forwardRef(
  (
    {
      href,
      onClick,
      variant,
      iconLeading,
      children,
      badge,
      ...rest
    }: ButtonT & CreateButtonUIProps & { badge?: React.ReactNode },
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <Button
        ref={ref}
        href={href}
        onClick={onClick}
        variant={variant}
        iconLeading={iconLeading || PlusIcon}
        {...rest}
      >
        {children}
      </Button>
    );
  },
);

export function CreateButton({
  action,
  variant = 'tertiary',
  href,
  children,
  testId = '',
  iconLeading = PlusIcon,
  onClick,
}: CreateButtonUIProps & {
  testId?: string;
  action: Action;
}) {
  return (
    <CreateButtonUI
      href={href}
      data-testid={testId}
      onClick={() => {
        trackCreatePresentation(action);
        onClick?.();
      }}
      variant={variant}
      iconLeading={iconLeading || PlusIcon}
    >
      {children}
    </CreateButtonUI>
  );
}

export function trackCreatePresentation(action: Action) {
  trackUser({
    event: 'Clicked create presentation',
    properties: {
      context: TrackingContext.UserHome,
      placement: 'Quick create',
      'shared presentation': false,
    },
  });
  trackUser({
    event: 'Clicked quick create button',
    properties: { context: TrackingContext.UserHome, action },
  });
}
