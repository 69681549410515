export const ENGAGEMENT_TYPES = [
  'Ideation Workshop',
  'Retrospective Workshop',
  'Problem-Solving Workshop',
  'Planning Workshop',
  'Team Building Session',
  'Seminars',
  'Generic Survey',
  'Employee Satisfaction Survey',
  'Event Survey',
  'Assessing Knowledge Survey',
  'Educational Presentation',
  'Persuasive Presentation',
  'Informative Presentation',
  'Informal Presentation',
  'Company All Hands Meeting',
  'Team Meeting',
  'Training',
  'Elevator Pitch',
  'Public Engagement',
  'Team pulse check-in',
  'Status check-in',
  'General Knowledge Quiz',
  'Educational Quiz',
  'Corporate Compliance Quiz',
  'Menti Feature demo',
];
