import { core } from '@mentimeter/http-clients';
import { uploadToS3 } from './s3';
import { getFileWithType } from './utils';

export interface UploadOutput {
  objectKey: string;
  fileId: string;
  fileType: string;
}

export const uploadImageToS3 = async (
  originalFile: File,
): Promise<UploadOutput> => {
  const file = getFileWithType(originalFile);
  const { data } = await core().files.post(file.type);
  const {
    id: fileId,
    presigned_post: { fields, url },
  } = data;
  const { objectKey } = await uploadToS3({ file, url, fields });

  return { fileId, objectKey, fileType: file.type };
};
