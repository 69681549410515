import React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const FelaFieldset = styled('fieldset', {
  displayName: 'Fieldset',
})('width', 'alignItems');

export type FieldsetT = React.ComponentProps<typeof FelaFieldset>;

export function Fieldset({ className, ...rest }: FieldsetT) {
  return <FelaFieldset className={cn(className)} {...rest} />;
}
