'use client';

import { useUser } from '@mentimeter/user';
import { useSearchParams } from '@mentimeter/next-navigation';
import React from 'react';
import { PlanCategory, WorkspaceRoleEnum } from '@mentimeter/http-clients';
import {
  billingRules,
  useBillingHistory,
  useSubscriptions,
} from '@mentimeter/billing';
import {
  PurchaseSuccessModal,
  RequestInvoiceSuccessModal,
} from '@mentimeter/checkout-ui';

const ALLOWED_PLAN_CATEGORIES = [
  PlanCategory.BASIC,
  PlanCategory.BASIC_MONTHLY,
  PlanCategory.PRO,
  PlanCategory.EDUCATIONAL_BASIC,
  PlanCategory.EDUCATIONAL_BASIC_MONTHLY,
  PlanCategory.EDUCATIONAL_PRO,
  PlanCategory.CONFERENCE_SMALL,
  PlanCategory.CONFERENCE_LARGE,
];

export function PurchaseSuccessModalDataHandler() {
  const { user } = useUser();
  const searchParams = useSearchParams();

  // The user can either come from the checkout, or from the invoice request page
  const cbcSuccessParam = searchParams?.get('checkout_success');
  const invoiceRequestSuccessParam = searchParams?.get(
    'invoice_request_success',
  );

  const { receipts } = useBillingHistory();
  const joinMode = searchParams.get('joinMode');
  const { subscriptions } = useSubscriptions();
  const { planCategory, planName } = billingRules(subscriptions);
  const allowedPlanCategories = React.useMemo(
    () => ALLOWED_PLAN_CATEGORIES,
    [],
  );

  const validSubscription =
    planCategory &&
    allowedPlanCategories.includes(planCategory) &&
    user?.role === WorkspaceRoleEnum.OWNER;

  const showCbcModal = Boolean(
    (cbcSuccessParam === 'true' || cbcSuccessParam === 'trial') &&
      validSubscription,
  );
  const showInvoiceRequestModal = Boolean(
    invoiceRequestSuccessParam === 'true' && validSubscription,
  );

  // This is a safe guard to avoid this modal from being rendered if the JoinTeamModal is rendered.
  // That modal is not rendered if joinMode is missing from the search params.
  if (joinMode || !planCategory) return null;

  return (
    <>
      <RequestInvoiceSuccessModal
        open={showInvoiceRequestModal}
        invoiceUrl={receipts?.[0]?.invoice_url}
      />
      <PurchaseSuccessModal
        open={showCbcModal}
        isTrial={Boolean(cbcSuccessParam === 'trial')}
        planCategory={planCategory}
        planName={planName}
        invoiceUrl={receipts?.[0]?.invoice_url}
      />
    </>
  );
}
