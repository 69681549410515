import { Device } from '@mentimeter/ragnar-device';
import {
  FolderIcon,
  GoogleIcon,
  PowerPointIcon,
  StarSolidIcon,
} from '@mentimeter/ragnar-visuals';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { List } from '@mentimeter/ragnar-ui/list';
import { Text } from '@mentimeter/ragnar-ui/text';
import { IMPORT_PAYWALL_MODAL_ID } from './constants';

interface ImportPaywallModalContentProps {
  onUpgradeClick: () => void;
}

export const ImportPaywallModalContent = ({
  onUpgradeClick,
}: ImportPaywallModalContentProps) => {
  return (
    <Box
      flexDirection="row"
      alignItems="stretch"
      id={IMPORT_PAYWALL_MODAL_ID}
      data-testid="import-paywall"
    >
      <Device.Match greaterThan={2}>
        <Box
          bg="primary"
          flex="1"
          position="relative"
          p={4}
          justifyContent="center"
          alignItems="center"
        >
          <BrandIllustration />
          <Box
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            mb={3}
          >
            <Thumbnails
              integrationName="PowerPoint"
              thumbnailIcon={<PowerPointIcon size={4} />}
              mr={3}
            />
            <Thumbnails
              integrationName="Google Slides"
              thumbnailIcon={<GoogleIcon color="secondary" size={4} />}
            />
          </Box>
          <Box
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <Thumbnails
              integrationName="Document"
              thumbnailIcon={<FolderIcon color="secondary" size={4} />}
            />
          </Box>
        </Box>
      </Device.Match>
      <Box flex="1" p={4}>
        <Text
          mb={2}
          fontSize={5}
          lineHeight="heading"
          color="text"
          fontWeight="semiBold"
        >
          {COPY.heading}
        </Text>
        <Text mt={2}>{COPY.description}</Text>
        <Text mt={3}>
          You can import in two ways:
          <List ml={2}>
            <Text as="li" pl={1} ml={3}>
              <strong>Upload</strong>
              {COPY.bullets.upload}
            </Text>
            <Text as="li" pl={1} ml={3}>
              <strong>Connect</strong>
              {COPY.bullets.connect}
            </Text>
          </List>
        </Text>
        <Button
          variant="positive"
          href="/plans"
          target="_blank"
          iconLeading={StarSolidIcon}
          mt={4}
          extend={() => ({
            alignSelf: 'flex-end',
          })}
          onClick={onUpgradeClick}
        >
          {COPY.cta}
        </Button>
      </Box>
    </Box>
  );
};

function BrandIllustration() {
  return (
    <svg
      width={327}
      height={289}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        right: '0',
        bottom: '0',
        transform: 'translate(50%, 50%) scale(1.1) translate(-50%, -50%)',
      }}
    >
      <circle cx="60" cy="60" r="60" fill="#196CFF" />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="204"
        y="166"
        width="123"
        height="123"
      >
        <path fill="#D9D9D9" d="M204 166h123v123H204z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M204 289.5c0-16.218 3.194-32.278 9.401-47.261 6.206-14.984 15.303-28.599 26.771-40.067s25.083-20.565 40.067-26.771C295.222 169.194 311.282 166 327.5 166v41.261c-10.8 0-21.494 2.127-31.471 6.26-9.978 4.133-19.044 10.191-26.681 17.827-7.636 7.637-13.694 16.703-17.827 26.681-4.133 9.977-6.26 20.671-6.26 31.471H204Z"
          fill="#FFB9D0"
        />
        <path
          d="M204 289.5c0-22.92 6.378-45.388 18.421-64.889 12.042-19.501 29.274-35.267 49.766-45.532l18.48 36.891c-13.646 6.836-25.121 17.334-33.14 30.32-8.019 12.986-12.266 27.948-12.266 43.21H204Z"
          fill="#FF80AB"
        />
      </g>
    </svg>
  );
}

function Thumbnails({
  integrationName,
  thumbnailIcon,
  ...props
}: {
  integrationName: string;
  thumbnailIcon: React.JSX.Element;
} & BoxT) {
  return (
    <Box
      bg="primary"
      height={100}
      borderWidth={2}
      borderStyle="solid"
      borderColor="border"
      borderRadius={2}
      alignItems="center"
      justifyContent="center"
      flex="0 0 142px"
      flexDirection="column"
      extend={() => ({
        boxSizing: 'border-box',
      })}
      {...props}
    >
      {thumbnailIcon}
      <Text color="onPrimary" fontWeight="bold" mt={1}>
        {integrationName}
      </Text>
    </Box>
  );
}

const COPY = {
  heading: 'Upgrade to import presentations',
  description: 'Manage and present all your slides from a single place.',
  bullets: {
    upload: ' PDF, PowerPoint or Keynote files',
    connect:
      '  Google Slides or PowerPoint with your Menti - all your animations and effects will run as usual',
  },
  cta: 'See plans',
};
