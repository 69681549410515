export function withTimeout<T>(promise: Promise<T>, ms: number): Promise<T> {
  const timeout: Promise<T> = new Promise((_resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error('Timed out in ' + ms + 'ms.'));
    }, ms);
  });

  return Promise.race([promise, timeout]);
}
