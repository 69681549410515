'use client';

import React, { useState } from 'react';
import { TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { HomeWidget } from '../shared/home-widget';
import { useGuide, type GuideT } from './useGuide';
import { GuideModal } from './GuideModal';
import { useStaticOrder } from './useStaticOrder';
import type { GuideSlugT } from './guides/guides';
import { GuideProgressBar } from './ProgressBar';
import { GuideCard } from './GuideCard';

const GUIDE_READ_ANIMATION_INITIAL_DELAY = 150;
const GUIDE_READ_ANIMATION_STAGGER_DELAY = 600;

export const Guides = () => {
  const { guides, readGuides } = useGuide();
  const [selectedGuide, setSelectedGuide] = useState<GuideT | null>(null);
  const [guideReadQueue, setGuideReadQueue] = useState<GuideSlugT[]>([]);
  const guidesInFixedOrder = useStaticOrder(
    sortGuidesByReadState(guides),
    (a) => a.slug,
  );

  const totalGuidesCount = guides.length;
  const guidesReadCount = guides.filter((a) => a.read).length;
  const guidesReadProgress = guidesReadCount / totalGuidesCount;
  const lastReadGuide =
    sortGuidesByReadState(guides).find((a) => a.readAt)?.readAt ?? '1970';

  return (
    <HomeWidget.Root>
      <HomeWidget.Header
        heading="Get to know Mentimeter"
        shortHeading="Get started"
      >
        <GuideProgressBar
          progress={guidesReadProgress}
          lastReadAt={lastReadGuide}
        />
      </HomeWidget.Header>

      <Box
        width="100%"
        display="grid"
        gridTemplateColumns={[
          '1fr',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(5, 1fr)',
          'repeat(5, 1fr)',
        ]}
        gap={['space4', 'space5']}
        alignItems="stretch"
      >
        {guidesInFixedOrder.map((guide) => (
          <GuideCard key={guide.slug} guide={guide} onRead={openGuide} />
        ))}
      </Box>

      {selectedGuide && (
        <GuideModal
          open
          guide={selectedGuide}
          setOpen={guideModalSetOpen}
          nextGuide={nextGuide}
          isLastGuide={isLastGuide(selectedGuide)}
        />
      )}
    </HomeWidget.Root>
  );

  function guideModalSetOpen(open: boolean) {
    if (open) {
      setSelectedGuide(null);
    } else {
      closeGuideModal();
    }
  }

  function closeGuideModal() {
    readGuides(guideReadQueue.slice(), {
      staggeredBy: GUIDE_READ_ANIMATION_STAGGER_DELAY,
      delay: GUIDE_READ_ANIMATION_INITIAL_DELAY,
    });
    setGuideReadQueue([]);
    setSelectedGuide(null);
  }

  function nextGuide() {
    if (!selectedGuide) return;

    const index = guidesInFixedOrder.findIndex(
      (a) => a.slug === selectedGuide.slug,
    );
    const nextGuide = guidesInFixedOrder[index + 1];
    if (nextGuide) {
      openGuide(nextGuide);
    } else {
      setSelectedGuide(null);
    }
  }

  function openGuide(guide: GuideT) {
    trackUser({
      event: 'Opened quick guide',
      properties: {
        context: TrackingContext.UserHome,
        'guide name': guide.title,
        'guide slug': guide.slug,
        'guides read': Math.min(guidesReadCount + 1, totalGuidesCount),
        'total guides': totalGuidesCount,
        'read all guides': guidesReadProgress === 1,
      },
    });
    setGuideReadQueue([...guideReadQueue, guide.slug]);
    setSelectedGuide(guide);
  }

  function isLastGuide(guide: GuideT) {
    const index = guidesInFixedOrder.findIndex((a) => a.slug === guide.slug);
    return index === guidesInFixedOrder.length - 1;
  }
};

function sortGuidesByReadState(guides: GuideT[]) {
  const unreadGuides = guides.filter((a) => !a.read);
  const readGuides = guides
    .filter((a) => a.read)
    .slice()
    .sort((a, b) => (b.readAt ?? '0').localeCompare(a.readAt ?? '0'));

  return [...unreadGuides, ...readGuides];
}
