// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostMergeSimilarRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** Input to merge similar */
  input: Array<string>;
  strength: number;
  ablyChannelId: string;
  requestId?: string;
}

export function postMergeSimilarRequest(
  requestParams: PostMergeSimilarRequest,
): Request {
  const data = {
    input: toSnakeKeys(requestParams.input),
    strength: toSnakeKeys(requestParams.strength),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/merge-similar`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
