import { useUserJourney } from '@mentimeter/user-journey';
import { useLatestResultsData } from './useLastResults';

export const useActivatedToResultsJourney = () => {
  const { inProgress, fulfilled, steps } = useUserJourney(
    ['SeriesActivated', 'InteractedWithResults'],
    true,
    {
      byPropertyKey: 'series_id',
    },
  );

  const { data: series } = useLatestResultsData(steps[0]?.properties.series_id);

  const inJourney = !fulfilled && inProgress;

  return { inJourney, series };
};
