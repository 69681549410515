import { useState } from 'react';
import { useTranslations } from '@mentimeter/i18n';
import { usePaymentMethod } from '@mentimeter/billing';
import { Box } from '@mentimeter/ragnar-ui/box';
import { CheckItem } from '@mentimeter/ragnar-ui/check-item';
import { Text } from '@mentimeter/ragnar-ui/text';
import { BankDetails } from './BankDetails';
import { InvoiceModal } from './InvoiceModal';

interface RequestInvoiceSuccessModalProps {
  invoiceUrl: string | undefined;
  open: boolean;
}

export function RequestInvoiceSuccessModal({
  invoiceUrl,
  open,
}: RequestInvoiceSuccessModalProps) {
  const t = useTranslations('checkoutUiPackage');
  const [hasDismissed, setHasDismissed] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showCheckedError, setShowCheckedError] = useState(false);

  const { isLoading: isFetchingBankDetails, paymentMethod: bankDetails } =
    usePaymentMethod();

  const toggleChecked = () => {
    if (isFetchingBankDetails) return; // the user should not be able to accept the terms while we are fetching the bank details
    setChecked(!checked);
    if (showCheckedError) setShowCheckedError(false);
  };

  // If we for some reason don't get bank details from the BE
  // we refer to the invoice for the bank details and the content of the modal will be different
  const noBankDetails = !bankDetails && !isFetchingBankDetails;
  const buttonProps = noBankDetails
    ? {
        onOpenChange: (isOpen: boolean) => setHasDismissed(!isOpen),
        primaryButtonText: t('request_invoice_success_modal.download_invoice'),
        onPrimaryClick: () => window.open(invoiceUrl, '_blank'),
      }
    : {
        secondaryButtonText: t(
          'request_invoice_success_modal.download_invoice',
        ),
        onSecondaryClick: () => window.open(invoiceUrl, '_blank'),
        primaryButtonText: t('request_invoice_success_modal.confirm'),
        onPrimaryClick: () => {
          if (checked) {
            setHasDismissed(true);
            setShowCheckedError(false);
          }
          if (!checked) setShowCheckedError(true);
        },
      };

  return (
    <InvoiceModal
      isOpen={open && !hasDismissed}
      title={t('request_invoice_success_modal.title')}
      {...buttonProps}
    >
      <Box gap="space6">
        <Text variant="dashboardBodyMd">
          {t('request_invoice_success_modal.due_info')}
        </Text>
        <BankDetails
          bankDetails={bankDetails}
          isFetchingBankDetails={isFetchingBankDetails}
          noBankDetails={noBankDetails}
        />
        {!noBankDetails && (
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="space3"
              alignItems="center"
            >
              <CheckItem
                name="read-and-understood-check"
                id="read-and-understood-check"
                checked={checked}
                onChange={toggleChecked}
              />
              <Text variant="dashboardBodyMd" onClick={toggleChecked}>
                {t('request_invoice_success_modal.read_and_understood')}
              </Text>
            </Box>
            {showCheckedError && (
              <Text variant="dashboardBodySm" color="negative" ml="space10">
                {t('request_invoice_success_modal.read_and_understood_alert')}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </InvoiceModal>
  );
}
