'use client';

import { trackViewedPage } from '@mentimeter/dashboard-tracking';
import { TrackingContext } from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import { useOnce } from '@mentimeter/react-hooks';
import { usePageAnchor } from '@mentimeter/return-to';

export function HomeClientOnly() {
  const { user } = useUser();

  usePageAnchor('Home', 'back-from-editor');
  useOnce(true, () => {
    trackViewedPage(TrackingContext.UserHome, {
      'workspace role': user?.role,
    });
  });

  return null;
}
