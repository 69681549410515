import type { Layout } from '@mentimeter/http-clients';

type LayoutType = 'default' | 'left' | 'right';

/**
 * Default alignment is vertical and horizontal center.
 */
const LAYOUT_MAP: Record<LayoutType, Layout> = {
  default: {
    alignment_x: 1,
    alignment_y: 1,
    font_size_offset: 0,
    size: '33%',
    type: 'default',
  },
  left: {
    alignment_x: 1,
    alignment_y: 1,
    font_size_offset: 0,
    size: '50%',
    type: 'left',
  },
  right: {
    alignment_x: 1,
    alignment_y: 1,
    font_size_offset: 0,
    size: '50%',
    type: 'right',
  },
};

type VerticalAlignment = 'center-align' | 'left-align' | 'right-align';

const ALIGNMENT_OVERRIDE: Record<VerticalAlignment, Partial<Layout>> = {
  'center-align': {
    alignment_x: 1,
  },
  'left-align': {
    alignment_x: 0,
  },
  'right-align': {
    alignment_x: 2,
  },
};

export const getLayoutProperties = (
  type: LayoutType,
  textVerticalAlignment: VerticalAlignment = 'center-align',
): Layout => {
  return { ...LAYOUT_MAP[type], ...ALIGNMENT_OVERRIDE[textVerticalAlignment] };
};
