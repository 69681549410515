import type { Space } from '@mentimeter/ragnar-dsc';
import type { Responsive } from '@mentimeter/ragnar-styled';
import type React from 'react';

enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

interface BaseEntity {
  [key: string]: any;
}

interface SortOptionsT<Entity extends BaseEntity> {
  sortKey: keyof Entity;
  sortOrder: SortOrderEnum;
  serverSideOrdering: boolean;
  accessor?: (arg0: Entity) => any;
}

interface UseTableT<Entity extends BaseEntity> {
  data?: Entity[];
  keyExtractor: (arg0: Entity) => string;
  defaultSortOptions: SortOptionsT<Entity>;
  shouldRowBeSelectable?: (arg0: Entity) => boolean;
  shouldCheckboxBeDisabled?: (arg0: Entity) => boolean;
  onReachedEndOfTable?: () => void;
  onDnDCombineRows?: ((item: Entity, target: Entity) => void) | undefined;
  getRowIsDroppable?: (item: Entity) => boolean;
}

interface CommonStateT<Entity extends BaseEntity> {
  rows: Row<Entity>[];
  selectedRowItems: Entity[];
  selectedRowKeys: Set<string>;
  toggleRowSelection: (key: string) => void;
  selectRows: (rows: Array<Row<Entity>>) => void;
  deselectRows: (rows: Array<Row<Entity>>) => void;
  sortOptions: SortOptionsT<Entity>;
  setSortOptions: (arg0: SortOptionsT<Entity>) => void;
  onDnDCombineRows?: ((item: Entity, target: Entity) => void) | undefined;
  getRowIsDroppable?: ((item: Entity) => boolean) | undefined;
}

type TableStateT<Entity extends BaseEntity> = CommonStateT<Entity>;

interface TableViewPropsT<Entity extends BaseEntity> {
  columns: Column<Entity>[];
  renderSelectColumn?: boolean | undefined;
  rowHeight?: number | undefined;
  isWaitingForData?: boolean | undefined;
  shouldDisplayUnselectedCheckboxes?: boolean;
  numbersOfPlaceholders?: number | undefined;
  SubHeaderWrapper?: React.FC<{ children: React.ReactNode }> | undefined;
  showHeader?: boolean | undefined;
  subHeaderBg?: string | undefined;
  id?: string | undefined;
  onSort?: ((sortOptions: SortOptionsT<Entity>) => void) | undefined;
  variant?: string;
}

type TablePropsT<Entity extends BaseEntity> = CommonStateT<Entity> &
  TableViewPropsT<Entity>;

interface TableEventPropsT {
  onReachedEndOfTable?: () => void;
}

type TableContextT<Entity extends BaseEntity> = TablePropsT<Entity>;

interface Row<Entity extends BaseEntity> {
  key: string;
  selectable: boolean;
  disabledCheckbox: boolean;
  selected: boolean;
  item: Entity;
}

interface Column<Entity extends BaseEntity> {
  id: string;
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  description?: string;
  width?: Responsive<Space> | undefined;
  matchMediaQuery?: {
    equals?: number;
    lessThan?: number;
    greaterThan?: number;
  };
  sortable: boolean;
  render?: (item: Entity) => React.ReactNode;
}

export { SortOrderEnum };
export type {
  SortOptionsT,
  UseTableT,
  TableStateT,
  TablePropsT,
  TableContextT,
  TableEventPropsT,
  TableViewPropsT,
  Row,
  Column,
  BaseEntity,
};
