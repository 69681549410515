/* eslint-disable menti-react/filename-convention--jsx */
import { Link } from '@mentimeter/next-navigation';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading, Media, Paragraph, Section, Strong } from './styles';

export function HowToPresent() {
  return (
    <Box>
      <Section>
        <Media
          mediaType="video"
          mediaUrl="https://static.mentimeter.com/static/motion/how-to-present.mp4"
        />
      </Section>
      <Section>
        <Heading>Test run your Menti </Heading>
        <Paragraph>
          All set with creating? Get a{' '}
          <Link href="https://help.mentimeter.com/en/articles/5784859-preview-your-presentations">
            preview of your Menti
          </Link>{' '}
          and what the participants see on their devices side-by-side by
          clicking <Strong>Preview</Strong> in the editor.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Press present to show your Menti to your participants</Heading>
        <Paragraph>
          Go full screen and let Mentimeter&apos;s beautiful questions be
          visualized by participant input. In remote meetings, remember to also{' '}
          <Link href="https://help.mentimeter.com/en/articles/3810352-how-to-use-mentimeter-in-a-remote-session">
            share your screen
          </Link>{' '}
          in your video conferencing software.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Press &quot;I&quot; to show joining instructions</Heading>
        <Paragraph>
          Let your audience{' '}
          <Link href="https://help.mentimeter.com/en/articles/3810352-how-to-use-mentimeter-in-a-remote-session">
            join the presentation
          </Link>{' '}
          with ease, using the voting code or QR code. They can now start voting
          on your Menti and the results will show up instantly! Go through your
          slides and experience the real time-engagement.
        </Paragraph>
      </Section>
    </Box>
  );
}
