import { addBreadcrumb } from '@mentimeter/errors/sentry';

type BreadcrumbType = 'default' | 'debug' | 'error' | 'http' | 'info';
type BreadcrumbCategory =
  | 'ai_menti_builder.async.ai.analyze.request'
  | 'ai_menti_builder.async.ai.analyze.response'
  | 'ai_menti_builder.async.ai.create_series.request'
  | 'ai_menti_builder.async.ai.create_series.response'
  | 'ai_menti_builder.async.core.create_series.response'
  | 'ai_menti_builder.generic_error'
  | 'ai_api_streamer.generic_error';

interface Breadcrumb {
  type: BreadcrumbType;
  category: BreadcrumbCategory;
  message: string;
  data: Record<any, any>;
  timestamp: number;
}

const createBreadcrumb = (
  type: BreadcrumbType,
  category: BreadcrumbCategory,
  message: string,
  data: Record<any, any>,
) => {
  const typedBreadcrumb: Breadcrumb = {
    type,
    category,
    message,
    data,
    // get current time
    timestamp: new Date().getTime(),
  };
  addBreadcrumb(typedBreadcrumb);
};

export const aiStreamerErrorBreadcrumb = (
  errorMessage: string,
  data: Record<any, any>,
) => {
  createBreadcrumb(
    'error',
    'ai_api_streamer.generic_error',
    errorMessage,
    data,
  );
};

export const aiBuilderErrorBreadcrumb = (
  errorMessage: string,
  data: Record<any, any>,
) => {
  createBreadcrumb(
    'error',
    'ai_menti_builder.generic_error',
    errorMessage,
    data,
  );
};

export const initialPromptRequestBreadcrumb = (data: Record<any, any>) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.ai.analyze.request',
    'Sent analyze request to Core API',
    data,
  );
};

export const initialPromptResponseBreadcrumb = (data: Record<any, any>) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.ai.analyze.response',
    'Got analyze response from ably stream',
    data,
  );
};

export const followUpPromptRequestBreadcrumb = (data: Record<any, any>) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.ai.analyze.request',
    'Sent analyze request to Core API',
    data,
  );
};

export const followUpPromptResponseBreadcrumb = (data: Record<any, any>) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.ai.analyze.response',
    'Got analyze response from ably stream',
    data,
  );
};

export const buildStepCreateSeriesRequestBreadcrumb = (
  data: Record<any, any>,
) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.ai.create_series.request',
    'Sent create_series request to Core API',
    data,
  );
};

export const buildStepCreateSeriesResponseBreadcrumb = (
  data: Record<any, any>,
) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.ai.create_series.response',
    'Got create_series response from ably stream',
    data,
  );
};

export const coreCreateSeriesResponseBreadcrumb = (data: Record<any, any>) => {
  createBreadcrumb(
    'info',
    'ai_menti_builder.async.core.create_series.response',
    'Got create_series response from Core API',
    data,
  );
};
