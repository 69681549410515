import type { Question } from '@mentimeter/http-clients';
import type { AiApiGeneratedSlideSchema } from '@mentimeter/ai-api-streamer';
import { coreSeriesQuestionSchema } from '../schemas/core-series-question-schema';
import { defaultCoreQuestionFactory } from './default-core-question-factory';
import {
  aiTypeToModuleId,
  aiTypeToQuestionSubType,
  aiTypeToQuestionType,
  aiTypeToSlideType,
} from './ai-to-core-question-model-maps';
import {
  setSuggestedImage,
  setBulletProperties,
  setSkipSlide,
  setChoices,
  setQuestionDescription,
  setReactions,
  setDimensions,
  setRange,
  setVotes,
  setSteps,
  setPinImage,
  setShowCorrect,
} from './question-property-setters';

export function aiApiGeneratedSchemaToCoreQuestionSchema(
  aiSlide: AiApiGeneratedSlideSchema,
): Partial<Question> {
  const coreQuestion = defaultCoreQuestionFactory({
    question: aiSlide.content.title || 'Untitled',
    type: aiTypeToQuestionType(aiSlide.type),
    module_id: aiTypeToModuleId(aiSlide.type),
    slide_type: aiTypeToSlideType(aiSlide.type),
    sub_type: aiTypeToQuestionSubType(aiSlide.type),
  });

  setQuestionDescription(coreQuestion, aiSlide);
  setSuggestedImage(coreQuestion, aiSlide);

  setChoices(coreQuestion, aiSlide);
  setDimensions(coreQuestion, aiSlide);

  setReactions(coreQuestion, aiSlide);

  setBulletProperties(coreQuestion, aiSlide);
  setSkipSlide(coreQuestion, aiSlide);
  setShowCorrect(coreQuestion, aiSlide);

  setRange(coreQuestion, aiSlide);
  setVotes(coreQuestion, aiSlide);
  setSteps(coreQuestion, aiSlide);

  setPinImage(coreQuestion, aiSlide);

  try {
    return coreSeriesQuestionSchema.parse(coreQuestion);
  } catch (parsingError: unknown) {
    throw new Error(`Failed to parse AI slide of type '${aiSlide.type}'`, {
      cause: parsingError,
    });
  }
}

export function transformSlidesFromAiSchemaToCoreQuestionSchema(
  aiApiSlides: AiApiGeneratedSlideSchema[],
  previousSlides: Partial<Question>[],
) {
  const hasAiSlides = aiApiSlides.length > 0;
  const differentAmountOfSlides = previousSlides.length !== aiApiSlides.length;
  if (hasAiSlides && differentAmountOfSlides) {
    const questions = aiApiSlides.map(aiApiGeneratedSchemaToCoreQuestionSchema);
    return [...previousSlides, ...questions];
  }

  return previousSlides;
}
