import type {
  PublicTemplateT,
  PublicTemplatesCategoryT,
} from '@mentimeter/http-clients';
import { useState } from 'react';
import { useSearchParams } from '@mentimeter/next-navigation';
import {
  useApiTemplate,
  useApiTemplateCategories,
} from '@mentimeter/templates-modal/hooks/usePublicTemplates';
import { useOnce } from '@mentimeter/react-hooks';

export type TemplateModalMode =
  | { mode: 'not-ready' }
  | { mode: 'hidden' }
  | { mode: 'show-all' }
  | { mode: 'template'; data: PublicTemplateT }
  | { mode: 'template-category'; data: PublicTemplatesCategoryT };

export const useTemplateModalMode = () => {
  const initialTemplateModalMode = useInitialTemplateModalMode();
  const [selectedTemplateMode, setSelectedTemplateMode] =
    useState<TemplateModalMode>(initialTemplateModalMode);

  useOnce(initialTemplateModalMode.mode !== 'not-ready', () => {
    setSelectedTemplateMode(initialTemplateModalMode);
  });

  return {
    selectedTemplateMode,
    setSelectedTemplateMode,
  };
};

export function useInitialTemplateModalMode(): TemplateModalMode {
  const showTemplateModal = useSearchParams().get('templates');

  const { preselectedTemplate, isLoading: isLoadingTemplate } =
    usePreselectedTemplate();
  const { preselectedCategory, isLoading: isLoadingCategory } =
    usePreselectedCategory();

  if (isLoadingTemplate || isLoadingCategory) {
    return { mode: 'not-ready' };
  }

  if (preselectedTemplate) {
    return { mode: 'template', data: preselectedTemplate };
  }

  if (preselectedCategory) {
    return { mode: 'template-category', data: preselectedCategory };
  }

  if (showTemplateModal === 'true') {
    return { mode: 'show-all' };
  }

  return { mode: 'hidden' };
}

function usePreselectedCategory() {
  const showTemplateCategory = safelyParseNumber(
    useSearchParams().get('template-category-id'),
  );
  const { data: businessCategories, isLoading: isLoadingBusinessCategories } =
    useApiTemplateCategories(!showTemplateCategory, 'business');
  const { data: educationCategories, isLoading: isLoadingEducationCategories } =
    useApiTemplateCategories(!showTemplateCategory, 'education');
  const preselectedCategory = showTemplateCategory
    ? ([...(businessCategories ?? []), ...(educationCategories ?? [])].find(
        (c) => c.id === showTemplateCategory,
      ) ?? null)
    : null;

  return {
    preselectedCategory,
    isLoading: isLoadingBusinessCategories || isLoadingEducationCategories,
  };
}

function usePreselectedTemplate() {
  const preselectedTemplateIdParam = useSearchParams().get('template-id');
  const { data: preselectedTemplate, isLoading } = useApiTemplate(
    safelyParseNumber(preselectedTemplateIdParam),
  );

  return {
    preselectedTemplate,
    isLoading,
  };
}

function safelyParseNumber(templateId: string | null): number | null {
  if (templateId === null) {
    return null;
  }
  const parsedId = parseInt(templateId, 10);
  return isNaN(parsedId) ? null : parsedId;
}
