import type {
  AiApiGeneratedSlideSchema,
  AiApiSlideTypes,
} from '@mentimeter/ai-api-streamer';
import type {
  ModuleId as QuestionModuleId,
  QuestionSlideType,
  QuestionSubType,
  QuestionType,
} from '@mentimeter/http-clients';

const AI_SLIDE_TYPE__TO__CORE_QUESTION_TYPE: {
  [key in AiApiSlideTypes]: QuestionType;
} = Object.freeze({
  wordcloud: 'wordcloud',
  multiple_choice: 'choices',
  open_ended: 'open',
  scales: 'scales',
  ranking: 'ranking',
  pin_on_image: 'pin_on_image',
  heading: 'slide',
  paragraph: 'slide',
  quiz_select_answer: 'quiz',
  quiz_type_answer: 'quiz_open',
  quiz_leaderboard: 'quiz_leaderboard',
  bullets: 'slide',
  prioritisation: 'prioritisation',
  choices: 'choices',
  quiz: 'quiz',
  quiz_open: 'quiz_open',
  quote: 'slide',
  true_or_false: 'choices',
  big: 'slide',
});

export const aiTypeToQuestionType = (
  aiSlideType: AiApiGeneratedSlideSchema['type'],
): QuestionType => AI_SLIDE_TYPE__TO__CORE_QUESTION_TYPE[aiSlideType];

const AI_SLIDE_TYPE__TO__CORE_MODULE_ID_TYPE: {
  [key in AiApiSlideTypes]: QuestionModuleId;
} = Object.freeze({
  wordcloud: 'wordcloud',
  multiple_choice: 'choices',
  open_ended: 'open',
  scales: 'scales',
  ranking: 'ranking',
  pin_on_image: 'pin-on-image',
  heading: 'heading',
  paragraph: 'paragraph',
  quiz_select_answer: 'quiz-choices',
  quiz_type_answer: 'quiz-open',
  quiz_leaderboard: 'quiz-leaderboard',
  bullets: 'bullets',
  quiz: 'quiz-choices',
  prioritisation: '100-points',
  choices: 'choices',
  quote: 'quote',
  quiz_open: 'quiz-open',
  true_or_false: 'choices',
  big: 'big',
});

export const aiTypeToModuleId = (
  aiSlideType: AiApiGeneratedSlideSchema['type'],
): QuestionModuleId => AI_SLIDE_TYPE__TO__CORE_MODULE_ID_TYPE[aiSlideType];

const AI_SLIDE_TYPE__TO__CORE_SLIDE_TYPE: {
  [key in AiApiSlideTypes]: QuestionSlideType;
} = Object.freeze({
  wordcloud: 'slide',
  multiple_choice: 'slide',
  open_ended: 'slide',
  scales: 'slide',
  ranking: 'slide',
  pin_on_image: 'slide',
  heading: 'heading',
  quiz_select_answer: 'slide',
  quiz_type_answer: 'slide',
  quiz_leaderboard: 'slide',
  paragraph: 'paragraph',
  bullets: 'bullets',
  prioritisation: 'slide',
  choices: 'slide',
  quote: 'quote',
  quiz: 'slide',
  quiz_open: 'slide',
  true_or_false: 'heading',
  big: 'big',
});

export const aiTypeToSlideType = (
  aiSlideType: AiApiGeneratedSlideSchema['type'],
): QuestionSlideType => {
  if (aiSlideType in AI_SLIDE_TYPE__TO__CORE_SLIDE_TYPE) {
    return AI_SLIDE_TYPE__TO__CORE_SLIDE_TYPE[aiSlideType];
  }

  // Setting default to slide
  return 'slide';
};

const AI_SLIDE_TYPE__TO__CORE_SLIDE_SUB_TYPE: {
  [key in AiApiSlideTypes]: QuestionSubType;
} = Object.freeze({
  wordcloud: null,
  multiple_choice: 'choices',
  open_ended: 'open',
  scales: 'scales',
  ranking: null,
  pin_on_image: null,
  heading: null,
  paragraph: null,
  quiz_select_answer: null,
  quiz_type_answer: null,
  quiz_leaderboard: null,
  bullets: null,
  prioritisation: null,
  choices: null,
  quote: null,
  quiz: null,
  quiz_open: null,
  true_or_false: 'choices',
  big: null,
});

export const aiTypeToQuestionSubType = (
  aiSlideType: AiApiGeneratedSlideSchema['type'],
): QuestionSubType => {
  if (aiSlideType in AI_SLIDE_TYPE__TO__CORE_SLIDE_SUB_TYPE) {
    return AI_SLIDE_TYPE__TO__CORE_SLIDE_SUB_TYPE[aiSlideType];
  }

  // Setting default to slide sub type
  return null;
};
