import {
  postSlidesRequest,
  postSlidesResponse,
  type PostSlidesRequest,
  type PostSlidesResponse,
} from '@core-api/ai-builder/ai-builder/presentations/{presentation_id}/slides/{slide_id}';
import { MentiError } from '@mentimeter/errors/sentry';
import { getUserAuth } from './user-auth';
import { makeHttpFetchConfigObject } from './http-config';

export async function putImageToCoreSeriesQuestion(
  presentationId: PostSlidesRequest['presentationId'],
  slideId: PostSlidesRequest['slideId'],
  imageFileNameKey: string,
): Promise<PostSlidesResponse> {
  const assetUrl = process.env.NEXT_PUBLIC_STATIC_ASSET_HOST;

  if (!assetUrl) {
    throw new Error('Missing public static asset url');
  }

  const { region, userToken } = getUserAuth();

  const questionImageParams = {
    croppedImageUrl: assetUrl + '/' + imageFileNameKey,
    questionImagePath: imageFileNameKey,
    questionImageEscapedPath: imageFileNameKey,
    questionImageUrl: assetUrl + '/' + imageFileNameKey,
  };

  const response = await fetch(
    postSlidesRequest({
      region,
      presentationId,
      slideId,
      questionImage: questionImageParams,
    }),
    makeHttpFetchConfigObject({ userToken }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError('Failed to attach image to the question', {
      feature: 'user-success',
      cause: error,
    });
  }

  return postSlidesResponse(response);
}
