import {
  CreateWithAi as AiMentiBuilderV2,
  FeatureAtCapacity,
  CreateWithAiV3 as AiMentiBuilderV3,
  type AiMentiBuilderV2SplitValues,
} from '@mentimeter/ai-menti-builder';
import { SSEProvider } from '@mentimeter/server-sent-events';
import React from 'react';
import { TrackingContext } from '@mentimeter/http-clients';
import { CreateWithAiModalLayout } from './CreateWithAiModalLayout';

export const CreateWithAiModal = ({
  version,
  prefillPrompt,
}: {
  version: AiMentiBuilderV2SplitValues;
  prefillPrompt?: string | undefined;
}) => {
  if (version === 'v1') {
    return (
      <CreateWithAiModalLayout>
        <SSEProvider>
          <AiMentiBuilderV2 prefillPrompt={prefillPrompt} />
        </SSEProvider>
      </CreateWithAiModalLayout>
    );
  }

  if (version === 'v2') {
    return (
      <CreateWithAiModalLayout>
        <FeatureAtCapacity trackingContext={TrackingContext.AiMentiBuilder} />
      </CreateWithAiModalLayout>
    );
  }

  if (version === 'v3') {
    return (
      <CreateWithAiModalLayout>
        <SSEProvider>
          <AiMentiBuilderV3 prefillPrompt={prefillPrompt} />
        </SSEProvider>
      </CreateWithAiModalLayout>
    );
  }

  return null;
};
