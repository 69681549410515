import { TONE_OF_VOICE_MAP } from '../constants/tone-of-voice';

export function isToneOfVoice(
  value: string,
): value is keyof typeof TONE_OF_VOICE_MAP {
  return value in TONE_OF_VOICE_MAP;
}

export function getToneOfVoice(value: string) {
  if (!isToneOfVoice(value)) {
    throw new Error(`Invalid tone of voice: ${value}`);
  }
  return TONE_OF_VOICE_MAP[value];
}
