// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostSlidesRequest {
  region: 'eu' | 'us';
  /** Id of of the series */
  presentationId: string;
  /** Id of the question to update */
  slideId: string;
  /** Question title */
  question?: string;
  /** Type of the question */
  type?: string;
  /** Sub type of the question */
  subType?: string;
  /** Question slide type */
  slideType?: string;
  questionImage?: {
    /** Image path */
    questionImagePath: string;
    /** Image url */
    questionImageUrl: string;
    /** Cropped image url */
    croppedImageUrl: string;
    /** Escaped path for the image */
    questionImageEscapedPath: string;
  };
}

export function postSlidesRequest(requestParams: PostSlidesRequest): Request {
  const data = {
    question: toSnakeKeys(requestParams.question),
    type: toSnakeKeys(requestParams.type),
    sub_type: toSnakeKeys(requestParams.subType),
    slide_type: toSnakeKeys(requestParams.slideType),
    question_image: toSnakeKeys(requestParams.questionImage),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-builder/presentations/${requestParams.presentationId}/slides/${requestParams.slideId}`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
