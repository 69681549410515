// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface GetSuggestResponse {
  /** The suggestions */
  suggestions: Array<string>;
}

export async function getSuggestResponse(
  response: Response,
): Promise<GetSuggestResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetSuggestResponse;
}
