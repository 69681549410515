// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostSeriesRequest {
  region: 'eu' | 'us';
  /** Name of the presentation */
  name: string;
  /** Sets up either Presentation or Survey */
  pace: 'audience' | 'presenter';
  /** In case of a specific theme Id */
  themeId?: number;
  /** Public team name, since in different regions they can have different IDs */
  publicThemeName?: string;
  /** Enables comments feature */
  commentsEnabled: boolean;
  /** Enables Q&A feature */
  questionsEnabled: boolean;
  /** Enables reactions feature */
  reactionsEnabled: boolean;
  /** Non-typed questions array */
  questions: Array<unknown>;
}

export function postSeriesRequest(requestParams: PostSeriesRequest): Request {
  const data = {
    questions: requestParams.questions,
    name: toSnakeKeys(requestParams.name),
    pace: toSnakeKeys(requestParams.pace),
    theme_id: toSnakeKeys(requestParams.themeId),
    public_theme_name: toSnakeKeys(requestParams.publicThemeName),
    comments_enabled: toSnakeKeys(requestParams.commentsEnabled),
    questions_enabled: toSnakeKeys(requestParams.questionsEnabled),
    reactions_enabled: toSnakeKeys(requestParams.reactionsEnabled),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-builder/series`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
