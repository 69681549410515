// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostInsightsAssistantRequest {
  region: 'eu' | 'us';
  userAuth: string;
  seriesId: string;
  ablyChannelId: string;
  messages: Array<{
    role: 'user' | 'assistant';
    content: string;
  }>;
  requestId?: string;
}

export function postInsightsAssistantRequest(
  requestParams: PostInsightsAssistantRequest,
): Request {
  const data = {
    series_id: toSnakeKeys(requestParams.seriesId),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    messages: toSnakeKeys(requestParams.messages),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/insights-assistant`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
