import z from 'zod';
import {
  type ModuleId,
  type Question,
  type QuestionType,
  type QuestionSubType,
  type QuestionSlideType,
  VALID_MODULE_IDS,
} from '@mentimeter/http-clients';

const questionType: z.Schema<QuestionType> = z.union([
  z.literal('choices'),
  z.literal('choices_images'),
  z.literal('eurovision'),
  z.literal('open'),
  z.literal('slide'),
  z.literal('pin_on_image'),
  z.literal('prioritisation'),
  z.literal('qfa'),
  z.literal('quiz'),
  z.literal('quiz_open'),
  z.literal('quiz_leaderboard'),
  z.literal('ranking'),
  z.literal('rating'),
  z.literal('scales'),
  z.literal('wordcloud'),
  z.literal('metadata'),
  z.literal('instructions'),
  z.literal('miro'),
]);

const questionSubType: z.Schema<QuestionSubType> = z.union([
  z.literal('bullets'),
  z.literal('cards'),
  z.literal('choices'),
  z.literal('cloud'),
  z.literal('donut'),
  z.literal('dots'),
  z.literal('flow'),
  z.literal('four-values'),
  z.literal('horizontal'),
  z.literal('onebyone'),
  z.literal('open'),
  z.literal('pie'),
  z.literal('scales'),
  z.literal('image'),
  z.literal('spider'),
  z.literal('two-values'),
  z.null(),
]);

const questionSlideType: z.Schema<QuestionSlideType> = z.union([
  z.literal('big'),
  z.literal('bullets'),
  z.literal('document'),
  z.literal('google-slides'),
  z.literal('heading'),
  z.literal('image'),
  z.literal('instructions'),
  z.literal('miro'),
  z.literal('number'),
  z.literal('paragraph'),
  z.literal('powerpoint'),
  z.literal('quote'),
  z.literal('slide'),
  z.literal('video'),
]);

const moduleId: z.Schema<ModuleId> = z.union([
  z.literal('deprecated'),
  z.enum(VALID_MODULE_IDS),
]);

export const coreSeriesQuestionSchema: z.Schema<Partial<Question>> = z
  .object({
    id: z.string(),
    question: z.string(),
    module_id: moduleId,
    question_description: z.string(),
    type: questionType,
    sub_type: questionSubType.nullable(),
    slide_type: questionSlideType,
  })
  .passthrough(); // Opens the schema to extra properties

export type QuestionSchema = z.infer<typeof coreSeriesQuestionSchema>;
