import z from 'zod';
const JSONString = z.string().transform((str, ctx) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
    return z.NEVER;
  }
});

const baseAblyEventSchema = z.object({
  channel: z.string(),
  data: z.any(),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const ablyDataEventSchema = baseAblyEventSchema.extend({
  name: z.literal('data'),
});

const beginSchema = z.object({
  name: z.literal('begin'),
  timestamp: z.number(),
});

const chunkSchema = z.object({
  data: JSONString,
  name: z.union([z.literal('batch'), z.literal('chunk')]),
  encoding: z.literal('json').optional(),
  timestamp: z.number(),
});

const endSchema = z.object({
  name: z.literal('end'),
  timestamp: z.number(),
});

const errorPayloadSchema = z.object({
  message: z.string(),
  code: z.number().optional(),
});

const errorSchema = z.object({
  data: JSONString.pipe(errorPayloadSchema),
  name: z.literal('error'),
  encoding: z.literal('json').optional(),
  timestamp: z.number(),
});

const allEventTypesSchema = z.union([
  ablyDataEventSchema,
  beginSchema,
  chunkSchema,
  endSchema,
  errorSchema,
]);

export const parseSSEEvent = (
  data: string,
  innerPayloadSchema: z.ZodTypeAny,
) => {
  return JSONString.pipe(allEventTypesSchema)
    .transform((d) => {
      /**
       * Parse inner data payload based on the event
       * name and the schema provided from caller
       */
      if (d.name === 'chunk' || d.name === 'batch') {
        return {
          ...d,
          data: innerPayloadSchema.parse(d.data),
        };
      }
      return d;
    })
    .parse(data);
};

export const parseSSEEventBase = (eventRaw: string) =>
  JSONString.pipe(baseAblyEventSchema).parse(eventRaw);

export function parseSSEEventDataWithSchema<Schema extends z.ZodSchema>(
  eventDataRaw: unknown,
  dataSchema: Schema,
): z.infer<Schema> | never {
  if (typeof eventDataRaw === 'string') {
    return JSONString.pipe(dataSchema).parse(eventDataRaw);
  }
  if (typeof eventDataRaw === 'object') {
    return dataSchema.parse(eventDataRaw);
  }

  throw new Error('[SSE Parser] Unsupported data type');
}
