// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostGetSlidesQualityResponse {
  status: string;
  requestId: string;
}

export async function postGetSlidesQualityResponse(
  response: Response,
): Promise<PostGetSlidesQualityResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostGetSlidesQualityResponse;
}
