'use client';

import React from 'react';
import { SparkleSolidIcon, UploadIcon } from '@mentimeter/ragnar-visuals';
import { ImportPaywallDataHandler } from '@mentimeter/paywalls/import-presentation';
import { useUser } from '@mentimeter/user';
import { TrackingContext, TrackingPlacement } from '@mentimeter/http-clients';
import { AiPersonalizedBuilder } from '@mentimeter/ai-personalized-builder';
import { CreateWithAiModal } from 'features/create-with-ai/CreateWithAiModal';
import { CREATE_NEW_HREF } from 'features/dashboard/CreateNewMentiButton';
import { useExperiment } from '@mentimeter/splitio';
import { useMatch } from '@mentimeter/ragnar-device';
import { BadgeWrap, Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { HomeWidget } from '../shared/home-widget';
import { CreateButtonUI } from './CreateButton';
import { CreateWithAIFeatureWrapper } from './CreateWithAi';
import { CreateNewGroup } from './CreateNewGroup';

export type Action =
  | 'Start from scratch'
  | 'Create quiz'
  | 'Create presentation'
  | 'Create survey'
  | 'Import presentation'
  | 'Start with AI';

export function CreateNew() {
  return (
    <HomeWidget.Root>
      <Box
        width="100%"
        flexDirection={['column', 'row']}
        flexWrap="wrap"
        gap="space4"
      >
        <CreateNewGroup />

        <AiPersonalizedBuilderAction />

        <CreateWithAIFeatureWrapper>
          {({ prefillPrompt, version }) => (
            <CreateWithAiModal
              version={version}
              prefillPrompt={prefillPrompt}
            />
          )}
        </CreateWithAIFeatureWrapper>

        <ImportAction />
      </Box>
    </HomeWidget.Root>
  );
}

function AiPersonalizedBuilderAction() {
  const { isInExperiment: aiPersonalizedBuilderEnabled } = useExperiment(
    'AI_Personalized_Builder',
    ['on'],
  );
  const isMobile = useMatch({ lessThan: 3 });

  if (!aiPersonalizedBuilderEnabled || isMobile) {
    return null;
  }

  return (
    <AiPersonalizedBuilder>
      <BadgeWrap badge={<Badge type="beta" compact />}>
        <CreateButtonUI
          width={['100%', 'auto']}
          iconLeading={SparkleSolidIcon}
          variant="outline"
        >
          New Personalized Menti
        </CreateButtonUI>
      </BadgeWrap>
    </AiPersonalizedBuilder>
  );
}

function ImportAction() {
  const { user } = useUser();
  const canImportDocument = user?.features.import_document ?? false;

  return (
    <ImportPaywallDataHandler
      enabled={!canImportDocument}
      trackingContext={TrackingContext.UserHome}
      trackingPlacement={TrackingPlacement.ImportDocumentQuickCreate}
      upgradeEvent="Plans from homeview"
      upgradeContext="Import document quick create button"
    >
      <BadgeWrap
        width={['100%', 'auto']}
        badge={!canImportDocument && <Badge type="upgrade" />}
      >
        <CreateButtonUI
          width="100%"
          iconLeading={UploadIcon}
          href={`${CREATE_NEW_HREF}?import-modal=true`}
          variant="outline"
        >
          Import presentation
        </CreateButtonUI>
      </BadgeWrap>
    </ImportPaywallDataHandler>
  );
}
