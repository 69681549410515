export function toCamelCase(input: string): string {
  if (typeof input !== 'string') {
    return input;
  }
  return input.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

export function transformObjectKeysToCamelCase(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjectKeysToCamelCase(item));
  }

  return Object.keys(obj).reduce<{ [key: string]: any }>((acc, key) => {
    const camelKey = toCamelCase(key);
    acc[camelKey] = transformObjectKeysToCamelCase(obj[key]);
    return acc;
  }, {});
}
