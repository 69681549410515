import useSWR from 'swr';
import type { GetSuggestRequest } from '@core-api/ai-api/ai-api/draft-builder/suggest';
import { getPromptSuggestions } from '@mentimeter/ai-api-streamer';

type IMU = GetSuggestRequest['imu'];

export interface UseSuggestionsArgs {
  imu?: IMU;
  limit?: number;
}

const createSuggestCacheKey = (imu: string = 'business', limit: number = 5) =>
  `suggest-${imu}-${limit}`;

export const useSuggestions = ({
  imu = 'business',
  limit = 5,
}: UseSuggestionsArgs) => {
  return useSWR(
    createSuggestCacheKey(),
    async () => {
      const data = await getPromptSuggestions({ imu, limit });
      return data as { suggestions: string[] };
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      errorRetryCount: 3,
    },
  );
};
