import React from 'react';
import { Badge, BadgeWrap } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

const Attachment = ({ text }: { text: string }) => {
  return (
    <BadgeWrap badge={<Badge text="pasted" type="text" />}>
      <Box
        borderWidth={1}
        borderRadius="2xl"
        alignSelf="start"
        p="space4"
        bg="disabled"
        width="150px"
        height="150px"
        mb="space8"
        overflow="hidden"
      >
        <Box
          overflow="hidden"
          position="relative"
          maxHeight="100%"
          extend={() => ({
            '::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '30px',
              background: `linear-gradient(180deg, rgba(244,244,246,0) 0%, rgba(244,244,246,0.8113839285714286) 52%, rgba(244,244,246,1) 90%)`,
            },
          })}
        >
          <Text variant="dashboardBodyXs">{text}</Text>
        </Box>
      </Box>
    </BadgeWrap>
  );
};

export default Attachment;
