import {
  captureException,
  MentiError,
  captureMessage,
} from '@mentimeter/errors/sentry';
import { uploadImageToS3 } from '@mentimeter/file-uploader';
import { type ImageResource } from '@mentimeter/http-clients';
import { fetchImageFromCore } from '../http/fetch-image';
import { putImageToCoreSeriesQuestion } from '../http/update-question-with-image';
import { withTimeout } from './async-handler';
import { isInteractiveQuestion } from './default-core-question-factory';

export const IMAGE_UPLOAD_TIMEOUT_IN_MS = 30_000;

export const getImageAsset = (url: string): ImageResource => {
  const presetObject = { url };
  return {
    url,
    presets: {
      huge: presetObject,
      large: presetObject,
      medium: presetObject,
      preview: presetObject,
      small: presetObject,
      source: presetObject,
      original: presetObject,
    },
    _object: '',
    _url: '',
    id: '',
    alt_description: null,
    crop: null,
  };
};

export const uploadAndSaveImage = async (
  seriesId: string,
  question: { cropped_image_url: string | null | undefined; id: string },
) => {
  try {
    if (isInteractiveQuestion(question)) {
      return;
    }

    if (!question.cropped_image_url) {
      throw new Error('Missing image url');
    }

    let image;
    try {
      image = await fetchImageFromCore(question.cropped_image_url);
    } catch (fetchError) {
      captureException(
        new MentiError('Failed to fetch image from core:', {
          cause: fetchError,
          feature: 'ai-menti-builder',
        }),
      );
    }

    let objectKey;
    try {
      if (!image) {
        throw new Error('Image is undefined or null after fetch.');
      }
      const uploadResult = await withTimeout(
        uploadImageToS3(image),
        IMAGE_UPLOAD_TIMEOUT_IN_MS,
      );
      objectKey = uploadResult.objectKey;
    } catch (uploadError) {
      captureMessage('Failed to upload image to S3');
    }

    if (!objectKey) {
      throw new Error(
        'objectKey is not defined, cannot proceed to putImageToCoreSeriesQuestion.',
      );
    }

    try {
      await withTimeout(
        putImageToCoreSeriesQuestion(seriesId, question.id, objectKey),
        IMAGE_UPLOAD_TIMEOUT_IN_MS,
      );
    } catch (putError) {
      captureMessage('Failed to put image to core');
    }
  } catch (error) {
    captureException(
      new MentiError('Failed to upload ai builder image', {
        cause: error,
        feature: 'ai-menti-builder',
      }),
    );
  }
};

export async function uploadImagesFromSeriesQuestions(
  seriesId: string,
  questions: { cropped_image_url: string | null | undefined; id: string }[],
) {
  try {
    const uploadImagePromises = questions
      .map(async (question) => {
        if (question.cropped_image_url) {
          return uploadAndSaveImage(seriesId, question);
        }

        return;
      })
      .filter((promise) => promise !== undefined);

    const uploadImageResults = await Promise.allSettled(uploadImagePromises);

    const failedImageUploads = uploadImageResults.filter(
      (result) => result.status === 'rejected',
    );

    if (failedImageUploads.length > 0) {
      throw new Error(
        `Failed to upload ${failedImageUploads.length} out of ${uploadImagePromises.length} images to Core.`,
      );
    }
  } catch (error) {
    captureException(
      new MentiError(
        '[AI Menti Builder] Failed to upload images to Core API.',
        {
          feature: 'ai-menti-builder',
          cause: { error, context: { series_id: seriesId } },
        },
      ),
    );
  }
}
