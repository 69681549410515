import { z, type ZodType } from 'zod';
import type { JSONContent } from '@tiptap/core';

// @ts-expect-error optional() adds undefined to the type signature which makes it incompatibe with JSONContent
// related discussion: https://github.com/colinhacks/zod/issues/3186
export const JSONContentSchema: ZodType<JSONContent> = z.lazy(() =>
  z
    .object({
      type: z.string().optional(),
      attrs: z.record(z.any()).optional(),
      content: z.array(z.lazy(() => JSONContentSchema)).optional(),
      marks: z
        .array(
          z
            .object({
              type: z.string(),
              attrs: z.record(z.any()).optional(),
            })
            .catchall(z.any()),
        )
        .optional(),
      text: z.string().optional(),
    })
    .catchall(z.any()),
);

export const aiApiSlideTypesV3 = z.enum([
  'wordcloud',
  'multiple_choice',
  'open_ended',
  'scales',
  'ranking',
  'pin_on_image',
  'heading',
  'quiz_select_answer',
  'quiz_type_answer',
  'quiz_leaderboard',
  'paragraph',
  'bullets',
  'prioritisation',
  'choices',
  'quote',
  'quiz',
  'quiz_open',
  'true_or_false',
  'big',
  'free_text',
]);

export type AiApiSlideTypesV3 = z.infer<typeof aiApiSlideTypesV3>;

const createBaseSlideSchema = <
  T extends AiApiSlideTypesV3,
  Item extends
    | z.ZodOptional<z.ZodArray<z.AnyZodObject>>
    | z.ZodArray<z.AnyZodObject>,
  ContentObject extends z.ZodRawShape,
>(
  typeKey: z.ZodLiteral<T>,
  itemsObjectSchema: Item,
  contentObjectSchema: ContentObject,
) => {
  return z.object({
    type: typeKey,
    content: z
      .object({
        title: z.string().optional(),
        items: itemsObjectSchema.optional(),
      })
      .extend(contentObjectSchema),
    skip_slide: z.boolean().optional(),
    image: z.nullable(z.string()).optional(),
    speaker_notes: z.nullable(z.string()).optional(),
  });
};

const wordCloudSlideSchema = createBaseSlideSchema(
  z.literal('wordcloud'),
  z.array(z.object({})).optional(),
  {},
);

const bulletsSlideSchema = createBaseSlideSchema(
  z.literal('bullets'),
  z.array(
    z.object({
      item: z.string(),
    }),
  ),
  {
    title: z.string(),
    items: z.array(z.any()),
  },
);

const multipleChoiceSlideSchema = createBaseSlideSchema(
  z.literal('multiple_choice'),
  z.array(
    z.object({
      text: z.string(),
      correct: z.boolean(),
    }),
  ),
  {},
);

const choicesSlideSchema = createBaseSlideSchema(
  z.literal('choices'),
  z.array(
    z.object({
      item: z.string(),
    }),
  ),
  {},
);

const openEndedSlideSchema = createBaseSlideSchema(
  z.literal('open_ended'),
  z.array(z.object({})).optional(),
  {
    title: z.string().optional(),
    subtitle: z.string().optional(),
  },
);

const paragraphSlideSchema = createBaseSlideSchema(
  z.literal('paragraph'),
  z.array(z.object({})).optional(),
  {
    title: z.string(),
    subtitle: z.string(),
  },
);

const scalesSlideSchema = createBaseSlideSchema(
  z.literal('scales'),
  z.array(
    z.object({
      item: z.string(),
    }),
  ),
  {
    labels: z.object({
      min: z.string(),
      max: z.string(),
    }),
  },
);

const rankingSlideSchema = createBaseSlideSchema(
  z.literal('ranking'),
  z.array(
    z.object({
      item: z.string(),
    }),
  ),
  {},
);

const pinOnImageSlideSchema = createBaseSlideSchema(
  z.literal('pin_on_image'),
  z.array(z.object({})).optional(),
  {
    imageToPin: z.string(),
  },
);

const headingSlideSchema = createBaseSlideSchema(
  z.literal('heading'),
  z.array(z.object({})).optional(),
  {
    title: z.string().nullable(),
    subtitle: z.string().nullable(),
  },
);

const quizSelectAnswerSlideSchema = createBaseSlideSchema(
  z.literal('quiz_select_answer'),
  z.array(
    z.object({
      correct: z.boolean(),
      text: z.string(),
    }),
  ),
  {},
);

const typeAnswerSlideSchema = createBaseSlideSchema(
  z.literal('quiz_type_answer'),
  z.array(z.object({})).optional(),
  { correctAnswers: z.array(z.string()) },
);

const quizLeaderboardSlideSchema = createBaseSlideSchema(
  z.literal('quiz_leaderboard'),
  z.array(z.object({})).optional(),
  {},
);

const prioritisationSlideSchema = createBaseSlideSchema(
  z.literal('prioritisation'),
  z
    .array(
      z.object({
        item: z.string(),
      }),
    )
    .optional(),
  {},
);

const quoteSlideSchema = createBaseSlideSchema(
  z.literal('quote'),
  z.array(z.object({})),
  {
    title: z.string(),
    subtitle: z.string(),
  },
);

const quizSlideSchema = createBaseSlideSchema(
  z.literal('quiz'),
  z.array(
    z.object({
      correct: z.boolean(),
      item: z.string(),
    }),
  ),
  {},
);

const quizOpenSlideSchema = createBaseSlideSchema(
  z.literal('quiz_open'),
  z.array(
    z.object({
      item: z.string(),
    }),
  ),
  {},
);

const trueOrFalseSlideSchema = createBaseSlideSchema(
  z.literal('true_or_false'),
  z.array(
    z.object({
      item: z.string(),
      correct: z.boolean(),
    }),
  ),
  {
    title: z.string(),
  },
);

const bigSlideSchema = createBaseSlideSchema(
  z.literal('big'),
  z.array(z.object({})),
  {},
);

const freeTextSlideSchema = z.object({
  type: z.literal('free_text'),
  content: JSONContentSchema,
  skip_slide: z.boolean().optional(),
  image: z.nullable(z.string()).optional(),
  speaker_notes: z.nullable(z.string()).optional(),
});

export const aiApiGeneratedSlideSchemaV3 = z.union([
  wordCloudSlideSchema,
  bulletsSlideSchema,
  multipleChoiceSlideSchema,
  openEndedSlideSchema,
  scalesSlideSchema,
  rankingSlideSchema,
  pinOnImageSlideSchema,
  headingSlideSchema,
  quizSelectAnswerSlideSchema,
  typeAnswerSlideSchema,
  quizLeaderboardSlideSchema,
  paragraphSlideSchema,
  prioritisationSlideSchema,
  choicesSlideSchema,
  quoteSlideSchema,
  quizSlideSchema,
  quizOpenSlideSchema,
  trueOrFalseSlideSchema,
  bigSlideSchema,
  freeTextSlideSchema,
]);

export type AiApiGeneratedSlideSchemaV3 = z.infer<
  typeof aiApiGeneratedSlideSchemaV3
>;
