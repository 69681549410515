import {
  postSeriesRequest,
  postSeriesResponse,
  type PostSeriesResponse,
} from '@core-api/ai-builder/ai-builder/series';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { type Question, type SeriesPost } from '@mentimeter/http-clients';
import { coreSeriesQuestionSchemaV3 } from '../schemas/core-series-question-schema';
import { getUserAuth } from './user-auth';
import { makeHttpFetchConfigObject } from './http-config';

function validateCoreQuestions(questions: Array<Partial<Question>>) {
  return questions.map((q) => coreSeriesQuestionSchemaV3.parse(q));
}

interface PostCreateSeriesParams {
  seriesData: Partial<SeriesPost>;
  questions: Array<Partial<Question>>;
  themeIdOrPublicName: string | number | undefined;
}

function makeRequestParams({
  seriesData,
  questions,
  themeIdOrPublicName,
}: PostCreateSeriesParams) {
  const { region, userToken } = getUserAuth();
  const { name, pace, qfa_active, comments_enabled, reactions } = seriesData;

  const themeObj =
    typeof themeIdOrPublicName === 'string'
      ? { publicThemeName: themeIdOrPublicName! }
      : { themeId: themeIdOrPublicName! };

  const params = postSeriesRequest({
    region,
    name: name!,
    pace: pace!,
    questions,
    commentsEnabled: !!comments_enabled,
    questionsEnabled: !!qfa_active,
    reactionsEnabled: !!reactions,
    ...themeObj,
  });
  const headers = makeHttpFetchConfigObject({ userToken });

  return { params, headers };
}

export async function postCreateSeriesWithQuestions({
  seriesData,
  questions,
  themeIdOrPublicName,
}: {
  seriesData: Partial<SeriesPost>;
  questions: Array<Partial<Question>>;
  themeIdOrPublicName: string | number | undefined;
}): Promise<PostSeriesResponse> {
  try {
    const validatedQuestions = validateCoreQuestions(questions);

    const { params, headers } = makeRequestParams({
      seriesData,
      themeIdOrPublicName,
      questions: validatedQuestions,
    });

    const response = await fetch(params, headers);

    if (!response.ok) {
      const jsonResponse = await response.json();
      throw new MentiError(
        `Error while creating series in the AI Builder API (${response.status} - ${response.statusText})`,
        {
          feature: 'ai-menti-builder',
          cause: jsonResponse,
        },
      );
    }

    return postSeriesResponse(response);
  } catch (error: unknown) {
    captureException(
      new MentiError(
        '[AI Menti Builder] Failed to create series in Core API.',
        {
          feature: 'ai-menti-builder',
          cause: { error, args: { questions } },
        },
      ),
    );
    throw error;
  }
}
