import {
  postAnalyzeRequest,
  postAnalyzeResponse,
  type PostAnalyzeRequest,
} from '@core-api/ai-api/ai-api/draft-builder/analyze';
import {
  postAnalyzeRequest as postAnalyzeRequestV3,
  postAnalyzeResponse as postAnalyzeResponseV3,
  type PostAnalyzeRequest as PostAnalyzeRequestV3,
} from '@core-api/ai-api/ai-api/draft-builder/v3/analyze';
import {
  postCreateSeriesRequest,
  postCreateSeriesResponse,
  type PostCreateSeriesRequest,
} from '@core-api/ai-api/ai-api/draft-builder/create-series';
import {
  postCreateSeriesRequest as postCreateSeriesRequestV3,
  postCreateSeriesResponse as postCreateSeriesResponseV3,
  type PostCreateSeriesRequest as PostCreateSeriesRequestV3,
} from '@core-api/ai-api/ai-api/draft-builder/v3/create-series';
import {
  postSummarizeRequest,
  postSummarizeResponse,
  type PostSummarizeRequest,
} from '@core-api/ai-api/ai-api/summarize';
import {
  postGeneralPromptRequest,
  postGeneralPromptResponse,
  type PostGeneralPromptRequest,
} from '@core-api/ai-api/ai-api/general-prompt';
import {
  postInsightsAssistantRequest,
  postInsightsAssistantResponse,
  type PostInsightsAssistantRequest,
} from '@core-api/ai-api/ai-api/insights-assistant';
import {
  postMergeSimilarRequest,
  postMergeSimilarResponse,
  type PostMergeSimilarRequest,
} from '@core-api/ai-api/ai-api/merge-similar';
import {
  postGenerateSentimentRequest,
  postGenerateSentimentResponse,
  type PostGenerateSentimentRequest,
} from '@core-api/ai-api/ai-api/generate-sentiment';
import {
  postRealtimeTokenRequest,
  postRealtimeTokenResponse,
} from '@core-api/ai-api/ai-api/realtime-token';
import type Ably from 'ably';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import User from '@mentimeter/user';
import {
  getSuggestRequest,
  type GetSuggestRequest,
  getSuggestResponse,
} from '@core-api/ai-api/ai-api/draft-builder/suggest';
import {
  postMetaRequest,
  postMetaResponse,
  type PostMetaRequest,
  type PostMetaResponse,
} from '@core-api/ai-api/ai-api/meta';
import {
  postGenerateSlideRequest,
  postGenerateSlideResponse,
  type PostGenerateSlideRequest,
} from '@core-api/ai-api/ai-api/generate-slide';
import {
  postGroupRequest,
  postGroupResponse,
  type PostGroupRequest,
} from '@core-api/ai-api/ai-api/group';
import {
  postExtractKeyPointsRequest,
  postExtractKeyPointsResponse,
  type PostExtractKeyPointsRequest,
} from '@core-api/ai-api/ai-api/extract-key-points';
import {
  postGetSlidesQualityRequest,
  postGetSlidesQualityResponse,
  type PostGetSlidesQualityRequest,
} from '@core-api/ai-api/ai-api/get-slides-quality';
import {
  postGetSlidesMetaRequest,
  postGetSlidesMetaResponse,
  type PostGetSlidesMetaRequest,
} from '@core-api/ai-api/ai-api/get-slides-meta';

const getUserAuth = () => {
  const region = User.region;
  const userAuth = User.getToken();
  if (!region || !userAuth)
    throw new MentiError('User not authenticated', {
      feature: 'user-success',
    });
  return { region, userAuth };
};

export const analyzeInputRequest = async (
  data: Omit<PostAnalyzeRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postAnalyzeRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to analyze text: ${error}`, {
      feature: 'user-success',
    });
  }

  return postAnalyzeResponse(response);
};

export const analyzeInputRequestV3 = async (
  data: Omit<PostAnalyzeRequestV3, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postAnalyzeRequestV3({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to analyze text: ${error}`, {
      feature: 'user-success',
    });
  }

  return postAnalyzeResponseV3(response);
};

export const getPromptSuggestions = async (
  data: Omit<GetSuggestRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    getSuggestRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to fetch suggestions: ${error}`, {
      feature: 'user-success',
    });
  }

  return getSuggestResponse(response);
};

export const createSeriesRequest = async (
  intent: Omit<PostCreateSeriesRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postCreateSeriesRequest({
      ...getUserAuth(),
      ...intent,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to create series: ${error}`, {
      feature: 'user-success',
    });
  }

  return postCreateSeriesResponse(response);
};

export const createSeriesRequestV3 = async (
  intentResponse: Omit<PostCreateSeriesRequestV3, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postCreateSeriesRequestV3({
      ...getUserAuth(),
      ...intentResponse,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to create series: ${error}`, {
      feature: 'user-success',
    });
  }

  return postCreateSeriesResponseV3(response);
};

export const getRealtimeToken = async () => {
  const response = await fetch(
    postRealtimeTokenRequest({
      ...getUserAuth(),
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError('ai streamer realtime token error', {
      cause: error,
      feature: 'user-success',
    });
  }

  return postRealtimeTokenResponse(response) as unknown as Ably.TokenDetails;
};

export const summarizeRequest = async (
  data: Omit<PostSummarizeRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postSummarizeRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to summarize: ${error}`, {
      feature: 'user-success',
    });
  }

  return postSummarizeResponse(response);
};

export const generalPromptRequest = async (
  data: Omit<PostGeneralPromptRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postGeneralPromptRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to general prompt: ${error}`, {
      feature: 'user-success',
    });
  }

  return postGeneralPromptResponse(response);
};

export const insightsAssistantRequest = async (
  data: Pick<
    PostInsightsAssistantRequest,
    'seriesId' | 'ablyChannelId' | 'messages' | 'requestId'
  >,
) => {
  const response = await fetch(
    postInsightsAssistantRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to general prompt: ${error}`, {
      feature: 'user-success',
    });
  }

  return postInsightsAssistantResponse(response);
};

export const mergeSimilarRequest = async (
  data: Omit<PostMergeSimilarRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postMergeSimilarRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to merge similar: ${error}`, {
      feature: 'user-success',
    });
  }

  return postMergeSimilarResponse(response);
};

export const generateSentimentRequest = async (
  data: Omit<PostGenerateSentimentRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postGenerateSentimentRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to generate sentiment: ${error}`, {
      feature: 'user-success',
    });
  }

  return postGenerateSentimentResponse(response);
};

export const postGenerationMetaRequest = async (
  payload: Omit<PostMetaRequest, 'region' | 'userAuth'> & {
    eventName:
      | 'menti_builder/analyze'
      | 'menti_builder/analyze_v3'
      | 'menti_builder/create_series'
      | 'menti_builder/create_series_v3';
  },
) => {
  try {
    const response = await fetch(
      postMetaRequest({
        ...getUserAuth(),
        ...payload,
      }),
    );

    if (!response.ok) {
      captureException(
        new MentiError('Error while making the AI Meta request', {
          cause: await response.json(),
          feature: 'ai-menti-builder',
        }),
      );
    }

    return postMetaResponse(response);
  } catch (error) {
    /**
     * We do not want to block the progress in the AI Builder if the meta request fails.
     */
    captureException(
      new MentiError('Error while making the AI Meta request', {
        cause: error,
        feature: 'ai-menti-builder',
      }),
    );

    const response: PostMetaResponse = {
      status: 'error',
    };
    return Promise.resolve(response);
  }
};

export const generateSlideRequest = async (
  data: Omit<PostGenerateSlideRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postGenerateSlideRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to generate slide: ${error}`, {
      feature: 'user-success',
    });
  }

  return postGenerateSlideResponse(response);
};

export const groupRequest = async (
  data: Omit<PostGroupRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postGroupRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to generate group: ${error}`, {
      feature: 'user-success',
    });
  }

  return postGroupResponse(response);
};

export const extractKeyPointsRequest = async (
  data: Omit<PostExtractKeyPointsRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postExtractKeyPointsRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to extract key points: ${error}`, {
      feature: 'user-success',
    });
  }

  return postExtractKeyPointsResponse(response);
};

export const slidesQualityRequest = async (
  data: Omit<PostGetSlidesQualityRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postGetSlidesQualityRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to get slides quality: ${error}`, {
      feature: 'user-success',
    });
  }

  return postGetSlidesQualityResponse(response);
};

export const slidesMetaRequest = async (
  data: Omit<PostGetSlidesMetaRequest, 'region' | 'userAuth'>,
) => {
  const response = await fetch(
    postGetSlidesMetaRequest({
      ...getUserAuth(),
      ...data,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to get slides meta: ${error}`, {
      feature: 'user-success',
    });
  }

  return postGetSlidesMetaResponse(response);
};
