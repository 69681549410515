import { LocalStorage } from '@mentimeter/storage';
import { useState } from 'react';
import { GUIDES } from './guides/guides';
import type { GuideDataT, GuideSlugT } from './guides/guides';

export interface GuideT extends GuideDataT {
  read: boolean;
  readAt?: string | undefined;
  type: 'video' | 'text';
}

interface NextStepState {
  readDateByGuideSlug: Record<string, string>;
}

const GUIDE_STATE_KEY = 'guides-state';

export function useGuide() {
  const [guideState, setGuideState] = useState(loadGuideStateOrDefault());

  const guides: GuideT[] = GUIDES.map((a) => ({
    ...a,
    type: a.type,
    read: a.slug in guideState.readDateByGuideSlug,
    readAt: guideState.readDateByGuideSlug[a.slug] ?? undefined,
  }));

  return { guides, readGuide, readGuides };

  function readGuide(guideSlug: GuideSlugT) {
    if (guideSlug in guideState.readDateByGuideSlug) return;

    const readAt = new Date().toISOString();
    const newValue = {
      readDateByGuideSlug: {
        ...guideState.readDateByGuideSlug,
        [guideSlug]: readAt,
      },
    };

    storeGuideState(newValue);
  }

  function readGuides(
    guideSlugs: GuideSlugT[],
    { staggeredBy, delay = 0 }: { staggeredBy: number; delay?: number },
  ) {
    const validSlugs = guideSlugs.filter(
      (s) => !(s in guideState.readDateByGuideSlug),
    );
    const now = Date.now();
    const newValue = {
      readDateByGuideSlug: {
        ...guideState.readDateByGuideSlug,
        ...validSlugs.reduce((acc, slug, index) => {
          const stagger = staggeredBy * index + delay;

          return {
            ...acc,
            [slug]: new Date(now + stagger).toISOString(),
          };
        }, {}),
      },
    };

    storeGuideState(newValue);
  }

  function loadGuideStateOrDefault() {
    return (
      LocalStorage.getJSONItem<NextStepState>(GUIDE_STATE_KEY) || {
        readDateByGuideSlug: {},
      }
    );
  }
  function storeGuideState(newState: NextStepState) {
    setGuideState(newState);

    LocalStorage.setJSONItem({
      type: 'functional',
      key: GUIDE_STATE_KEY,
      value: newState,
    });
  }
}
