import {
  type ThemeResponseT,
  type ThemesResponseT,
  type WorkspaceResponseT,
} from '@mentimeter/http-clients';
import { type ThemeDefinition } from '../types';

export function getWorkspaceDefaultTheme(
  themeData: ThemesResponseT,
  workspaceData: WorkspaceResponseT,
): ThemeResponseT | undefined {
  if (typeof workspaceData.default_theme_id !== 'number') {
    return undefined;
  }

  return themeData.find((theme) => theme.id === workspaceData.default_theme_id);
}

const getHighlightColors = (
  themeResponse: ThemeResponseT,
): { highlightOne: string; highlightTwo: string } => {
  const highlightOne = themeResponse.settings.bar_color[0];
  const highlightTwo = themeResponse.settings.bar_color[1];

  const fallBack = themeResponse.settings.line_color;

  return {
    highlightOne: highlightOne || fallBack,
    highlightTwo: highlightTwo || fallBack,
  };
};

export function fromThemeResponseToThemeDefinition(
  themeResponse: ThemeResponseT,
  isWorkspaceDefault: boolean = false,
): ThemeDefinition {
  return {
    id: themeResponse.id,
    name: themeResponse.name,
    displayName: isWorkspaceDefault ? 'Default' : themeResponse.name,
    publicThemeName: themeResponse.public ? themeResponse.name : undefined,
    colors: {
      textColor: themeResponse.settings.text_color,
      backgroundColor: themeResponse.settings.background_color,
      ...getHighlightColors(themeResponse),
    },
  };
}
