import type { UserResponseT } from '@mentimeter/http-clients';
import {
  UserFeaturesTypeEnum,
  WorkspaceRoleEnum as RoleEnum,
} from '@mentimeter/http-clients';
import type { DeepPartial } from '@mentimeter/ts-utils';
import { http, HttpResponse } from 'msw';
import type { SetupServer } from 'msw/node';
import { serverToState } from './transform';
import { userCache as internalUserCacheWrapper } from './internal-user-cache-wrapper';
import type { UserT } from './types';

export const userResponse: UserResponseT = {
  id: 1,
  user_id: 1,
  name: 'original name',
  email: 'email@mentimeter.com',
  flags: {
    receive_results_email: true,
    ai_features_enabled: true,
    dismissed_sharedpage_groups_notification: false,
    // @ts-expect-error-auto TS(2322) FIXME: Type '{ receive_results_email: true; dismissed_sha... Remove this comment to see the full error message
    dismissed_team_settings_upgrade_notification: false,
  },
  address: '',
  features: {
    type: UserFeaturesTypeEnum.REGISTERED,
    custom_themes: true,
    custom_themes_limit: 1,
    custom_themes_limit_user: 0,
    manage_groups: false,
    groups: false,
    dev_user: false,
    export: true,
    series_in_workspace_limit: Infinity,
    user_capturing: false,
    custom_colors: false,
    quick_form: false,
    several_answers: false,
    private_results: false,
    moderation_enabled: false,
    collaboration: {
      enabled: true,
    },
    engaged_participants_limit: -1,
    ai_enabled: false,
    ai_domain_enabled: true,
  },
  intercom_hash: '',
  external_identities: null,
  has_password: true,
  saml: false,
  created_at: '',
  token: '',
  session_token: '',
  custom_themes: {},
  beta_status: 'inactive',
  role: RoleEnum.OWNER,
  service_urls: {
    core: 'https://core-api.mock',
    quiz: 'https://quiz-api.mock',
    blab: 'https://blab-api.mock',
  },
};

export const user: UserT = {
  id: 1,
  user_id: 1,
  name: 'original name',
  email: 'email@mentimeter.com',
  flags: {
    receive_results_email: true,
    ai_features_enabled: true,
    dismissed_sharedpage_groups_notification: false,
    // @ts-expect-error-auto TS(2322) FIXME: Type '{ receive_results_email: true; dismissed_sha... Remove this comment to see the full error message
    dismissed_team_settings_upgrade_notification: false,
  },
  address: '',
  features: {
    type: UserFeaturesTypeEnum.REGISTERED,
    custom_themes: true,
    custom_themes_limit: 1,
    custom_themes_limit_user: 0,
    manage_groups: false,
    groups: false,
    dev_user: false,
    export: true,
    custom_colors: false,
    quick_form: false,
    several_answers: false,
    private_results: false,
    moderation_enabled: false,
    collaboration: {
      enabled: false,
    },
    series_in_workspace_limit: Infinity,
    user_capturing: false,
    ai_enabled: false,
    ai_domain_enabled: true,
  },
  intercom_hash: '',
  external_identities: null,
  has_password: true,
  saml: false,
  created_at: '',
  token: '',
  session_token: '',
  custom_themes: {},
  beta_status: 'inactive',
  role: RoleEnum.OWNER,
  service_urls: {
    core: 'https://core-api.mock',
    quiz: 'https://quiz-api.mock',
    blab: 'https://blab-api.mock',
  },
};

export const freeUser: UserResponseT = {
  ...userResponse,
  features: {
    ...userResponse.features,
    engaged_participants_limit: 50,
    type: UserFeaturesTypeEnum.REGISTERED,
  },
};

export const basicUser: UserResponseT = {
  ...userResponse,
  features: {
    ...userResponse.features,
    type: UserFeaturesTypeEnum.BASIC,
  },
};

export const basicTeamMember: UserResponseT = {
  ...userResponse,
  role: RoleEnum.USER,
  features: {
    ...userResponse.features,
    type: UserFeaturesTypeEnum.BASIC,
  },
};

export const basicTeamOwner: UserResponseT = {
  ...userResponse,
  role: RoleEnum.OWNER,
  features: {
    ...userResponse.features,
    type: UserFeaturesTypeEnum.BASIC,
  },
};

export const proUser: UserResponseT = {
  ...userResponse,
  features: {
    ...userResponse.features,
    type: UserFeaturesTypeEnum.PRO,
    org_template: true,
  },
};

export const enterpriseUser: UserResponseT = {
  ...userResponse,
  features: {
    ...userResponse.features,
    type: UserFeaturesTypeEnum.ENTERPRISE,
    org_template: true,
  },
};

export const mockNotAuthenticated = (apiMock: SetupServer) => {
  return apiMock.use(
    http.get('https://core-api.mock/users', () =>
      HttpResponse.json(null, { status: 401 }),
    ),
  );
};

export const mockUser = (apiMock: SetupServer, user = {}) => {
  const mergedUser: UserResponseT = mergeUser(user);

  internalUserCacheWrapper.setUserCache(mergedUser);
  return apiMock.use(
    http.get('https://core-api.mock/users', () => {
      return HttpResponse.json(mergedUser);
    }),
  );
};

export const mockNoUser = (apiMock: SetupServer) =>
  apiMock.use(
    http.get('https://core-api.mock/users', () => {
      return HttpResponse.json(null, { status: 204 });
    }),
  );

export const mockUserProfile = (apiMock: SetupServer) => {
  apiMock.use(
    http.get('https://core-api.mock/users/profile', () => {
      return HttpResponse.json(userResponse);
    }),
  );
};

export const mergeUser = (
  userDeltaResponse: DeepPartial<UserResponseT>,
  baseUserResponse?: UserResponseT,
): UserResponseT => {
  const initialUserResponse = baseUserResponse ?? userResponse;
  return {
    ...initialUserResponse,
    ...(userDeltaResponse as UserResponseT),
    features: {
      ...initialUserResponse.features,
      ...(userDeltaResponse.features ?? {}),
    },
  };
};

export const mockMergedUser = (
  useUserMock: any,
  userDeltaResponse: DeepPartial<UserResponseT>,
) => {
  (useUserMock as jest.Mock).mockReturnValue({
    user: serverToState(mergeUser(userDeltaResponse)),
  });
};
