import React from 'react';
import { SparkleSolidIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Select } from '@mentimeter/ragnar-ui/input/select';
import { Button } from '@mentimeter/ragnar-ui/button';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';
import { Label } from '@mentimeter/ragnar-ui/label';
import { Range } from '@mentimeter/ragnar-ui/range';
import { RadioGroupItem } from '@mentimeter/ragnar-ui/radio-group';
import { Fieldset } from '@mentimeter/ragnar-ui/fieldset';
import type { IntentFormElements, IntentFormValues } from '../types';
import { CONTENT_PROFICIENCY_LEVEL_MAP } from '../constants/content-proficiency-level';
import { TONE_OF_VOICE_MAP } from '../constants/tone-of-voice';
import { ENGAGEMENT_TYPES } from '../constants/engagement-types';
import { SUPPORTED_LANGUAGES } from '../constants/supported-languages';
import { FormSection } from './FormSection';

const defaultIntentFormValues: IntentFormValues = {
  'engagement-type': '',
  topic: '',
  objective: '',
  language: 'English',
  'content-proficiency': '1',
  'tone-of-voice': '1',
  'number-of-slides': '5',
  'specific-requests': '',
  'design-preferences': '',
  'key-points': '',
  'image-keywords': '',
};

const MAX_INPUT_LENGTH = 100;

export function IntentForm({
  error,
  loading,
  onSubmit,
}: {
  error: boolean;
  loading: boolean;
  onSubmit: (formElements: IntentFormElements) => void;
}) {
  const [inputs, setInputs] = React.useState(defaultIntentFormValues);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(event.currentTarget.elements as IntentFormElements);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      alignItems="center"
      width="100%"
      maxWidth="750px"
      px="space8"
    >
      <FormField>
        <Label htmlFor="engagement-type" fontSize="100">
          What kind of Menti do you want to make?
        </Label>
        <Select
          id="engagement-type"
          name="engagement-type"
          value={inputs['engagement-type']}
          onChange={handleInputChange}
          options={ENGAGEMENT_TYPES_OPTIONS}
          disabled={loading}
        />
      </FormField>

      <FormField>
        <Label htmlFor="topic" fontSize="100">
          What is the main topic about?
        </Label>
        <TextInputItem
          name="topic"
          placeholder="Main topic"
          value={inputs.topic}
          onChange={handleInputChange}
          maxLength={MAX_INPUT_LENGTH}
          required
          disabled={loading}
        />
      </FormField>

      <FormField>
        <Label htmlFor="objective" fontSize="100">
          What is the objective of your presentation?
        </Label>
        <TextInputItem
          name="objective"
          placeholder="Objective"
          value={inputs.objective}
          onChange={handleInputChange}
          maxLength={MAX_INPUT_LENGTH}
          required
          disabled={loading}
        />
      </FormField>

      <FormSection
        title="Presentation preferences"
        legend="Set language, proficiency, tone of voice, and number of slides"
      >
        <FormField>
          <Label htmlFor="language" fontSize="100">
            Language
          </Label>
          <Select
            id="language"
            name="language"
            value={inputs.language}
            onChange={handleInputChange}
            options={SUPPORTED_LANGUAGES_OPTIONS}
            disabled={loading}
          />
        </FormField>

        <FormField>
          <Label as="legend" htmlFor="content-proficiency" fontSize="100">
            Content proficiency
          </Label>
          <Fieldset width="100%">
            <RadioGroup onChange={handleInputChange}>
              {Object.entries(CONTENT_PROFICIENCY_LEVEL_MAP).map(
                ([key, value]) => (
                  <RadioGroupItem
                    key={key}
                    name="content-proficiency"
                    checked={inputs['content-proficiency'] === key}
                    aria-label={value}
                    disabled={loading}
                    value={key}
                    readOnly
                    extend={({ theme }) => ({
                      borderRadius: `${theme.kosmosBorderRadius.xl}px`,
                      marginRight: 0,
                      padding: `${theme.kosmosSpacing['space2']}px ${theme.kosmosSpacing['space1']}px`,
                    })}
                  >
                    {value}
                  </RadioGroupItem>
                ),
              )}
            </RadioGroup>
          </Fieldset>
        </FormField>

        <FormField>
          <Label as="legend" htmlFor="tone-of-voice" fontSize="100">
            Tone of voice
          </Label>
          <Fieldset width="100%">
            <RadioGroup onChange={handleInputChange}>
              {Object.entries(TONE_OF_VOICE_MAP).map(([key, value]) => (
                <RadioGroupItem
                  key={key}
                  name="tone-of-voice"
                  checked={inputs['tone-of-voice'] === key}
                  aria-label={value}
                  disabled={loading}
                  value={key}
                  readOnly
                  extend={({ theme }) => ({
                    borderRadius: `${theme.kosmosBorderRadius.xl}px`,
                    marginRight: 0,
                    padding: `${theme.kosmosSpacing['space2']}px ${theme.kosmosSpacing['space1']}px`,
                  })}
                >
                  {value}
                </RadioGroupItem>
              ))}
            </RadioGroup>
          </Fieldset>
        </FormField>

        <FormField>
          <Label htmlFor="number-of-slides" fontSize="100">
            Number of slides ({inputs['number-of-slides']})
          </Label>

          <Range
            name="number-of-slides"
            min={1}
            max={10}
            step={1}
            value={inputs['number-of-slides']}
            onChange={handleInputChange}
            disabled={loading}
          />
          <RangeLabels min="1" max="10" />
        </FormField>
      </FormSection>

      <FormSection
        title="More options"
        legend="Add specific requests, key-points, design preferences, and images"
      >
        <FormField>
          <Label htmlFor="specific-requests" fontSize="100">
            Any specific requests?
          </Label>
          <TextInputItem
            name="specific-requests"
            placeholder="Requests"
            value={inputs['specific-requests']}
            onChange={handleInputChange}
            maxLength={MAX_INPUT_LENGTH}
            disabled={loading}
          />
        </FormField>

        <FormField>
          <Label htmlFor="design-preferences" fontSize="100">
            Any design preferences?
          </Label>
          <TextInputItem
            name="design-preferences"
            placeholder="Design preferences"
            value={inputs['design-preferences']}
            onChange={handleInputChange}
            maxLength={MAX_INPUT_LENGTH}
            disabled={loading}
          />
        </FormField>

        <FormField>
          <Label htmlFor="key-points" fontSize="100">
            Any key points you want to make sure to include?
          </Label>
          <TextInputItem
            name="key-points"
            placeholder="Key points"
            value={inputs['key-points']}
            onChange={handleInputChange}
            maxLength={MAX_INPUT_LENGTH}
            disabled={loading}
          />
        </FormField>

        <FormField>
          <Label htmlFor="image-keywords" fontSize="100">
            Any image keywords? (comma separated)
          </Label>
          <TextInputItem
            name="image-keywords"
            placeholder="Image, keywords"
            value={inputs['image-keywords']}
            onChange={handleInputChange}
            maxLength={MAX_INPUT_LENGTH}
            disabled={loading}
          />
        </FormField>
      </FormSection>

      <Button
        iconLeading={SparkleSolidIcon}
        mt="space4"
        disabled={loading}
        type="submit"
        variant="primary"
        size="large"
        state={loading ? 'loading' : undefined}
      >
        Create draft
      </Button>
    </Form>
  );
}

function FormField({ children }: { children: React.ReactNode }) {
  return (
    <Box width="100%" mb="space6">
      {children}
    </Box>
  );
}

function RadioGroup({
  children,
  onChange,
}: {
  children: React.ReactNode;
  onChange: React.FormEventHandler;
}) {
  return (
    <Box
      width="100%"
      display="grid"
      gap="space2"
      gridAutoFlow="column"
      alignItems="stretch"
      onChange={onChange}
    >
      {children}
    </Box>
  );
}

function RangeLabels({ min, max }: { min: string; max: string }) {
  return (
    <Box
      top={-8}
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Text fontWeight="semiBold" fontSize="87.5">
        {min}
      </Text>
      <Text fontWeight="semiBold" fontSize="87.5">
        {max}
      </Text>
    </Box>
  );
}

type SelectOption = React.ComponentProps<typeof Select>['options'][0];

function stringToSelectOption(str: string): SelectOption {
  const key = str.toLowerCase().replace(' ', '-');
  return {
    label: str,
    value: str,
    key,
  };
}

const ENGAGEMENT_TYPES_OPTIONS = ENGAGEMENT_TYPES.map(stringToSelectOption);

const SUPPORTED_LANGUAGES_OPTIONS =
  SUPPORTED_LANGUAGES.map(stringToSelectOption);
