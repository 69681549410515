import useSWRMutation from 'swr/mutation';
import type { z } from 'zod';
import { core, type Series, type SeriesPost } from '@mentimeter/http-clients';
import { uploadImagesFromSeriesQuestions } from '@mentimeter/ai-menti-builder';
import { aiSlideToCoreQuestion } from '@mentimeter/ai-experimentation';
import type { aiSeriesSchema } from '../schemas/ai-series-schema';
import { getRandomLayout } from '../utils/get-random-layout';
import { getRandomTheme } from '../utils/get-random-theme';

export function useCreateSeriesFromAi({
  onSuccess,
}: {
  onSuccess: (series: Series) => void;
}) {
  const { data, isMutating, error, trigger } = useSWRMutation(
    'createSeriesFromAi',
    fetcher,
    {
      onSuccess,
    },
  );

  if (isMutating) {
    return {
      loading: true,
      createSeries: trigger,
    };
  }

  if (error) {
    return {
      loading: false,
      error,
      createSeries: trigger,
    };
  }

  return {
    loading: false,
    data,
    createSeries: trigger,
  };
}

async function fetcher(
  _key: string,
  { arg: aiSeries }: { arg: z.infer<typeof aiSeriesSchema> },
) {
  const seriesPost: SeriesPost = {
    name: aiSeries.name,
    comments_enabled: aiSeries.comments,
    pace: aiSeries.pace ? 'audience' : 'presenter',
    qfa_active: aiSeries.qna_feature,
    public_theme_name: getRandomTheme(),
    questions: aiSeries.slides.map((question) => {
      const layout = getRandomLayout();

      return {
        ...aiSlideToCoreQuestion(question),
        ...(question.image && { layout }),
      };
    }),
  };

  const { data: newSeries } = await core().series.post(seriesPost);

  // Map by position since we don't have a better alternative...
  const imagesToUpload = newSeries.questions?.map((question, index) => ({
    id: question.id,
    cropped_image_url: aiSeries.slides[index]?.image,
  }));

  await uploadImagesFromSeriesQuestions(newSeries.series_id, imagesToUpload);

  return newSeries;
}
