import React, { useEffect, useState } from 'react';
import { animated, easings, useSpring } from '@react-spring/web';
import { useOnce } from '@mentimeter/react-hooks';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

const DEFAULT_LOADER_DURATION = 10_000;

interface ProgressBarProps {
  progressText?: string;
  loaderDurationInMs?: number | undefined;
  shouldFinish?: boolean;
  loop?: boolean;
}
export const ProgressBar = ({
  progressText,
  loaderDurationInMs = DEFAULT_LOADER_DURATION,
  shouldFinish = false,
  loop = false,
}: ProgressBarProps) => {
  const [showMessage, setShowMessage] = useState(false);
  const [props, api] = useSpring(() => ({
    width: 75 + '%',
    from: { width: 0 + '%' },
    loop,
    config: {
      duration: loaderDurationInMs,
      easing: easings.easeOutQuad,
    },
  }));

  useEffect(() => {
    if (shouldFinish) {
      api.start({
        width: '100%',
        config: { duration: 3000, easing: easings.easeOutQuad },
      });
    }
  }, [shouldFinish, api]);

  useEffect(() => {
    api.start({
      width: '75%',
      config: { duration: loaderDurationInMs, easing: easings.easeOutQuad },
    });
  }, [api, loaderDurationInMs]);

  useOnce(true, () => {
    // Show message after 1/4 of the loading duration
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, loaderDurationInMs / 4);

    return () => clearTimeout(timer);
  });
  return (
    <>
      <Box
        as="section"
        width="100%"
        height="100%"
        alignItems="start"
        justifyContent="center"
        borderRadius="lg"
        overflow="hidden"
        role="progressbar"
        data-testid="progress-bar"
      >
        <Box
          // @ts-expect-error-auto FIXME known Spring issue https://github.com/pmndrs/react-spring/issues/1645
          style={props}
          as={animated.div}
          height="inherit"
          bg="borderInfoWeakest"
          p={3}
          overflow="hidden"
          position="absolute"
          extend={() => ({
            textWrap: 'nowrap',
            userSelect: 'none',
          })}
        >
          <Text
            as="h2"
            color="info"
            fontSize="225"
            fontWeight="regular"
            lineHeight="none"
          >
            {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
            {progressText}
          </Text>
        </Box>
        <Text
          as="h2"
          p={3}
          fontSize="225"
          lineHeight="none"
          fontWeight="regular"
          extend={() => ({
            textWrap: 'nowrap',
            userSelect: 'none',
          })}
        >
          {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
          {progressText}
        </Text>
      </Box>
      {showMessage && (
        <Text
          color="textWeak"
          fontSize={2}
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: '28px',
            position: 'absolute',
            top: '65px',
          }}
        >
          Hang tight. You can start editing shortly.
        </Text>
      )}
    </>
  );
};
