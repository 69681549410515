import type { ThemeEffectArgs } from './types';

export const themeHoverEffect =
  ({
    backgroundColor,
    textColor,
    highlightOne,
    highlightTwo,
  }: ThemeEffectArgs) =>
  () => ({
    overflow: 'hidden',
    '&:hover': {
      color: `${textColor} !important`,
      backgroundColor: `${backgroundColor} !important`,
    },
    '&:hover::before': {
      transform: 'translate(-115%, -10px) rotate(45deg)',
    },
    '&:hover::after': {
      transform: 'translate(115%, 10px) rotate(-45deg)',
    },
    '&:focus-visible': {
      color: `${textColor} !important`,
      backgroundColor: `${backgroundColor} !important`,
    },
    '&:focus-visible::before': {
      transform: 'translate(-115%, -10px) rotate(45deg)',
    },
    '&:focus-visible::after': {
      transform: 'translate(115%, 10px) rotate(-45deg)',
    },
    '::before': {
      transition: 'transform 0.3s',
      transform: 'translate(-200%, -10px)',
      position: 'absolute',
      content: '""',
      display: 'block',
      width: '50%',
      height: '100%',
      borderRadius: '20px',
      backgroundColor: highlightOne,
    },
    '::after': {
      transition: 'transform 0.3s',
      transform: 'translate(200%, 10px)',
      position: 'absolute',
      content: '""',
      display: 'block',
      width: '50%',
      height: '100%',
      borderRadius: '5px',
      backgroundColor: highlightTwo,
    },
  });
