import type { AiFeature } from '../types';

type RewindTimes = `${string}m` | `${string}s`;

interface MakeAblyChannelProps {
  userId: string | number;
  randomChannelId: string;
  feature: AiFeature;
  rewindTime?: RewindTimes | undefined;
}

export const makeAiApiAblyChannel = ({
  userId,
  randomChannelId,
  feature,
  rewindTime: _rewindTime,
}: MakeAblyChannelProps) =>
  `menti-ai:${feature}:${userId}:${randomChannelId}` as const;

export const makeAblyStreamUrl = ({
  userId,
  feature,
  ablyAccessToken,
  randomChannelId,
  rewindTime,
}: MakeAblyChannelProps & { ablyAccessToken: string }) => {
  const ablyHost = process.env.NEXT_PUBLIC_ABLY_REALTIME_HOST;

  if (!ablyHost) {
    throw new Error(
      'The ably host is not defined in the environment variables.',
    );
  }

  const baseURL = `https://${ablyHost}/event-stream`;
  const url = new URL(baseURL);

  // Constructing the query parameters
  const params = new URLSearchParams();
  params.append(
    'channel',
    makeAiApiAblyChannel({
      userId,
      randomChannelId,
      feature,
      rewindTime,
    }),
  );
  params.append('v', '1.2');
  params.append('accessToken', ablyAccessToken);

  // Setting the query parameters
  url.search = params.toString();

  return `${url.toString()}` as const;
};
