// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostGeneralPromptResponse {
  status: string;
  requestId: string;
}

export async function postGeneralPromptResponse(
  response: Response,
): Promise<PostGeneralPromptResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostGeneralPromptResponse;
}
