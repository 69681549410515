import Z from 'zod';
import { aiApiGeneratedSlideSchema } from './ai-api-slides-schema';

export const createSeriesEventBodySchema = Z.object({
  slides: Z.array(aiApiGeneratedSlideSchema),
  name: Z.string(),
  // Making them optional as they might not be present until the
  // the AI publisher is updated
  qna_feature: Z.boolean().optional(),
  comments: Z.boolean().optional(),
  pace: Z.boolean().optional(), // True: audience, false/default: presenter
});

export type CreateSeriesEventBodySchema = Z.infer<
  typeof createSeriesEventBodySchema
>;
