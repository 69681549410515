import type { PublicTemplateT } from '@mentimeter/http-clients';
import {
  useUserProfile,
  type PublicTemplateUseCase,
  useApiTemplates,
  getSupportedMainUsage,
} from '@mentimeter/templates-modal/hooks/usePublicTemplates';

interface PublicTemplatesInfoT {
  templates: PublicTemplateT[];
  mainUsage: PublicTemplateUseCase;
  isLoading: boolean;
  error: boolean;
}

export function useRecommendedTemplates(): PublicTemplatesInfoT {
  const { data: userProfile, error: profileError } = useUserProfile();

  const mainUsage = getSupportedMainUsage(userProfile);
  const { data: templates, error: templatesError } = useApiTemplates(
    !userProfile,
    mainUsage,
    {
      largeTemplates: true,
    },
  );

  if (profileError || templatesError) {
    return {
      templates: [],
      mainUsage,
      isLoading: false,
      error: true,
    };
  }

  if (!userProfile || !templates) {
    return {
      templates: [],
      mainUsage,
      isLoading: true,
      error: false,
    };
  }

  if (templates.length === 0) {
    return {
      templates,
      mainUsage,
      isLoading: false,
      error: true,
    };
  }

  return {
    templates,
    mainUsage,
    isLoading: false,
    error: false,
  };
}
