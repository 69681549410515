import { core } from '@mentimeter/http-clients';

export async function fetchImageFromCore(imageUrl: string): Promise<File> {
  const urlParams = new URLSearchParams(imageUrl);
  urlParams.set('w', '3200');
  urlParams.delete('crop');
  urlParams.delete('cs');
  const url = decodeURIComponent(urlParams.toString());

  const { data } = await core().get(url, { responseType: 'blob' });
  return data as File;
}
