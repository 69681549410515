// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostCreateSeriesRequest {
  region: 'eu' | 'us';
  userAuth: string;
  tool: string;
  hasAttachment: boolean;
  hasStructureInstructions: boolean;
  enhancedPrompt: string;
  question?: string;
  isInvalidInput: boolean;
  imageKeyword: string;
  userInput: Array<{
    question: string;
    answer: string;
  }>;
  ablyChannelId: string;
  requestId?: string;
}

export function postCreateSeriesRequest(
  requestParams: PostCreateSeriesRequest,
): Request {
  const data = {
    tool: toSnakeKeys(requestParams.tool),
    has_attachment: toSnakeKeys(requestParams.hasAttachment),
    has_structure_instructions: toSnakeKeys(
      requestParams.hasStructureInstructions,
    ),
    enhanced_prompt: toSnakeKeys(requestParams.enhancedPrompt),
    question: toSnakeKeys(requestParams.question),
    is_invalid_input: toSnakeKeys(requestParams.isInvalidInput),
    image_keyword: toSnakeKeys(requestParams.imageKeyword),
    user_input: toSnakeKeys(requestParams.userInput),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/draft-builder/v3/create-series`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
