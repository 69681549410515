import axios from 'axios';

function getKeyFromResponse(text: string) {
  const keyRegex = /<Key>(.*)<\/Key>/g;
  const match = keyRegex.exec(text);

  return match![1]!;
}

function getLocationFromResponse(text: string) {
  // Regex needs to be created each time (changes state on exec)
  const locationRegex = /<Location>(.*)<\/Location>/g;
  const match = locationRegex.exec(text);

  return match![1]!;
}

export function getSanitizedFileName(file: File, isImage: boolean) {
  // Used to comply with S3 file name guidelines:
  // https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
  const { name = '', type } = file;
  const sanitizedFilename = name.replace(/[^0-9a-zA-Z_.-]/g, '');
  if (sanitizedFilename) return sanitizedFilename;

  if (isImage && type.includes('gif')) {
    return 'GIF';
  }
  return isImage ? 'image' : 'file';
}

function createForm(
  file: File,
  isImage: boolean,
  fields: Record<string, string>,
) {
  const form = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    form.append(key, value);
  });
  form.append('file', file, getSanitizedFileName(file, isImage)); // 3rd parameter overrides the filename
  return form;
}

interface S3Input {
  file: File;
  url: string;
  fields: Record<string, string>;
  isImage?: boolean;
}

interface S3Response {
  location: string;
  objectKey: string;
}

export const uploadToS3 = async ({
  file,
  url,
  fields,
  isImage = true,
}: S3Input): Promise<S3Response> => {
  const form = createForm(file, isImage, fields);
  const s3Response = await axios.post(url, form);
  const location = getLocationFromResponse(s3Response.data);
  const objectKey = getKeyFromResponse(s3Response.data);

  return { location, objectKey };
};
