/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import {
  TrackingContext,
  type UserPresentationRole,
} from '@mentimeter/http-clients';
import { ResultsChartIcon } from '@mentimeter/ragnar-visuals';
import { user } from '@mentimeter/user/mocks';
import { useRouter } from '@mentimeter/next-navigation';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { CalloutCardGeneric } from '@mentimeter/ragnar-ui/callout-card';
import { Text } from '@mentimeter/ragnar-ui/text';
import { HomeWidget } from '../shared/home-widget';
import { useActivatedToResultsJourney } from './useActivatedToResultsJourney';

export const LatestResultsDataHandler = () => {
  const router = useRouter();
  const { series, inJourney } = useActivatedToResultsJourney();
  if (!series || !inJourney) return null;

  return (
    <HomeWidget.Root>
      <LatestResults
        title={series.name}
        onClick={() => {
          router.push(`/app/presentation/${series.id}/results`);
          trackUser({
            event: 'Opened results view',
            properties: {
              placement: 'Last results widget',
              context: TrackingContext.UserHome,
              role: (series.owner_id === user?.id
                ? 'Owner'
                : 'Collaborator') as UserPresentationRole,
              'workspace role': user?.role,
              'shared presentation': Boolean(series.workspace_id),
              'series id': series.id,
            },
          });
        }}
      />
    </HomeWidget.Root>
  );
};

const LatestResults = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => {
  return (
    <Clickable as="a" width="100%" onClick={onClick}>
      <CalloutCardGeneric theme="info">
        <Box width="100%" height="100%" flexDirection="row" alignItems="center">
          <Box mr={3}>
            <Box
              width="8px"
              height="8px"
              borderRadius={3}
              bg="brand"
              mb={-2}
              extend={({ theme }) => ({
                boxShadow: `0 0 0 ${theme.borders[2]}px ${theme.colors.bgStrong}`,
              })}
            />
            <ResultsChartIcon size={6} />
          </Box>
          <Box flex="1 1 auto">
            <Text color="onNeutralWeaker">New results</Text>
            <Text fontWeight="semiBold" fontSize={3}>
              {title}
            </Text>
          </Box>
          <Box ml={3} height="100%" justifyContent="flex-end">
            <Button size="compact" variant="brand">
              View
            </Button>
          </Box>
        </Box>
      </CalloutCardGeneric>
    </Clickable>
  );
};
