import type {
  PublicTemplateT,
  PublicTemplateWithSeriesT,
  QuestionType,
} from '@mentimeter/http-clients';

export type PresentationType =
  | 'quiz'
  | 'survey'
  | 'presentation'
  | 'wordcloud'
  | 'choices'
  | 'open-ended'
  | 'scales'
  | 'ranking'
  | 'pin-on-image'
  | 'all';

export interface TemplateFilterInclusive {
  presentationType: PresentationType;
}

export const PRESENTATION_TYPE_TO_QUESTION_TYPE: Partial<
  Record<PresentationType, QuestionType>
> = {
  wordcloud: 'wordcloud',
  choices: 'choices',
  'open-ended': 'open',
  scales: 'scales',
  ranking: 'ranking',
  'pin-on-image': 'pin_on_image',
};

const PRESENTATION_TYPE_FILTERS: Record<
  PresentationType,
  <T extends PublicTemplateT>(t: T) => boolean
> = {
  all: () => true,
  presentation: (template) => {
    const isAudiencePace = template.series?.pace.mode === 'audience';
    const hasQuizSlide = hasSlideType(template, 'quiz');
    return !isAudiencePace && !hasQuizSlide;
  },
  survey: (template) => template.series?.pace.mode === 'audience',
  quiz: (template) => hasSlideType(template, 'quiz'),
  'open-ended': (template) => hasSlideType(template, 'open'),
  choices: (template) => hasSlideType(template, 'choices'),
  scales: (template) => hasSlideType(template, 'scales'),
  wordcloud: (template) => hasSlideType(template, 'wordcloud'),
  ranking: (template) => hasSlideType(template, 'ranking'),
  'pin-on-image': (template) => hasSlideType(template, 'pin_on_image'),
};

export function hasSlideType<T extends PublicTemplateT>(
  template: T,
  slideType: QuestionType,
) {
  return (
    template.series?.questions.some(
      (question) => question.type === slideType,
    ) ?? false
  );
}

export function filterTemplates<T extends PublicTemplateT>(
  templates: T[],
  filter: TemplateFilterInclusive,
) {
  return templates.filter(PRESENTATION_TYPE_FILTERS[filter.presentationType]);
}

export const PRESENTATION_TYPE_TO_DISPLAY_QUESTION_ID: Record<
  PresentationType,
  (t: PublicTemplateWithSeriesT) => string
> = {
  all: (template) => template.preview_question_admin_key,
  presentation: (template) => template.preview_question_admin_key,
  survey: (template) => template.preview_question_admin_key,
  quiz: (template) => template.preview_question_admin_key,
  'open-ended': (template) =>
    getFirstMatchingQuestionByType(template, 'open')?.admin_key ??
    template.preview_question_admin_key,
  choices: (template) =>
    getFirstMatchingQuestionByType(template, 'choices')?.admin_key ??
    template.preview_question_admin_key,
  scales: (template) =>
    getFirstMatchingQuestionByType(template, 'scales')?.admin_key ??
    template.preview_question_admin_key,
  wordcloud: (template) =>
    getFirstMatchingQuestionByType(template, 'wordcloud')?.admin_key ??
    template.preview_question_admin_key,
  ranking: (template) =>
    getFirstMatchingQuestionByType(template, 'ranking')?.admin_key ??
    template.preview_question_admin_key,
  'pin-on-image': (template) =>
    getFirstMatchingQuestionByType(template, 'pin_on_image')?.admin_key ??
    template.preview_question_admin_key,
};

export function getFirstMatchingQuestionByType(
  template: PublicTemplateWithSeriesT,
  questionType: QuestionType,
) {
  return template.series.questions.find(
    (question) => question.type === questionType,
  );
}
