import { useSplitData, useSplitsLazy } from '@mentimeter/splitio';
import { useUser } from '@mentimeter/user';
import { useMemo } from 'react';

export type AiMentiBuilderV2SplitValues = 'v1' | 'v2' | 'v3' | 'off';

const DEFAULT_SPLIT_VALUES = {
  USE_AI_Menti_Builder_v2: 'off',
};

interface SplitHookResult {
  isReady: boolean;
  USE_AI_Menti_Builder_v2: AiMentiBuilderV2SplitValues;
}

export const useCreateWithAISplit = (isMobile: boolean): SplitHookResult => {
  const { user } = useUser();
  const { country } = useSplitData();
  const lazyTreatment = useSplitsLazy();

  const experimentIsReady = !!user && !!country && lazyTreatment.isReady;

  const splits = useMemo(() => {
    if (!experimentIsReady || isMobile) {
      return null;
    }

    return lazyTreatment.getSplits(['USE_AI_Menti_Builder_v2'], {
      splitAttributes: {
        country,
      },
    });
  }, [experimentIsReady, isMobile, lazyTreatment, country]);

  const USE_AI_Menti_Builder_v2 = (splits?.USE_AI_Menti_Builder_v2 ??
    DEFAULT_SPLIT_VALUES.USE_AI_Menti_Builder_v2) as AiMentiBuilderV2SplitValues;

  if (isMobile) {
    return {
      isReady: experimentIsReady,
      USE_AI_Menti_Builder_v2: 'off', // Always off for mobile
    };
  }

  return {
    isReady: experimentIsReady,
    USE_AI_Menti_Builder_v2,
  };
};
