import React from 'react';
import type { Pictogram } from '../types';
import { Svg } from '../../svg';
import { COLOR_MAP } from '../colorMap';

export const PinOnImage = ({ userColor, variant = 'purple' }: Pictogram) => {
  return (
    <Svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      aria-hidden="true"
      className="light"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140 80.5a39.818 39.818 0 0 1-5.706 20.601L100 160.5l-34.297-59.404A39.813 39.813 0 0 1 60 80.5c0-22.092 17.909-40 40-40s40 17.908 40 40Zm-40 13.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.969-13.334-13.333-13.334S86.666 73.136 86.666 80.5 92.636 93.833 100 93.833Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
    </Svg>
  );
};
