import { useMatch } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';
import { Text } from '@mentimeter/ragnar-ui/text';

export function Header({
  children,
  heading,
  shortHeading,
  id,
  loading,
}: {
  children?: React.ReactNode;
  heading?: string;
  shortHeading?: string;
  id?: string;
  loading?: boolean;
}) {
  const isMobile = useMatch({ lessThan: 3 });

  return (
    <Box width="100%" minHeight="24px" mb="space5">
      {loading ? (
        <Skeleton variant="rectangle" height="100%" />
      ) : (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Text
            fontWeight="semiBold"
            color="text"
            fontSize="100"
            mr="space2"
            id={id}
          >
            {isMobile ? (shortHeading ?? heading) : heading}
          </Text>

          <Box
            flex="1"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
}
