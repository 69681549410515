import { useState } from 'react';
import { ContextMenu } from 'features/dashboard/context-menu/ContextMenu';
import { Share } from 'features/dashboard/context-menu/actions/Share';
import { Rename } from 'features/dashboard/context-menu/actions/Rename';
import { Duplicate } from 'features/dashboard/context-menu/actions/Duplicate';
import type { RenameItemProps } from 'features/dashboard/context-menu/modals/RenameModal';
import { RenameModal } from 'features/dashboard/context-menu/modals/RenameModal';
import { DeletePresentation } from 'features/dashboard/context-menu/actions/DeletePresentation';
import type { DeleteSharedTemplateProps } from 'features/dashboard/context-menu/modals/DeleteSharedTemplateModal';
import { DeleteSharedTemplateModal } from 'features/dashboard/context-menu/modals/DeleteSharedTemplateModal';
import type { DeletePresentationProps } from 'features/dashboard/context-menu/modals/DeletePresentationModal';
import { DeletePresentationModal } from 'features/dashboard/context-menu/modals/DeletePresentationModal';
import { ShareAsTemplate } from 'features/dashboard/context-menu/actions/ShareAsTemplate';
import type { PresentationContextMenuProps } from 'features/dashboard/context-menu/useContextMenuContext';
import {
  ShareModal,
  type ShareProps,
} from 'features/dashboard/share/ShareModal';
import type { ShareModalLayoutProps } from 'features/dashboard/share/components/ShareModalLayout';
import { TabType } from 'features/dashboard/share/components/TabSelector';
import { TRACKING_PLACEMENT } from 'features/dashboard/presentation-view/constants';
import { ViewResults } from 'features/dashboard/context-menu/actions/ViewResults';
import { MovePresentation } from 'features/dashboard/context-menu/actions/MovePresentation';
import {
  getPresentationCurrentLocationId,
  MoveModal,
  type MoveModalProps,
} from '@mentimeter/move-modal';
import { useSeriesCache } from 'features/dashboard/use-series-cache';
import { trackMovePresentation } from 'features/dashboard/presentation-view/context-menu/team-actions';
import { isGalleryFolder } from 'features/dashboard/presentation-view/types';
import {
  ModalContentShareWithGroups,
  type ShareWithGroupsModalProps,
} from 'features/dashboard/share-with-groups';
import {
  ModalContentShare,
  type ShareWithOrgModalProps,
} from 'features/dashboard/share-with-org';
import { useFoldersCache } from 'lib/useFolders';

export function HomePresentationContextMenu({
  item,
  user,
  trackingContext,
}: PresentationContextMenuProps) {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteSharedTemplateModalOpen, setDeleteSharedTemplateModalOpen] =
    useState(false);
  const [openShareWithGroupsModal, setOpenShareWithGroupsModal] =
    useState(false);
  const [openShareWithOrgModal, setOpenShareWithOrgModal] = useState(false);
  const [defaultShareTab, setDefaultShareTab] = useState<TabType>(
    TabType.Editors,
  );
  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const { invalidate: invalidatePresentationsCache } = useSeriesCache();
  const { invalidateFoldersCache } = useFoldersCache();

  if (!user || item.userAccess !== 'edit') return null;

  const onMoveModalSelect = () => {
    trackMovePresentation({
      user,
      context: trackingContext,
      placement: TRACKING_PLACEMENT.ACTION_MENU,
      seriesId: item.slideDeckPublicKey,
      presentationHasResults: item.hasResults,
      isWorkspacePresentation: item.workspaceId !== null,
    });
    setMoveModalOpen(true);
  };

  return (
    <>
      <ContextMenu
        trackingContext={trackingContext}
        trackingPlacement={TRACKING_PLACEMENT.RECENT_PRESENTATIONS}
        item={item}
        user={user}
      >
        <Share
          item={item}
          setDefaultShareTab={setDefaultShareTab}
          setShareModalOpen={setShareModalOpen}
        />
        <ShareAsTemplate
          item={item}
          setOpenShareWithGroupsModal={setOpenShareWithGroupsModal}
          setOpenShareWithOrgModal={setOpenShareWithOrgModal}
        />

        <ViewResults item={item} />

        <ContextMenu.Separator />
        <Rename setRenameModalOpen={setRenameModalOpen} />
        <MovePresentation onSelect={() => onMoveModalSelect()} />
        <Duplicate item={item} />

        <ContextMenu.Separator />
        <DeletePresentation
          item={item}
          setDeleteModalOpen={setDeleteModalOpen}
          setDeleteSharedTemplateModalOpen={setDeleteSharedTemplateModalOpen}
        />
      </ContextMenu>

      <Modals
        share={{
          open: shareModalOpen,
          setOpen: setShareModalOpen,
          seriesId: item.slideDeckPublicKey,
          defaultTab: defaultShareTab,
          trackingContext,
        }}
        renameItem={{
          name: item.name,
          id: item.slideDeckPublicKey,
          open: renameModalOpen,
          onOpenChange: setRenameModalOpen,
          isFolder: isGalleryFolder(item),
        }}
        deleteSharedTemplate={{
          open: deleteSharedTemplateModalOpen,
          setOpen: setDeleteSharedTemplateModalOpen,
          itemName: item.name,
        }}
        deletePresentation={{
          open: deleteModalOpen,
          setOpen: setDeleteModalOpen,
          itemName: item.name,
          itemId: item.slideDeckPublicKey,
          trackingContext,
          inUserSharedFolder: false,
          userItemRole: user?.id === item.ownerId ? 'Owner' : 'Collaborator',
        }}
        movePresentation={{
          onOpenChange: () => setMoveModalOpen((s) => !s),
          open: moveModalOpen,
          itemToMoveName: item.name,
          currentLocationId: getPresentationCurrentLocationId(item, user?.id),
          seriesId: item.slideDeckPublicKey,
          invalidateCache: async () => {
            await invalidatePresentationsCache();
            await invalidateFoldersCache();
          },
          trackingDetails: {
            context: trackingContext,
            placement: TRACKING_PLACEMENT.ACTION_MENU,
          },
        }}
        shareWithGroupsModal={{
          id: item.slideDeckPublicKey,
          name: item.name,
          open: openShareWithGroupsModal,
          setOpen: setOpenShareWithGroupsModal,
          onShare: async () => {
            await invalidatePresentationsCache();
          },
          onUnshare: async () => {
            await invalidatePresentationsCache();
          },
        }}
        shareWithOrgModal={{
          open: openShareWithOrgModal,
          setOpen: setOpenShareWithOrgModal,
          id: item.slideDeckPublicKey,
          name: item.name,
          templateId: item.templateId,
        }}
      />
    </>
  );
}

interface ModalsProps {
  share: ShareProps & ShareModalLayoutProps;
  renameItem: RenameItemProps;
  deleteSharedTemplate: DeleteSharedTemplateProps;
  deletePresentation: DeletePresentationProps;
  movePresentation: MoveModalProps;
  shareWithGroupsModal: ShareWithGroupsModalProps;
  shareWithOrgModal: ShareWithOrgModalProps;
}

function Modals(props: ModalsProps) {
  return (
    <>
      <ShareModal {...props.share} />
      <RenameModal {...props.renameItem} />
      <DeleteSharedTemplateModal {...props.deleteSharedTemplate} />
      <DeletePresentationModal {...props.deletePresentation} />
      {props.shareWithOrgModal.open && (
        <ModalContentShare {...props.shareWithOrgModal} />
      )}
      {props.shareWithGroupsModal.open && (
        <ModalContentShareWithGroups {...props.shareWithGroupsModal} />
      )}
      {props.movePresentation.open && <MoveModal {...props.movePresentation} />}
    </>
  );
}
