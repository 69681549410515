import { trackUser } from '@api/tracking/client';
import { isTemplate } from '@mentimeter/core-hooks';
import { core } from '@mentimeter/http-clients';
import { TemplatePlusIcon } from '@mentimeter/ragnar-visuals';
import { useToast } from '@mentimeter/toast';
import { getIsMemberLite } from '@mentimeter/user';
import { useSeriesCache } from 'features/dashboard/use-series-cache';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import { Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { ContextMenu } from '../ContextMenu';
import { useContextMenuContext } from '../useContextMenuContext';

export const ShareAsTemplate = ({
  item,
  setOpenShareWithGroupsModal,
  setOpenShareWithOrgModal,
}: {
  item: SlideDeckOverview;
  setOpenShareWithGroupsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenShareWithOrgModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { trackingContext, user } = useContextMenuContext();
  const toast = useToast();
  const { invalidate: invalidateSeriesCache } = useSeriesCache();

  if (!user?.features.org_template) return null;

  const isSharedWithMe = item.ownerId !== user?.id && !item.workspaceId;
  const disabled = getIsMemberLite(user) || isSharedWithMe;
  const hasGroupsFeature = user?.features.groups;
  const isSharedAsTemplate =
    !hasGroupsFeature && isTemplate(item) && item.templateId;

  const handleSelectUnshareTemplate = () => {
    core()
      .templates.put(item.templateId || item.slideDeckPublicKey, {
        shared_with_organization: false,
      })
      .then(async () => {
        trackUser({
          event: 'Unshared template',
          properties: {
            Template: null,
            'Template ID': null,
            'from group id': null,
            'Removed by': 'owner',
            share_with_workspace: true,
            context: trackingContext,
          },
        });
        await invalidateSeriesCache();
        toast.displayToast({
          description: `Unshared "${item.name}"`,
          autoDismiss: true,
        });
      })
      .catch(() => {
        toast.displayToast({
          description: `Could not unshare "${item.name}"`,
          autoDismiss: true,
          variant: 'negative',
        });
      });
  };

  const handleSelect = () => {
    if (hasGroupsFeature) {
      setOpenShareWithGroupsModal(true);
    } else if (isSharedAsTemplate) {
      handleSelectUnshareTemplate();
    } else {
      setOpenShareWithOrgModal(true);
    }
  };

  const color = disabled ? 'textDisabled' : 'text';

  return (
    <Tooltip
      trigger={
        <ContextMenu.Item onSelect={handleSelect} disabled={disabled}>
          <ContextMenu.ItemIcon>
            <TemplatePlusIcon color={color} />
          </ContextMenu.ItemIcon>
          <ContextMenu.ItemLabel color={color}>
            {isSharedAsTemplate ? 'Unshare' : 'Share'} template
          </ContextMenu.ItemLabel>
        </ContextMenu.Item>
      }
      side="left"
    >
      {isSharedWithMe && 'Only the Menti owner can share as a template'}
    </Tooltip>
  );
};
