export function makeHttpFetchConfigObject({
  userToken,
  headers,
}: {
  userToken: string;
  headers?: Record<string, string>;
}) {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
      ...headers,
    },
  };
}
