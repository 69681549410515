import { TrackingContext } from '@mentimeter/http-clients';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { trackUserEvent } from '../../tracking';
import { Animations } from '../animations';
import { LoaderOverlay } from '../loader/LoaderOverlay';
import { useFetchAvailableThemes } from './hooks/use-fetch-available-themes';
import { themeHoverEffect } from './style-overrides';
import type { ThemeDefinition } from './types';

export const ThemePicker = ({
  onThemePicked,
  shouldAnimate,
  version,
}: {
  onThemePicked: (themeIds: {
    themeId: number | undefined;
    publicThemeName: string | undefined;
  }) => void;
  shouldAnimate: boolean;
  version?: string;
}) => {
  const { availableThemes, isLoading } = useFetchAvailableThemes();

  const handleThemePicked = (theme: ThemeDefinition) => () => {
    trackUserEvent({
      event: 'Selected theme AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
        selected_theme: theme.name,
        theme_id: theme.id,
        theme: theme.displayName,
        ...(version && { version }),
      },
    });

    onThemePicked({
      themeId: theme.id,
      publicThemeName: theme.publicThemeName,
    });
  };

  if (isLoading || !availableThemes?.length) {
    return <LoaderOverlay />;
  }

  return (
    <Box width="100%" alignItems="center">
      <Animations.FadeIn delay={500} skip={!shouldAnimate}>
        <Text
          fontSize={6}
          fontWeight="regular"
          textAlign="center"
          display="block"
        >
          Final thing, choose a theme for your draft.
        </Text>
      </Animations.FadeIn>

      <Animations.FadeIn delay={1000} skip={!shouldAnimate}>
        <Box
          my={4}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="center"
        >
          {availableThemes.map((theme) => (
            <Button
              m={2}
              size="large"
              key={theme.name}
              onClick={handleThemePicked(theme)}
              extend={themeHoverEffect(theme.colors)}
              data-testid="theme-option"
            >
              {theme.displayName}
            </Button>
          ))}
        </Box>
      </Animations.FadeIn>

      <Animations.FadeIn delay={1500} skip={!shouldAnimate}>
        <Text
          fontSize={2}
          fontWeight="regular"
          color="textWeaker"
          textAlign="center"
        >
          You can always change this later
        </Text>
      </Animations.FadeIn>
    </Box>
  );
};
