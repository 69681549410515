import { MentiError } from '@mentimeter/error-utils/error';
import type { FeatureOwner } from '@mentimeter/error-utils/feature-owners';

export function getSlideDeckPublicKeyFromResponse<
  SeriesResponseT extends {
    series: { id: string } | undefined | null;
    slideDeck: { slideDeckPublicKey: string } | undefined | null;
  },
>(
  seriesOrSlideDeck: SeriesResponseT,
  { feature = 'compatibility-layer' }: { feature?: FeatureOwner } = {},
): string {
  if (seriesOrSlideDeck.series) {
    return seriesOrSlideDeck.series.id;
  }

  if (seriesOrSlideDeck.slideDeck) {
    return seriesOrSlideDeck.slideDeck.slideDeckPublicKey;
  }

  throw new MentiError('Failed to fetch series or slide deck', {
    feature,
  });
}
