import React, { useCallback, useState } from 'react';
import {
  StreamDataHandler,
  analyzeInputRequest,
  analyzeEventBodySchema,
  postGenerationMetaRequest,
} from '@mentimeter/ai-api-streamer';
import type {
  AiApiStreamerError,
  AnalyzeEventBodySchema,
  IntentSchema,
  FollowUpSchema,
} from '@mentimeter/ai-api-streamer';
import { TrackingContext } from '@mentimeter/http-clients';
import { useOnce } from '@mentimeter/react-hooks';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { LoaderOverlay } from '../../components/loader/LoaderOverlay';
import type { AIBuilderStepState } from '../../hooks';
import { ErrorScreen } from '../../components/error-screen/ErrorScreen';
import {
  aiStreamerErrorBreadcrumb,
  followUpPromptRequestBreadcrumb,
  followUpPromptResponseBreadcrumb,
  trackUserEvent,
} from '../../tracking';
import {
  getFollowUpQuestion,
  hasMissingEngagementOrTopic,
} from '../../utils/intent-properties';
import { SuggestionTextareaPrompt } from '../../components/suggestion-textarea-prompt/SuggestionTextareaPrompt';

interface FollowUpStepProps {
  followUpPrompt: string;
  intent: IntentSchema | undefined;
  followUp: FollowUpSchema | undefined;
  shouldAnimate: boolean;
  proceedToStep: (step: AIBuilderStepState) => void;
  setFollowUpPrompt: (prompt: string) => void;
  setAnalyzeResponseState: (state: AnalyzeEventBodySchema) => void;
  ablyChannelId: string | undefined;
  ablyStreamUrl: string | undefined;
  onRetry: () => void;
  onBack: (() => AIBuilderStepState) | undefined;
}

const TIMEOUT_DURATION_IN_MS = 45_000;

export const FollowUpStep = ({
  followUpPrompt,
  shouldAnimate,
  proceedToStep,
  setFollowUpPrompt,
  setAnalyzeResponseState,
  ablyChannelId,
  ablyStreamUrl,
  followUp,
  intent,
  onRetry,
  onBack,
}: FollowUpStepProps) => {
  const [requestState, setRequestState] = useState<
    'loading' | 'error' | 'idle'
  >('idle');

  const handleSubmit = async (followUpPrompt: string) => {
    setFollowUpPrompt(followUpPrompt);
    setRequestState('loading');
    try {
      if (!ablyChannelId) {
        throw new Error('ablyChannelId is not defined');
      }

      trackUserEvent({
        event: 'Submitted prompt AI Menti Builder',
        properties: {
          context: TrackingContext.UserHome,
          prompt_length: followUpPrompt.length,
          prompt_step: 'follow-up',
          follow_up_question: getFollowUpQuestion(followUp),
          missing_key: hasMissingEngagementOrTopic(intent),
        },
      });

      followUpPromptRequestBreadcrumb({
        ablyChannelId,
      });

      await analyzeInputRequest({
        userInput: {
          question: followUp?.question?.content ?? 'Unknown question',
          answer: followUpPrompt,
        },
        ablyChannelId,
        intent: intent!,
      });
    } catch (error) {
      aiStreamerErrorBreadcrumb('Unexpected error on submit', {
        promptStep: 'follow-up',
        ablyChannelId,
      });

      setRequestState('error');
    }
  };

  const handleAblyEvent = useCallback(
    (ablyEventData: AnalyzeEventBodySchema | undefined, listening: boolean) => {
      if (!listening || !ablyEventData) {
        return;
      }
      setRequestState('idle');

      setAnalyzeResponseState(ablyEventData);

      followUpPromptResponseBreadcrumb({
        ablyChannelId,
      });

      postGenerationMetaRequest({
        data: {
          prompt_step: 'follow-up',
          followUpPrompt,
          ...ablyEventData,
        },
        aiSessionId: ablyChannelId || '',
        eventName: 'menti_builder/analyze',
      });

      proceedToStep('theme');
    },
    [ablyChannelId, followUpPrompt, proceedToStep, setAnalyzeResponseState],
  );

  const handleError = useCallback(
    (aiApiStreamerError: AiApiStreamerError) => {
      const errorMessage =
        '[AI Menti Builder] Error while listening for events';

      aiStreamerErrorBreadcrumb(errorMessage, {
        promptStep: 'follow-up',
        ablyChannelId,
      });

      captureException(
        new MentiError(errorMessage, {
          feature: 'ai-menti-builder',
          cause: aiApiStreamerError,
        }),
      );
      setRequestState('error');
    },
    [ablyChannelId],
  );

  useOnce(requestState === 'error', () => {
    trackUserEvent({
      event: 'Viewed error message AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
        error_step: 'follow-up',
      },
    });
  });

  if (requestState === 'error') {
    return (
      <ErrorScreen
        onRetry={() => {
          trackUserEvent({
            event: 'Clicked try again AI Menti Builder',
            properties: {
              context: TrackingContext.UserHome,
            },
          });
          onRetry();
          setRequestState('idle');
        }}
      />
    );
  }

  if (!ablyStreamUrl) {
    return <LoaderOverlay />;
  }

  return (
    <StreamDataHandler<AnalyzeEventBodySchema>
      schema={analyzeEventBodySchema}
      ablyStreamUrl={ablyStreamUrl}
      startTimeout={requestState === 'loading'}
      timeoutInMs={TIMEOUT_DURATION_IN_MS}
      onError={handleError}
      onEvent={handleAblyEvent}
    >
      {() => (
        <SuggestionTextareaPrompt
          placeholder="Include things like topic, type of meeting or what you want to achieve..."
          isLoading={requestState === 'loading'}
          label={followUp?.question.content || ''}
          defaultValue={followUpPrompt}
          shouldAnimate={shouldAnimate}
          onSubmit={handleSubmit}
        />
      )}
    </StreamDataHandler>
  );
};
