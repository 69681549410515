// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostSummarizeResponse {
  status: string;
  requestId: string;
}

export async function postSummarizeResponse(
  response: Response,
): Promise<PostSummarizeResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostSummarizeResponse;
}
