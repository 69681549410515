import { useState } from 'react';
import { useOnce } from '@mentimeter/react-hooks';
import { ModalContainer, ModalRoot } from '@mentimeter/ragnar-ui/modal';
import { PaywallModalTrigger } from '../../lib/triggers/PaywallModalTrigger';
import { ImportPaywallModalContent } from './ImportPaywallModalContent';

export function ImportPaywallModal({
  children,
  onOpen,
  trackClickUpgrade,
}: {
  children: React.ReactNode;
  onOpen?: () => void;
  trackClickUpgrade: () => void;
}) {
  const [open, setOpen] = useState(false);

  useOnce(open, () => {
    onOpen?.();
  });

  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <PaywallModalTrigger trigger={() => setOpen(true)}>
        {children}
      </PaywallModalTrigger>
      <ModalContainer size="medium">
        <ImportPaywallModalContent onUpgradeClick={trackClickUpgrade} />
      </ModalContainer>
    </ModalRoot>
  );
}
