import React, { useState } from 'react';
import {
  useAblySetup,
  type AnalyzeEventBodySchema,
} from '@mentimeter/ai-api-streamer';
import { useUser } from '@mentimeter/user';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { useRouter } from '@mentimeter/next-navigation';
import { useOnce } from '@mentimeter/react-hooks';
import { useSWRConfig } from 'swr';
import { LocalStorage } from '@mentimeter/storage';
import { Layout } from './components/Layout';
import { useAiBuilderSteps, useSuggestions } from './hooks';
import { InitialPromptStep, FollowUpStep, BuildStep } from './steps';
import { trackUserEvent, TrackingContext } from './tracking';
import { ErrorScreen } from './components/error-screen/ErrorScreen';

export const CreateWithAi = ({
  prefillPrompt = '',
}: {
  prefillPrompt?: string | undefined;
}) => {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const { step, back, proceedToStep, shouldAnimate } = useAiBuilderSteps();

  const { data: suggestionData } = useSuggestions({
    imu: 'business',
    limit: 5,
  });

  const { user } = useUser();
  const { ablyChannel, ablyUrl, ablyRequestTokenCacheKey } = useAblySetup(
    user?.id,
    {
      feature: 'draft-builder',
    },
  );

  const [initialPrompt, setInitialPrompt] = useState<string>(prefillPrompt);
  const [followUpPrompt, setFollowUpPrompt] = useState<string>('');

  // The analysis response is needed to determine the intent and can be overwritten by follow-up questions
  const [analyzeResponseState, setAnalyzeResponseState] = useState<
    AnalyzeEventBodySchema | undefined
  >();

  const handleSeriesCreationDone = async (
    seriesId: string,
    questionId: string,
  ) => {
    LocalStorage.setItem({
      type: 'functional',
      key: 'ai_menti_builder_csat_version',
      value: '2',
    });

    const createdWithAIQueryParam = '&created-with-ai=true';

    setTimeout(() => {
      router.push(
        buildPresentationPath({
          seriesId,
          questionId,
          mode: 'edit',
        }) + createdWithAIQueryParam,
      );
    }, 3000);
  };

  useOnce(true, () => {
    trackUserEvent({
      event: 'Viewed AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
      },
    });
  });

  const restartFlow = React.useCallback(async () => {
    setFollowUpPrompt('');
    setAnalyzeResponseState(undefined);
    await mutate(ablyRequestTokenCacheKey);
    proceedToStep('prompt');
  }, [
    setFollowUpPrompt,
    setAnalyzeResponseState,
    mutate,
    ablyRequestTokenCacheKey,
    proceedToStep,
  ]);

  switch (step) {
    case 'prompt':
      return (
        <Layout showDisclaimer={true}>
          <InitialPromptStep
            initialPrompt={initialPrompt}
            suggestions={suggestionData?.suggestions ?? []}
            shouldAnimate={shouldAnimate}
            ablyChannelId={ablyChannel}
            ablyStreamUrl={ablyUrl}
            setInitialPrompt={setInitialPrompt}
            proceedToStep={proceedToStep}
            setAnalyzeResponseState={setAnalyzeResponseState}
            onRetry={restartFlow}
          />
        </Layout>
      );
    case 'follow-up':
      return (
        <Layout>
          <FollowUpStep
            followUpPrompt={followUpPrompt}
            intent={analyzeResponseState?.intent}
            followUp={analyzeResponseState?.followUp}
            shouldAnimate={shouldAnimate}
            proceedToStep={proceedToStep}
            setFollowUpPrompt={setFollowUpPrompt}
            setAnalyzeResponseState={setAnalyzeResponseState}
            ablyChannelId={ablyChannel}
            ablyStreamUrl={ablyUrl}
            onRetry={restartFlow}
            onBack={back}
          />
        </Layout>
      );

    case 'theme':
    case 'building':
      return (
        <Layout>
          <BuildStep
            intent={analyzeResponseState?.intent}
            ablyChannelId={ablyChannel}
            ablyStreamUrl={ablyUrl}
            shouldAnimate={shouldAnimate}
            handleSeriesCreationDone={handleSeriesCreationDone}
            onRetry={restartFlow}
          />
        </Layout>
      );

    default:
      return (
        <Layout>
          <ErrorScreen onRetry={restartFlow} />
        </Layout>
      );
  }
};
