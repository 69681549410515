import React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import {
  ModalContainer,
  ModalBody,
  ModalDismiss,
  ModalTitle,
} from '@mentimeter/ragnar-ui/modal';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Light } from '@mentimeter/ragnar-ui/themes';

export const CreateWithAiModalLayout = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <ModalContainer
      width="90vw"
      maxWidth="1300px"
      height="90vh"
      maxHeight="800px"
    >
      <Light>
        <Box bg="bg" flex="1 0 auto">
          <Box
            flexDirection="row"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            p={4}
            zIndex={1}
          >
            <ModalTitle size="small">Start with AI</ModalTitle>
            <ModalDismiss asChild>
              <Clickable p={4} position="absolute" right={0} top={0} zIndex={1}>
                <CrossIcon size={3} />
              </Clickable>
            </ModalDismiss>
          </Box>

          {/* negative margin in order to center content related to the whole modal*/}
          <ModalBody p={4} pt={4} mt="-90px">
            {children}
          </ModalBody>
        </Box>
      </Light>
    </ModalContainer>
  );
};
