// This file is automatically generated. DO NOT EDIT.

import { type SlideDeckOverview } from '../../../types/response/SlideDeckOverview';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface GetRecentResponse {
  /** The user's most recently visited presentations */
  series: Array<SlideDeckOverview>;
}

export async function getRecentResponse(
  response: Response,
  options?: GetJSONResponseOptions<GetRecentResponse>,
): Promise<GetRecentResponse> {
  return getJSONResponse<GetRecentResponse>(
    response,
    'getRecentResponse',
    options,
  );
}
