import type { Question } from '@mentimeter/http-clients';
import { v4 as uuid } from 'uuid';

export const defaultCoreQuestionFactory = (
  props: Partial<Question>,
): Partial<Question> => {
  return {
    id: uuid(),
    question_description: '',
    sub_type: null,
    ...props,
  };
};

export const isInteractiveQuestion = (question: Partial<Question>): boolean => {
  switch (question.type) {
    case 'choices':
    case 'scales':
    case 'ranking':
    case 'prioritisation':
    case 'quiz':
    case 'quiz_open':
    case 'wordcloud':
    case 'open':
      return true;
    default:
      return false;
  }
};
