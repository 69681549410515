import { useRef } from 'react';

export function useStaticOrder<T>(list: T[], getId: (item: T) => any) {
  const staticList = useRef(list.map(getId));

  return list
    .slice()
    .sort(
      (a, b) =>
        staticList.current.indexOf(getId(a)) -
        staticList.current.indexOf(getId(b)),
    );
}
