import React from 'react';
import type { Pictogram } from '../types';
import { Svg } from '../../svg';
import { COLOR_MAP } from '../colorMap';

export const MultipleChoice = ({
  userColor,
  variant = 'purple',
}: Pictogram) => {
  return (
    <Svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      aria-hidden="true"
      className="light"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152 49h-34v34H84v33H50v33h102V49Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
    </Svg>
  );
};
