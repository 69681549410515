import React from 'react';
import type { Pictogram } from '../types';
import { Svg } from '../../svg';
import { COLOR_MAP } from '../colorMap';

export const Wordcloud = ({ userColor, variant = 'coral' }: Pictogram) => {
  return (
    <Svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      aria-hidden="true"
      className="light"
    >
      <path
        d="M134.484 100C142.519 95.7353 148 87.2455 148 77.4639C148 63.4006 136.684 52 122.726 52C112.732 52 104.098 57.8442 100 66.3227C95.896 57.8442 87.2623 52 77.2738 52C63.3155 52 52 63.4006 52 77.4639C52 87.2511 57.4814 95.741 65.5159 100C57.4814 104.265 52 112.754 52 122.536C52 136.599 63.3155 148 77.2738 148C87.2679 148 95.9016 142.156 100 133.677C104.098 142.156 112.738 148 122.726 148C136.684 148 148 136.599 148 122.536C148 112.749 142.519 104.259 134.484 100Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
    </Svg>
  );
};
