/* eslint-disable menti-react/filename-convention--jsx */
import { Link } from '@mentimeter/next-navigation';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading, Media, Paragraph, Section, Strong } from './styles';

export function HowParticipantsJoin() {
  return (
    <Box>
      <Section>
        <Media
          mediaType="lottie"
          mediaUrl="https://static.mentimeter.com/static/motion/how-to-join.lottie"
        />
      </Section>
      <Section>
        <Heading>Scan a QR code</Heading>
        <Paragraph>
          Press &quot;I&quot; when presenting to{' '}
          <Link href="https://help.mentimeter.com/en/articles/5766150-show-the-voting-instructions-with-an-instructions-slide">
            show a QR code
          </Link>{' '}
          that your participants can scan with their phones to quickly join.
          Their voting is anonymous to ensure honest answers.
        </Paragraph>
      </Section>
      <Section>
        <Heading>Enter a code on menti.com</Heading>
        <Paragraph>
          Participants can go to{' '}
          <Link href="https://www.menti.com/">menti.com</Link> on any device or
          computer and enter an 8-digit code that is visible on the top of your
          Menti. Remember, participants do not need an account to vote.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Click on a participation link</Heading>
        <Paragraph>
          You can also{' '}
          <Link href="https://help.mentimeter.com/en/articles/410895-let-your-audience-connect-to-the-presentation-via-a-link">
            share a participation link
          </Link>{' '}
          with your audience—a perfect fit for surveys and video calls. Find the
          participation link by clicking <Strong>Share</Strong> in the editor.
          Once your audience clicks the link, they can start participating
          without a code.
        </Paragraph>
      </Section>
    </Box>
  );
}
