import React, { useCallback, useState } from 'react';
import {
  StreamDataHandler,
  analyzeInputRequestV3,
  analyzeEventBodySchemaV3,
  postGenerationMetaRequest,
} from '@mentimeter/ai-api-streamer';
import type {
  AiApiStreamerError,
  AnalyzeEventBodySchemaV3,
} from '@mentimeter/ai-api-streamer';
import { TrackingContext } from '@mentimeter/http-clients';
import { useOnce } from '@mentimeter/react-hooks';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import {
  aiStreamerErrorBreadcrumb,
  followUpPromptRequestBreadcrumb,
  followUpPromptResponseBreadcrumb,
  trackUserEvent,
} from '../../../tracking';
import type { AIBuilderStepState } from '../../../hooks';
import { ErrorScreen } from '../../../components/error-screen/ErrorScreen';
import { LoaderOverlay } from '../../../components/loader/LoaderOverlay';
import { SuggestionTextareaPromptV3 } from '../../../components/suggestion-textarea-prompt-v3/SuggestionTextareaPromptV3';
import Attachment from '../../../components/attachment/Attachment';

interface FollowUpStepProps {
  followUpPrompt: string;
  intent: AnalyzeEventBodySchemaV3 | undefined;
  shouldAnimate: boolean;
  proceedToStep: (step: AIBuilderStepState) => void;
  setFollowUpPrompt: (prompt: string) => void;
  setAnalyzeResponseState: (state: AnalyzeEventBodySchemaV3) => void;
  ablyChannelId: string | undefined;
  ablyStreamUrl: string | undefined;
  onRetry: () => void;
}

const TIMEOUT_DURATION_IN_MS = 45_000;

export const FollowUpStep = ({
  followUpPrompt,
  shouldAnimate,
  proceedToStep,
  setFollowUpPrompt,
  setAnalyzeResponseState,
  ablyChannelId,
  ablyStreamUrl,
  intent,
  onRetry,
}: FollowUpStepProps) => {
  const [requestState, setRequestState] = useState<
    'loading' | 'error' | 'idle'
  >('idle');

  const followUpQuestion =
    "Thank you! Here's a suggestion on what Mentimeter can do for you.";

  const handleSubmit = async (followUpPrompt: string) => {
    setFollowUpPrompt(followUpPrompt);
    setRequestState('loading');
    try {
      if (!ablyChannelId) {
        throw new Error('ablyChannelId is not defined');
      }
      trackUserEvent({
        event: 'Submitted prompt AI Menti Builder',
        properties: {
          context: TrackingContext.UserHome,
          prompt_length: followUpPrompt.length,
          prompt_step: 'follow-up',
          version: '3',
        },
      });

      followUpPromptRequestBreadcrumb({
        ablyChannelId,
      });

      await analyzeInputRequestV3({
        userInput: {
          question: followUpQuestion,
          answer: followUpPrompt,
        },
        ablyChannelId,
        intentResponse: intent!,
      });
    } catch (error) {
      aiStreamerErrorBreadcrumb('Unexpected error on submit', {
        promptStep: 'follow-up',
        ablyChannelId,
      });

      setRequestState('error');
    }
  };

  const handleAblyEvent = useCallback(
    (
      ablyEventData: AnalyzeEventBodySchemaV3 | undefined,
      listening: boolean,
    ) => {
      if (!listening || !ablyEventData) {
        return;
      }
      setRequestState('idle');

      setAnalyzeResponseState(ablyEventData);

      followUpPromptResponseBreadcrumb({
        ablyChannelId,
      });

      postGenerationMetaRequest({
        data: {
          prompt_step: 'follow-up',
          followUpPrompt,
          ...ablyEventData,
        },
        aiSessionId: ablyChannelId || '',
        eventName: 'menti_builder/analyze_v3',
      });

      proceedToStep('theme');
    },
    [ablyChannelId, followUpPrompt, proceedToStep, setAnalyzeResponseState],
  );

  const handleError = useCallback(
    (aiApiStreamerError: AiApiStreamerError) => {
      const errorMessage =
        '[AI Menti Builder] Error while listening for events';

      aiStreamerErrorBreadcrumb(errorMessage, {
        promptStep: 'follow-up',
        ablyChannelId,
      });

      captureException(
        new MentiError(errorMessage, {
          feature: 'ai-menti-builder',
          cause: aiApiStreamerError,
        }),
      );
      setRequestState('error');
    },
    [ablyChannelId],
  );

  useOnce(requestState === 'error', () => {
    trackUserEvent({
      event: 'Viewed error message AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
        error_step: 'follow-up',
        version: '3',
      },
    });
  });

  if (requestState === 'error') {
    return (
      <ErrorScreen
        onRetry={() => {
          trackUserEvent({
            event: 'Clicked try again AI Menti Builder',
            properties: {
              context: TrackingContext.UserHome,
              version: '3',
            },
          });
          onRetry();
          setRequestState('idle');
        }}
      />
    );
  }

  if (!ablyStreamUrl) {
    return <LoaderOverlay />;
  }

  return (
    <StreamDataHandler<AnalyzeEventBodySchemaV3>
      schema={analyzeEventBodySchemaV3}
      ablyStreamUrl={ablyStreamUrl}
      startTimeout={requestState === 'loading'}
      timeoutInMs={TIMEOUT_DURATION_IN_MS}
      onError={handleError}
      onEvent={handleAblyEvent}
    >
      {() => (
        <SuggestionTextareaPromptV3
          placeholder="Include things like topic, type of meeting or what you want to achieve..."
          isLoading={requestState === 'loading'}
          label={followUpQuestion}
          attachment={
            intent?.hasAttachment &&
            intent.userInput[0] && (
              <Attachment text={intent.userInput[0]?.answer.slice(0, 200)} />
            )
          }
          defaultValue={intent?.enhancedPrompt || ''}
          shouldAnimate={shouldAnimate}
          onSubmit={handleSubmit}
        />
      )}
    </StreamDataHandler>
  );
};
