import { captureException } from '@mentimeter/errors/sentry';
import { useSSE } from '@mentimeter/server-sent-events';
import React, { useEffect } from 'react';
import type z from 'zod';
import {
  parseSSEEventBase,
  parseSSEEventDataWithSchema,
  transformObjectKeysToCamelCase,
} from './validators';
import {
  clearAsyncRequestTimeout,
  createErrorHandlerTimeout,
} from './utils/timeout-handlers';
import { AiApiStreamerError } from './errors';

const DEFAULT_TIMEOUT_IN_MS = 30_000;

interface AiApiStreamer<K> {
  listening: boolean;
  data: K | undefined;
  error: AiApiStreamerError | undefined;
  actions: {
    stopListening: () => void;
    startListening: () => void;
  };
}

interface ApiStreamerOptions {
  connectOnInit?: boolean | undefined;
  startTimeout?: boolean | undefined;
  timeoutInMs?: number | undefined;
  onError?: ((error: AiApiStreamerError) => void) | undefined;
}

export const useAiApiStreamer = <K>(
  streamingUrl: string,
  schema: z.Schema,
  options: ApiStreamerOptions,
): AiApiStreamer<K> => {
  const {
    connectOnInit = false,
    startTimeout = false,
    timeoutInMs = DEFAULT_TIMEOUT_IN_MS,
    onError,
  } = options;
  const [error, setError] = React.useState<AiApiStreamerError | undefined>();
  const [data, setData] = React.useState<K>();
  const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const runTimer = React.useCallback(() => {
    if (!startTimeout) {
      clearAsyncRequestTimeout(timeoutRef);
      return;
    }

    timeoutRef.current = createErrorHandlerTimeout(
      timeoutInMs,
      (timeoutError: AiApiStreamerError) => {
        setError(timeoutError);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onError && onError(timeoutError);
      },
    );
  }, [startTimeout, timeoutInMs, onError]);

  useEffect(() => {
    if (startTimeout) {
      runTimer();
    }
    return () => clearAsyncRequestTimeout(timeoutRef);
  }, [startTimeout, runTimer]);

  const {
    listening,
    start: startListening,
    stop: stopListening,
  } = useSSE(
    streamingUrl,
    React.useCallback(
      ({ data: rawData }: MessageEvent<string>) => {
        try {
          const ablyEvent = parseSSEEventBase(rawData);

          switch (ablyEvent.name) {
            case 'data': {
              const parsedData = parseSSEEventDataWithSchema(
                ablyEvent.data,
                schema,
              );
              const data = transformObjectKeysToCamelCase(parsedData);
              setData(data);
              clearAsyncRequestTimeout(timeoutRef);
              return;
            }
            case 'error':
              const error = new AiApiStreamerError(
                'Got error event from the stream',
                {
                  feature: 'user-success',
                  cause: { eventPayload: ablyEvent.data },
                },
              );
              setError(error);
              clearAsyncRequestTimeout(timeoutRef);
              captureException(error);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              onError && onError(error);
          }
        } catch (error: unknown) {
          const aiApiStreamerError = new AiApiStreamerError(
            'Error while parsing an SSE event',
            {
              feature: 'user-success',
              cause: { error, rawData },
            },
          );
          setError(aiApiStreamerError);
          clearAsyncRequestTimeout(timeoutRef);
          captureException(aiApiStreamerError);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onError && onError(aiApiStreamerError);
        }
      },
      [onError, schema],
    ),
    { connectOnInit },
    (error) => {
      const aiApiStreamerError = new AiApiStreamerError(
        'Error while connecting to the Event Source',
        {
          feature: 'user-success',
          cause: { error },
        },
      );
      setError(aiApiStreamerError);
      clearAsyncRequestTimeout(timeoutRef);
      captureException(aiApiStreamerError);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onError && onError(aiApiStreamerError);
    },
  );

  return {
    listening,
    data,
    error,
    actions: { stopListening, startListening },
  };
};
