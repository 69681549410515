import React from 'react';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

interface SectionProps {
  title: string;
  legend: string;
  children: React.ReactNode;
}

export function FormSection({ title, legend, children }: SectionProps) {
  const [showSection, setShowSection] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const heightRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setHeight((heightRef.current && heightRef.current.clientHeight) ?? 0);
  }, [setHeight, showSection]);

  return (
    <Box
      mb="space4"
      extend={({ theme }) => ({
        marginLeft: `${-theme.kosmosSpacing.space0}px`,
        width: `calc(100% + ${theme.kosmosSpacing.space4}px)`,
      })}
    >
      <Box
        width="100%"
        flexDirection="row"
        alignItems="center"
        onClick={() => setShowSection(!showSection)}
        borderRadius="xl"
        p="space2"
        extend={({ theme }) => ({
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme.colors.secondaryWeakest,
          },
        })}
      >
        <Text fontSize="100" fontWeight="semiBold">
          {title}
        </Text>

        <Box
          ml="space1"
          extend={({ theme }) => ({
            transform: showSection ? 'rotate(-180deg)' : 'rotate(0deg)',
            transition: `transform ${theme.durations[1]}s`,
          })}
        >
          <ChevronDownIcon size={3} />
        </Box>
      </Box>

      <Box
        width="100%"
        px="space2"
        extend={({ theme }) => ({
          maxHeight: showSection ? `${height}px` : 0,
          overflow: 'hidden',
          transition: `max-height ${theme.durations[1]}s`,
        })}
      >
        <Box
          ref={heightRef}
          width="100%"
          display="grid"
          gridTemplateColumns="1fr"
          alignItems="end"
          mt="space2"
        >
          {children}
        </Box>
      </Box>

      {!showSection && (
        <Box px="space2">
          <Text fontSize="100" color="textWeaker">
            {legend}
          </Text>
        </Box>
      )}
    </Box>
  );
}
