import useSWR from 'swr';
import type {
  FetchGroupsResponseT,
  FetchGroupDetailsResponseT,
  FetchGroupMembersResponseT,
  GroupMemberT,
  GroupT,
} from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import { core } from '@mentimeter/http-clients';

export const useGroups = (isAdmin = false) => {
  const { user } = useUser();
  const groupsFeatureOn = user?.features?.groups !== false;
  const { data, error } = useSWR<FetchGroupsResponseT | null, any>(
    'get-groups',
    async () => {
      if (!groupsFeatureOn) return null;
      //isAdmin false returns groups user belongs to, true all groups in workspace
      const { data } = await core().groups.getAll({ isAdmin });
      return data;
    },
  );
  if (error)
    return {
      groups: [],
      isLoading: false,
      error,
    };
  else if (!data)
    return {
      groups: [],
      isLoading: groupsFeatureOn,
      error: null,
    };

  // @ts-expect-error-auto TS(2322) FIXME: Type '{ id: string; name: string; description: str... Remove this comment to see the full error message
  const groups: GroupT[] =
    data.data.map((group) => ({
      id: group.id,
      name: group.name,
      description: group.description,
      memberCount: group.member_count,
      templateCount: group.template_count,
      themeCount: group.theme_count,
      creator: group.creator,
    })) ?? [];

  return {
    groups,
    isLoading: false,
    error: null,
  };
};

export const useGroup = (groupId: string | undefined) => {
  const { data, error } = useSWR<
    FetchGroupDetailsResponseT | null,
    any,
    [string, string | undefined]
  >(['get-group-details', groupId], async ([_, id]) => {
    if (!id) return null;
    const { data } = await core().groups.get({ id });
    return data;
  });

  if (error)
    return {
      group: null,
      isLoading: false,
      error,
    };
  else if (!data)
    return {
      group: null,
      isLoading: true,
      error: null,
    };

  // @ts-expect-error-auto TS(2322) FIXME: Type '{ id: string; name: string; description: str... Remove this comment to see the full error message
  const group: GroupT = {
    id: data.id,
    name: data.name,
    description: data.description,
    creator: data.creator,
    memberCount: data.member_count,
    templateCount: data.template_count,
    themeCount: data.theme_count,
  };
  return {
    group,
    isLoading: false,
    error: null,
  };
};

export const useGroupMembers = (groupId: string | undefined) => {
  const { data, error } = useSWR<
    FetchGroupMembersResponseT | null,
    any,
    [string, string | undefined]
  >(['get-group-members', groupId], async ([_, id]) => {
    if (!id) return null;
    const { data } = await core().groups.getMembers({ id });
    return data;
  });

  if (error)
    return {
      members: [],
      isLoading: false,
      error,
    };
  else if (!data)
    return {
      members: [],
      isLoading: true,
      error: null,
    };
  const members: GroupMemberT[] =
    data.data.map((data) => {
      const { id, name, email, status, profile_picture_url } = data.user;
      return {
        id,
        name,
        email: email ? email.toLowerCase() : '',
        status,
        profile_picture_url,
      };
    }) ?? [];

  return {
    members,
    isLoading: false,
    error: null,
  };
};
