import type { DSC } from '@mentimeter/ragnar-dsc';
import type { ButtonColorsT, RagnarButtonState, VariantT } from './types';
import { ERROR_STATE, SUCCESS_STATE } from './constants';

export const getColors = ({
  theme,
  state,
  variant,
}: {
  theme: DSC;
  state: RagnarButtonState | undefined;
  variant: VariantT;
}) => {
  const VARIANT_MAP: Record<VariantT, ButtonColorsT> = {
    primary: {
      color: theme.colors.onPrimary,
      backgroundColor: theme.colors.buttonBgPrimary,
      hoverColor: theme.colors.onPrimary,
      hoverBackgroundColor: theme.colors.buttonBgPrimaryHover,
      activeColor: theme.colors.onPrimary,
      activeBackgroundColor: theme.colors.buttonBgPrimaryActive,
    },
    secondary: {
      color: theme.colors.text,
      backgroundColor: 'transparent',
      hoverColor: theme.colors.text,
      hoverBackgroundColor: theme.colors.buttonBgSecondaryHover,
      activeColor: theme.colors.text,
      activeBackgroundColor: theme.colors.buttonBgSecondaryActive,
    },
    tertiary: {
      color: theme.colors.text,
      backgroundColor: theme.colors.buttonBgTertiary,
      hoverColor: theme.colors.text,
      hoverBackgroundColor: theme.colors.buttonBgTertiaryHover,
      activeColor: theme.colors.text,
      activeBackgroundColor: theme.colors.buttonBgTertiaryActive,
    },
    brand: {
      color: theme.colors.onBrand,
      backgroundColor: theme.colors.buttonBgBrand,
      hoverColor: theme.colors.onBrand,
      hoverBackgroundColor: theme.colors.buttonBgBrandHover,
      activeColor: theme.colors.onBrand,
      activeBackgroundColor: theme.colors.buttonBgBrandActive,
    },
    outline: {
      color: theme.colors.text,
      backgroundColor: 'transparent',
      borderColor: theme.colors.primary,
      hoverColor: theme.colors.text,
      hoverBackgroundColor: theme.colors.buttonBgOutlineHover,
      hoverBorderColor: theme.colors.primary,
      activeColor: theme.colors.text,
      activeBackgroundColor: theme.colors.buttonBgOutlineActive,
      activeBorderColor: theme.colors.primary,
    },
    negative: {
      color: theme.colors.onNegative,
      backgroundColor: theme.colors.buttonBgNegative,
      hoverColor: theme.colors.onNegative,
      hoverBackgroundColor: theme.colors.buttonBgNegativeHover,
      activeColor: theme.colors.onNegative,
      activeBackgroundColor: theme.colors.buttonBgNegativeActive,
    },
    negativeWeak: {
      color: theme.colors.onNegativeWeakest,
      backgroundColor: theme.colors.buttonBgNegativeWeak,
      hoverColor: theme.colors.onNegativeWeakest,
      hoverBackgroundColor: theme.colors.buttonBgNegativeWeakHover,
      activeColor: theme.colors.onNegativeWeakest,
      activeBackgroundColor: theme.colors.buttonBgNegativeWeakActive,
    },
    positive: {
      color: theme.colors.onPositive,
      backgroundColor: theme.colors.buttonBgPositive,
      hoverColor: theme.colors.onPositive,
      hoverBackgroundColor: theme.colors.buttonBgPositiveHover,
      activeColor: theme.colors.onPositive,
      activeBackgroundColor: theme.colors.buttonBgPositiveActive,
    },
    subtle: {
      color: theme.colors.text,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      hoverColor: theme.colors.text,
      hoverBackgroundColor: 'transparent',
      hoverBorderColor: 'transparent',
      activeColor: theme.colors.text,
      activeBackgroundColor: 'transparent',
      activeBorderColor: 'transparent',
    },
  };

  return VARIANT_MAP[
    state === SUCCESS_STATE
      ? 'positive'
      : state === ERROR_STATE
        ? 'negative'
        : variant
  ];
};
