import { generateSlideBodySchema } from '@mentimeter/ai-api-streamer';
import { z } from 'zod';

export const aiSeriesSchema = z.object({
  name: z.string(),
  comments: z.boolean(),
  pace: z.boolean(),
  qna_feature: z.boolean(),
  slides: z.array(generateSlideBodySchema),
});
