import Z from 'zod';
import { aiApiGeneratedSlideSchemaV3 } from './ai-api-slides-schema-v3';

export const createSeriesEventBodySchemaV3 = Z.object({
  slides: Z.array(aiApiGeneratedSlideSchemaV3),
  name: Z.string(),
  // Making them optional as they might not be present until
  // the AI publisher is updated
  qna_feature: Z.boolean().optional(),
  comments: Z.boolean().optional(),
  pace: Z.boolean().optional(), // True: audience, false/default: presenter
});

export type CreateSeriesEventBodySchemaV3 = Z.infer<
  typeof createSeriesEventBodySchemaV3
>;
