'use client';

import { useMatch } from '@mentimeter/ragnar-device';
import { useUser } from '@mentimeter/user';
import { useFlag } from '@mentimeter/flags/confidence/client';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';

export const UserGreeting = () => {
  const { user } = useUser();
  const name = user?.name ?? null;

  const isMobile = useMatch({ lessThan: 3 });
  useFlag('dashboard-aa.enabled', false);

  return (
    <Box
      flexDirection="row"
      alignItems={['baseline', 'flex-end']}
      maxWidth={['90%', '100%']}
    >
      <Text
        color="text"
        fontSize={[5, 6]}
        textAlign="left"
        data-testid="welcome-message"
      >
        {isMobile ? (
          <>
            Welcome{name ? ',' : '!'}
            <br />
            {name ?? ''}
          </>
        ) : (
          <>Welcome{name ? ', ' + name : '!'}</>
        )}
      </Text>
    </Box>
  );
};
