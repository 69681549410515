/* eslint-disable menti-react/filename-convention--jsx */
import {
  type AnimationConfig,
  animated,
  useSpring,
  useTrail,
} from '@react-spring/web';
import React from 'react';

type Unpacked<T> = T extends (infer U)[] ? U : T;

const FadeInItems = <T extends any[]>({
  data,
  renderItem: RenderItem,
  getKey,
  config = { duration: 1000 },
}: {
  data: T;
  getKey: (item: Unpacked<T>) => string;
  renderItem: React.ComponentType<{ item: Unpacked<T>; index: number }>;
  config?: Partial<AnimationConfig>;
}) => {
  const trails = useTrail(data.length, {
    config,
    opacity: 1,
    from: { opacity: 0 },
  });

  return trails.map((style, index) => (
    <animated.div key={getKey(data[index])} style={style}>
      <RenderItem item={data[index]} index={index} />
    </animated.div>
  ));
};

const FadeIn = ({
  children,
  delay = 500,
  style,
  show = true,
  skip = false,
}: {
  show?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  delay?: number;
  skip?: boolean;
}) => {
  const [props, api] = useSpring(() => ({
    from: { opacity: 0, y: 50 },
  }));

  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  React.useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(
      () => {
        api.start({ opacity: show ? 1 : 0, y: 0, immediate: skip });
      },
      skip ? 0 : delay,
    );
  }, [api, delay, show, skip]);

  return (
    <animated.div
      style={{
        ...props,
        ...style,
      }}
    >
      {children}
    </animated.div>
  );
};

export const Animations = {
  FadeInItems,
  FadeIn,
};
