import useSWR, { type SWRResponse } from 'swr';
import type Ably from 'ably';
import { getRealtimeToken } from '../http';

export const useFetchAblyRealtimeToken = (
  requestCacheKey: string = '/ai-api-streamer/realtime-token',
): SWRResponse<Ably.TokenDetails> => {
  return useSWR(
    requestCacheKey,
    async () => {
      return getRealtimeToken();
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      errorRetryCount: 3,
    },
  );
};
