import React from 'react';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';

export const CircleButton = (props: ButtonT) => {
  return (
    <Button
      {...props}
      extend={({ theme }) => ({
        width: '40px',
        height: '40px',
        padding: 0,
      })}
    />
  );
};
