// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostGenerateSlideResponse {
  status: string;
  requestId: string;
}

export async function postGenerateSlideResponse(
  response: Response,
): Promise<PostGenerateSlideResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostGenerateSlideResponse;
}
