// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostAnalyzeRequest {
  region: 'eu' | 'us';
  userAuth: string;
  userInput: {
    question: string;
    answer: string;
  };
  intentResponse?: {
    tool: string;
    hasAttachment: boolean;
    hasStructureInstructions: boolean;
    enhancedPrompt: string;
    question?: string;
    isInvalidInput: boolean;
    imageKeyword: string;
    userInput: Array<{
      question: string;
      answer: string;
    }>;
  };
  ablyChannelId: string;
  requestId?: string;
}

export function postAnalyzeRequest(requestParams: PostAnalyzeRequest): Request {
  const data = {
    user_input: toSnakeKeys(requestParams.userInput),
    intent_response: toSnakeKeys(requestParams.intentResponse),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/draft-builder/v3/analyze`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
