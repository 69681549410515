// This file is automatically generated. DO NOT EDIT.

import { type Question } from '../../../../../types/response/Question';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface PostSlidesResponse {
  /** the updated question */
  question: Question;
}

export async function postSlidesResponse(
  response: Response,
  options?: GetJSONResponseOptions<PostSlidesResponse>,
): Promise<PostSlidesResponse> {
  return getJSONResponse<PostSlidesResponse>(
    response,
    'postSlidesResponse',
    options,
  );
}
