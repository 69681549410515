import type { Question } from '@mentimeter/http-clients';
import type { QuestionImage } from './image-utils';

export const mapQuestionsToUpload = ({
  localStateQuestions,
  remoteCoreQuestions,
}: {
  localStateQuestions: Array<Partial<Question>>;
  remoteCoreQuestions: Question[];
}): QuestionImage[] => {
  return remoteCoreQuestions.map((remoteCoreQuestion, index) => {
    const localStateQuestion = localStateQuestions[index];
    if (!localStateQuestion) {
      throw new Error(`Question at index ${index} is undefined`);
    }
    return {
      cropped_image_url: localStateQuestion.cropped_image_url,
      id: remoteCoreQuestion.id,
    };
  });
};
