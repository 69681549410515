/* eslint-disable menti-react/filename-convention--jsx */
import type { JSX } from 'react';
import { CONTENT_BY_SLUG } from './guide-content';
import type { GuideSlugT } from './guides';

// eslint-disable-next-line react/jsx-no-useless-fragment
const FALLBACK_CONTENT = { header: <></>, body: <></> };

export function getGuideContent(guideSlug: GuideSlugT): () => JSX.Element {
  return CONTENT_BY_SLUG[guideSlug] ?? FALLBACK_CONTENT;
}
