// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostMetaRequest {
  region: 'eu' | 'us';
  userAuth: string;
  eventName: string;
  data: unknown;
  aiSessionId: string;
  seriesId?: string;
}

export function postMetaRequest(requestParams: PostMetaRequest): Request {
  const data = {
    data: requestParams.data,
    event_name: toSnakeKeys(requestParams.eventName),
    ai_session_id: toSnakeKeys(requestParams.aiSessionId),
    series_id: toSnakeKeys(requestParams.seriesId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/meta`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
