// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostGroupResponse {
  status: string;
  requestId: string;
}

export async function postGroupResponse(
  response: Response,
): Promise<PostGroupResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostGroupResponse;
}
