// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostExtractKeyPointsResponse {
  status: string;
  requestId: string;
}

export async function postExtractKeyPointsResponse(
  response: Response,
): Promise<PostExtractKeyPointsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostExtractKeyPointsResponse;
}
