import { useEffect, useRef, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { makeAblyStreamUrl, makeAiApiAblyChannel } from './utils/stream-url';
import { useFetchAblyRealtimeToken } from './use-fetch-ably-realtime-token';
import type { AiFeature } from './types';

interface AblySetup {
  userId: number | undefined;
  accessToken: string | undefined;
  ablyUrl: string | undefined;
  ablyChannel: string | undefined;
  ablyStreamId: string;
  ablyRequestTokenCacheKey: string;
}

export const useAblySetup = (
  userId: number | undefined,
  config: { feature: AiFeature },
): AblySetup => {
  // Each StreamId is unique every time the modal is opened
  const ablyStreamIdRef = useRef<string>(uuidV4());
  const ablyRequestTokenCacheKey = `/ai-api-streamer/realtime-token/${ablyStreamIdRef.current}`;

  const { data: tokenDetails } = useFetchAblyRealtimeToken(
    ablyRequestTokenCacheKey,
  );

  const accessToken = tokenDetails?.token;

  const [ablyChannel, setAblyChannel] = useState<string>();
  const [ablyUrl, setAblyUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!userId) {
      return;
    }

    if (ablyChannel) {
      return;
    }

    setAblyChannel(
      makeAiApiAblyChannel({
        userId,
        feature: config.feature,
        randomChannelId: ablyStreamIdRef.current,
      }),
    );
  }, [ablyChannel, userId, config.feature]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    if (!accessToken) {
      return;
    }

    if (ablyUrl) {
      return;
    }

    setAblyUrl(
      makeAblyStreamUrl({
        userId,
        feature: config.feature,
        ablyAccessToken: accessToken,
        randomChannelId: ablyStreamIdRef.current,
      }),
    );
  }, [userId, accessToken, ablyUrl, config.feature]);

  return {
    userId,
    accessToken,
    ablyUrl,
    ablyChannel,
    ablyStreamId: ablyStreamIdRef.current,
    ablyRequestTokenCacheKey,
  };
};
