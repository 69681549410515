import {
  useFlag as _useFlag,
  type FlagQuery,
  type PrimitiveValue,
  type ReactFlagEvaluationOptions,
} from '@openfeature/react-sdk';

export function useFlag<K extends string, V extends PrimitiveValue>(
  key: K,
  defaultFlagValue: V,
  options?: ReactFlagEvaluationOptions,
) {
  return _useFlag(key, defaultFlagValue, options) as FlagQuery<V>;
}
