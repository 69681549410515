import {
  postRequestAiAccessRequest,
  postRequestAiAccessResponse,
} from '@core-api/ai-opt-in/ai-api/request-ai-access';
import { MentiError } from '@mentimeter/errors/sentry';
import type { TrackingContext } from '@mentimeter/http-clients';
import { LocalStorage } from '@mentimeter/storage';
import { userCache } from '@mentimeter/user';

export const HAS_SENT_AI_REQUEST_LOCAL_STORAGE_KEY = 'mm-has-sent-ai-request';

export const getAiAccessRequestState = () =>
  LocalStorage.getJSONItem<boolean>(HAS_SENT_AI_REQUEST_LOCAL_STORAGE_KEY) ??
  false;

export const requestAiAccess = async ({
  context,
  feature,
}: {
  context: TrackingContext;
  feature: 'AI Menti Builder' | 'Open ended clustering' | 'Powertools';
}) => {
  if (getAiAccessRequestState()) {
    throw new Error('AI access request already sent');
  }

  const region = userCache.region;
  const userAuth = userCache.getToken();

  if (!region || !userAuth) {
    throw new MentiError(`User not authenticated to request ai access`, {
      feature: 'user-success',
    });
  }

  const response = await fetch(
    postRequestAiAccessRequest({
      region,
      userAuth,
      feature,
      context,
    }),
  );

  if (!response.ok) {
    const error = await response.text();
    throw new MentiError(`Failed to request ai access: ${error}`, {
      feature: 'user-success',
    });
  }

  LocalStorage.setJSONItem({
    key: HAS_SENT_AI_REQUEST_LOCAL_STORAGE_KEY,
    value: true,
    type: 'functional',
  });

  return postRequestAiAccessResponse(response);
};
