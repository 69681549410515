import React from 'react';
import type { Pictogram } from '../types';
import { COLOR_MAP } from '../colorMap';
import { Svg } from '../../svg';

export const OpenEnded = ({ userColor, variant = 'blue' }: Pictogram) => {
  return (
    <Svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      aria-hidden="true"
      className="light"
    >
      <path
        d="M52 100c0-26.51 21.49-48 48-48s48 21.49 48 48-21.49 48-48 48H52v-48Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
    </Svg>
  );
};
