import React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import {
  ModalContainer,
  ModalBody,
  ModalRoot,
  ModalTrigger,
  ModalDismiss,
  ModalTitle,
} from '@mentimeter/ragnar-ui/modal';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';

export function ModalLayout({
  children,
  trigger,
}: {
  children: React.ReactNode;
  trigger: React.ReactNode;
}) {
  return (
    <ModalRoot>
      <ModalTrigger>{trigger}</ModalTrigger>

      <ModalContainer size="large" pb="space8" height="90vh">
        <ModalTitle>
          <Header>Personalized builder</Header>
        </ModalTitle>

        <ModalBody>
          <Box alignItems="center" width="100%" flex="1 1 auto">
            <Text as="h2" mb="space10" color="text" fontSize="200">
              What Menti will you create today?
            </Text>

            {children}
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
}

function Header({ children }: { children: React.ReactNode }) {
  return (
    <Box width="100%" flex="none" p="space6">
      <Text>{children}</Text>
      <ModalDismiss>
        <Clickable
          aria-label="dismiss"
          position="absolute"
          top={0}
          right={0}
          p="space6"
        >
          <CrossIcon size={3} />
        </Clickable>
      </ModalDismiss>
    </Box>
  );
}
