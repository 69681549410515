import type { Question } from '@mentimeter/http-clients';

export const mapQuestionsToUpload = (
  questions: Array<Partial<Question>>,
  resultQuestions: Question[],
): { cropped_image_url: string | null | undefined; id: string }[] => {
  return resultQuestions.map((q, index) => {
    const question = questions[index];
    if (!question) {
      throw new Error(`Question at index ${index} is undefined`);
    }
    return {
      cropped_image_url: question.cropped_image_url,
      id: q.id,
    };
  });
};
