import { PlanCategory } from '@mentimeter/http-clients';
import { useRouter } from '@mentimeter/next-navigation';
import { useTranslations } from '@mentimeter/i18n';
import { useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Image } from '@mentimeter/ragnar-ui/image';
import {
  ModalContainer,
  ModalRoot,
  ModalBody,
} from '@mentimeter/ragnar-ui/modal';
import { CloseModal } from './components';
import { PlansSuccessSummary } from './PlansSuccessSummary';

export function PurchaseSuccessModal({
  planCategory,
  planName,
  isTrial,
  invoiceUrl,
  open,
}: {
  planCategory: PlanCategory;
  planName: string | undefined;
  isTrial: boolean;
  invoiceUrl: string | undefined;
  open: boolean;
}) {
  const t = useTranslations('checkoutUiPackage');
  const [hasDismissed, setHasDismissed] = useState(false);
  const translationsKey = isTrial
    ? 'trial_purchase_success_modal'
    : 'purchase_success_modal';

  const router = useRouter();
  const text =
    planCategory === PlanCategory.PRO ||
    planCategory === PlanCategory.EDUCATIONAL_PRO ||
    planCategory === PlanCategory.CONFERENCE_LARGE ||
    planCategory === PlanCategory.CONFERENCE_SMALL
      ? t(`${translationsKey}.text_pro`)
      : t('purchase_success_modal.text_basic'); // trials are always pro, so we have no basic copy for trials

  return (
    <ModalRoot
      open={open && !hasDismissed}
      onOpenChange={(isOpen) => setHasDismissed(!isOpen)}
    >
      <ModalContainer
        size="large"
        width={['100%', '100%', '880px']}
        dismissable={false}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <ModalBody width="100%" height="100%" px="space0" py="space0">
          <CloseModal
            onCloseModal={() => {
              setHasDismissed(true);
              router.replace('/app/home');
            }}
            iconSize={4}
          />
          <Box
            flexDirection={['column', 'column', 'row']}
            width="100%"
            alignItems="stretch"
          >
            <Box
              flex="1 1 auto"
              bg="#b5cce7"
              alignItems={['center', 'center', 'unset']}
              justifyContent="center"
            >
              <Box
                width="100%"
                height={['100%', '100%', '90%']}
                justifyContent="center"
                alignItems="center"
                py={['space4', 'space4', 'space0']}
              >
                <Image
                  src="https://static.mentimeter.com/static/images/using-mentimeter-confetti.svg"
                  alt="success state"
                />
              </Box>
            </Box>
            <Box
              width={['100%', '100%', '50%']}
              pt="space16"
              pb="space8"
              px={['space2', 'space2', 'space16']}
              bg="neutralWeakest"
              alignItems={['center', 'center', 'unset']}
            >
              <PlansSuccessSummary
                isTrial={isTrial}
                heading={t(`${translationsKey}.title`, { plan: planName })}
                text={text}
                invoiceUrl={invoiceUrl || '/app/billing'}
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
}
