import React from 'react';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';

export const LoaderOverlay = () => (
  <Box
    aria-live="polite"
    bg="bg"
    top={0}
    left={0}
    width="100%"
    height="100%"
    alignItems="center"
    justifyContent="center"
    data-testid="ai-loader-overlay"
  >
    <LoaderIcon size={3} />
  </Box>
);
