import { ArrowRightIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { animated } from '@react-spring/web';
import type { SpringValues } from 'react-spring';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  CharacterCount,
  CharacterCountWrap,
} from '@mentimeter/ragnar-ui/character-count';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Label } from '@mentimeter/ragnar-ui/label';
import type { TextareaItemT } from '@mentimeter/ragnar-ui/input/textarea';
import { TextareaItem } from '@mentimeter/ragnar-ui/input/textarea';
import type { TextInputItemT } from '@mentimeter/ragnar-ui/input/text-input';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';

const PromptInput = ({
  disabled,
  isLoading = false,
  buttonStyle,
  ...props
}: TextInputItemT & {
  disabled?: boolean;
  isLoading: boolean;
  buttonStyle: SpringValues;
}) => {
  return (
    <Box flexDirection="row" width="100%" alignItems="center">
      <TextInputItem
        autoFocus
        width="100%"
        type="text"
        {...props}
        as={animated.input}
        disabled={isLoading}
      />

      <Box position="absolute" right={0} pr={2} width="auto">
        <Button
          variant="primary"
          type="submit"
          as={animated.button}
          aria-label="Submit prompt"
          disabled={disabled}
          style={buttonStyle}
        >
          Submit
          <Box ml={1}>
            <ArrowRightIcon size={2} color="inherit" />
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

const PromptTextArea = ({
  disabled,
  isLoading = false,
  ...props
}: TextareaItemT & {
  disabled?: boolean;
  isLoading: boolean;
  value: string;
}) => {
  return (
    <Box flexDirection="row" width="100%" alignItems="center">
      <CharacterCountWrap width="100%" multiline>
        <TextareaItem autoFocus {...props} disabled={isLoading} />
        {props.value.length > 29500 && ( // show the counter for the last 500 characters
          <CharacterCount
            id="prompt"
            maxLength={30000}
            value={props.value}
            multiline
          />
        )}
      </CharacterCountWrap>
    </Box>
  );
};

const PromptLabel = ({ children }: React.PropsWithChildren) => {
  return (
    <Label
      htmlFor="prompt"
      fontSize={6}
      maxWidth={720}
      fontWeight="regular"
      textAlign="center"
      mb={0}
    >
      {children}
    </Label>
  );
};

const PromptSuggestions = ({
  suggestions,
  onClick,
  disabled = false,
}: {
  suggestions: string[];
  disabled?: boolean;
  onClick: (suggestion: string, position: number) => void;
}) => {
  return (
    <Box flexDirection="row" flexWrap="wrap" justifyContent="center">
      {suggestions.map((suggestion, position) => (
        <Button
          m={2}
          key={suggestion}
          size="large"
          variant="outline"
          extend={() => {
            return {
              borderWidth: '0px',
              fontWeight: 'regular',
            };
          }}
          disabled={disabled}
          onClick={() => onClick(suggestion, position)}
        >
          {suggestion}
        </Button>
      ))}
    </Box>
  );
};

export const PromptForm = ({
  children,
  onSubmit,
}: React.PropsWithChildren<{
  onSubmit: HTMLFormElement['onSubmit'];
}>) => {
  return (
    <Form onSubmit={onSubmit} alignItems="center" width="100%">
      {children}
    </Form>
  );
};

PromptForm.Label = PromptLabel;
PromptForm.Input = PromptInput;
PromptForm.TextArea = PromptTextArea;
PromptForm.Suggestions = PromptSuggestions;
