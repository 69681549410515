import { useMatch } from '@mentimeter/ragnar-device';
import React, { useCallback, useState } from 'react';
import { SparkleSolidIcon } from '@mentimeter/ragnar-visuals';
import { useUser } from '@mentimeter/user';
import {
  useCreateWithAISplit,
  type AiMentiBuilderV2SplitValues,
} from '@mentimeter/ai-menti-builder';
import { TrackingContext } from '@mentimeter/http-clients';
import {
  getAiAccess,
  getAiAccessRequestState,
  requestAiAccess,
} from '@mentimeter/ai-access';
import { useSearchParams } from '@mentimeter/next-navigation';
import { trackUser } from '@api/tracking/client';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverBody,
  PopoverContainer,
  PopoverFooter,
  PopoverHeader,
} from '@mentimeter/ragnar-ui/popover';
import { Badge, BadgeWrap } from '@mentimeter/ragnar-ui/badge';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ModalRoot } from '@mentimeter/ragnar-ui/modal';
import { Dark } from '@mentimeter/ragnar-ui/themes';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { CreateButtonUI, trackCreatePresentation } from './CreateButton';

function useStartWithAiQueryParams() {
  const searchParams = useSearchParams();

  if (!searchParams) {
    return { shouldOpenModal: false, prefillPrompt: undefined };
  }

  let shouldOpenModal = searchParams.get('start-with-ai-open-modal') ?? false;
  const prefillPrompt = searchParams.get('start-with-ai-prompt') ?? undefined;

  // Modal should open if the query param is set to true OR if the user has a prefill prompt
  shouldOpenModal =
    searchParams?.get('start-with-ai-open-modal') === 'true' ||
    prefillPrompt !== undefined;

  return { shouldOpenModal, prefillPrompt };
}

const CreateWithAiDataHandler = ({
  children,
}: {
  children: ({
    aiEnabled,
    authorizedToChangeAiSetting,
    canAutoOptIn,
    optInToAi,
    version,
  }: {
    aiEnabled: boolean;
    authorizedToChangeAiSetting: boolean;
    canAutoOptIn: boolean;
    optInToAi: () => Promise<void>;
    version: AiMentiBuilderV2SplitValues;
    prefillPrompt: string | undefined;
    createWithAIModalOpen: boolean;
    setCreateWithAIModalOpen: (open: boolean) => void;
  }) => React.ReactNode;
}) => {
  const { user, update } = useUser();
  const { shouldOpenModal, prefillPrompt } = useStartWithAiQueryParams();

  const isMobile = useMatch({ lessThan: 3 });
  const { isReady, USE_AI_Menti_Builder_v2 } = useCreateWithAISplit(isMobile);
  const {
    authorizedToChangeAiSetting,
    canAutoOptIn,
    aiEnabled,
    aiDomainEnabled,
  } = getAiAccess(user);

  const autoOpenModal = shouldOpenModal && (aiEnabled || canAutoOptIn);
  const [createWithAIModalOpen, setCreateWithAIModalOpen] = useState(
    !!autoOpenModal,
  );

  const optInToAi = useCallback(async () => {
    if (!update) return;
    trackUser({
      event: 'Auto opted in to AI',
      properties: {
        context: TrackingContext.UserHome,
        feature: 'AI Menti Builder',
      },
    });
    await update(
      {
        aiFeaturesEnabled: true,
      },
      'Home',
    );
  }, [update]);

  if (!isReady || !aiDomainEnabled) return null;

  if (['v1', 'v2', 'v3'].includes(USE_AI_Menti_Builder_v2)) {
    return children({
      aiEnabled,
      authorizedToChangeAiSetting,
      canAutoOptIn,
      optInToAi,
      version: USE_AI_Menti_Builder_v2,
      prefillPrompt,
      createWithAIModalOpen,
      setCreateWithAIModalOpen,
    });
  }

  return null;
};

const ChangeWorkspaceAISetting = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <PopoverHeader onDismissClick={onClose}>
        Enable AI tools in your workspace
      </PopoverHeader>
      <PopoverBody>
        <Text>
          In order to create with AI you need to enable AI tools in your
          workspace settings
        </Text>
      </PopoverBody>
      <PopoverFooter>
        <Button variant="primary" href="/app/workspace-settings">
          Change settings
        </Button>
      </PopoverFooter>
    </>
  );
};

const RequestAIToolsPopover = ({ onClose }: { onClose: () => void }) => {
  const [hasSentRequest, setHasSentRequest] = React.useState(
    getAiAccessRequestState(),
  );

  const handleRequestAiAccess = async () => {
    setHasSentRequest(true);
    try {
      await requestAiAccess({
        context: TrackingContext.UserHome,
        feature: 'AI Menti Builder',
      });
    } catch (e) {
      // TODO: Sent error to Sentry?
      // NOOP: We don't want to show an error to the user
    }
  };

  return (
    <>
      <PopoverHeader onDismissClick={onClose}>Request AI Tools</PopoverHeader>
      <PopoverBody>
        <Text>
          In order to create with AI your workspace owner need to enable AI
          tools in the workspace settings
        </Text>
      </PopoverBody>
      <PopoverFooter>
        {hasSentRequest ? (
          <Button variant="primary" disabled>
            Request sent
          </Button>
        ) : (
          <Button variant="primary" onClick={handleRequestAiAccess}>
            Request AI tools
          </Button>
        )}
      </PopoverFooter>
    </>
  );
};

const CreateWithAIButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <CreateButtonUI
      data-testid="start-with-ai-button"
      variant="outline"
      iconLeading={SparkleSolidIcon}
      onClick={() => {
        onClick();
        trackCreatePresentation('Start with AI');
      }}
    >
      Start with AI
    </CreateButtonUI>
  );
};

const CreateWithAiButtonWithAuthoriseAIPopOver = ({
  authorizedToChangeAiSetting,
}: {
  authorizedToChangeAiSetting: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const renderPopoverContent = () => {
    return (
      <PopoverContainer width={300} align="end">
        <Dark>
          <Box bg="surface">
            {authorizedToChangeAiSetting ? (
              <ChangeWorkspaceAISetting onClose={() => setIsOpen(false)} />
            ) : (
              <RequestAIToolsPopover onClose={() => setIsOpen(false)} />
            )}
          </Box>
        </Dark>
      </PopoverContainer>
    );
  };

  return (
    <PopoverRoot open={isOpen}>
      <BadgeWrap badge={<Badge type="beta" compact />}>
        <PopoverTrigger asChild>
          <CreateButtonUI
            data-testid="start-with-ai-button"
            variant="outline"
            iconLeading={SparkleSolidIcon}
            onClick={handleButtonClick}
          >
            Start with AI
          </CreateButtonUI>
        </PopoverTrigger>
      </BadgeWrap>
      {renderPopoverContent()}
    </PopoverRoot>
  );
};

export const CreateWithAIFeatureWrapper = ({
  children,
}: {
  children: (args: {
    handleCancel: () => void;
    version: AiMentiBuilderV2SplitValues;
    prefillPrompt: string | undefined;
  }) => React.ReactNode;
}) => {
  return (
    <CreateWithAiDataHandler>
      {({
        authorizedToChangeAiSetting,
        canAutoOptIn,
        aiEnabled,
        optInToAi,
        version,
        prefillPrompt,
        createWithAIModalOpen,
        setCreateWithAIModalOpen,
      }) => (
        <>
          {!aiEnabled && !canAutoOptIn ? (
            <CreateWithAiButtonWithAuthoriseAIPopOver
              authorizedToChangeAiSetting={authorizedToChangeAiSetting}
            />
          ) : (
            <CreateWithAIButton
              onClick={() => {
                if (!aiEnabled) optInToAi();
                setCreateWithAIModalOpen(true);
              }}
            />
          )}

          <ModalRoot
            open={createWithAIModalOpen}
            onOpenChange={(open: boolean) => {
              setCreateWithAIModalOpen(open);
            }}
          >
            {createWithAIModalOpen &&
              children({
                version,
                prefillPrompt,
                handleCancel: () => setCreateWithAIModalOpen(false),
              })}
          </ModalRoot>
        </>
      )}
    </CreateWithAiDataHandler>
  );
};
