import type { JSX } from 'react';
import type { GuideSlugT } from './guides';
import { HowParticipantsJoin } from './content/how-participants-join';
import { WhatIsAMenti } from './content/what-is-a-menti';
import { CreatingYourFirstMenti } from './content/creating-your-first-menti';
import { HowToPresent } from './content/how-to-present';
import { UsingMentimeterWithOtherTools } from './content/using-mentimeter-with-other-tools';

export const CONTENT_BY_SLUG: Record<GuideSlugT, () => JSX.Element> = {
  WhatIsAMenti,
  CreatingYourFirstMenti,
  HowToPresent,
  HowParticipantsJoin,
  UsingMentimeterWithOtherTools,
};
