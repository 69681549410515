const DEPRECATED_MIME_TYPES = ['application/x-iwork-keynote-sffkey'];

export function getFileWithType(file: File): File {
  // If the file has a deprecated MIME type, we want to try to get the MIME type from the file extension
  if (file.type.length > 0 && !DEPRECATED_MIME_TYPES.includes(file.type)) {
    return file;
  }
  const fileType = getFileMIMEType(file.name);
  if (!fileType) {
    return file;
  }
  return new File([file], file.name, { type: fileType });
}

function getFileMIMEType(fileName: string): string | undefined {
  const extension = getFileExtension(fileName);
  switch (extension) {
    case 'key':
      return 'application/vnd.apple.keynote';
    case 'pdf':
      return 'application/pdf';
    case 'ppt':
      return 'application/vnd.ms-powerpoint';
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case 'png':
      return 'image/png';
    case 'jpeg':
      return 'image/jpeg';
    case 'jpg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'svg':
      return 'image/svg+xml';
    default:
      return undefined;
  }
}

const getFileExtension = (fileName: string): string | undefined =>
  fileName.split('.').pop() || undefined;
