import { colors } from '@mentimeter/ragnar-colors';

export interface ThemeEffectArgs {
  backgroundColor: string;
  textColor: string;
  highlightOne: string;
  highlightTwo: string;
}

export interface ThemeDefinition {
  id: number;
  name: string;
  displayName: string;
  colors: ThemeEffectArgs;
  publicThemeName?: string | undefined;
}

export type ThemeList = ThemeDefinition[];

export const DEFAULT_THEMES: Array<ThemeDefinition> = [
  {
    id: 1,
    name: 'Menti Light',
    publicThemeName: 'Menti light',
    displayName: 'Light',
    colors: {
      textColor: colors.text,
      backgroundColor: colors.bg,
      highlightOne: '#346AF6',
      highlightTwo: '#EF87AA',
    },
  },
  {
    id: 2,
    name: 'Menti Dark',
    publicThemeName: 'Menti dark',
    displayName: 'Dark',
    colors: {
      textColor: colors.bg,
      backgroundColor: colors.text,
      highlightOne: '#346AF6',
      highlightTwo: '#EF87AA',
    },
  },
  {
    id: 3,
    name: 'True blue',
    publicThemeName: 'True blue',
    displayName: 'True blue',
    colors: {
      textColor: 'white',
      backgroundColor: '#346AF6',
      highlightOne: '#EF87AA',
      highlightTwo: '#FBE98A',
    },
  },
  {
    id: 4,
    name: 'Perfect pink',
    publicThemeName: 'Perfect pink',
    displayName: 'Perfect pink',
    colors: {
      textColor: colors.text,
      backgroundColor: '#FF80AB',
      highlightOne: '#D1D4FF',
      highlightTwo: '#6E54BD',
    },
  },
  {
    id: 5,
    name: 'Mellow yellow',
    publicThemeName: 'Mellow yellow',
    displayName: 'Mellow yellow',
    colors: {
      textColor: colors.text,
      backgroundColor: '#FFCC00',
      highlightOne: '#6E54BD',
      highlightTwo: '#FF80AB',
    },
  },
];

export const DEFAULT_THEMES_NAME_LIST: string[] = DEFAULT_THEMES.map(
  ({ publicThemeName }) => publicThemeName?.toLowerCase() as string,
);
