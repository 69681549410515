import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { SSEContext } from './sse-provider';
import { isUrl } from './utils';

interface SSEErrorEvent extends Event {
  data: string;
}

interface UseSSEHook {
  listening: boolean;
  stop: () => void;
  start: () => void;
}

/**
 * A generic SSE hook that can connect to any valid event stream.
 *
 * For example, to connect to an Ably channel use the following sourceUrl, replace with your own Channel and Key
 * https://realtime.ably.io/event-stream?channels=MY_CHANNEL&v=1.2&key=MY_KEY
 *
 * Event source documenttation from MDN
 * https://developer.mozilla.org/en-US/docs/Web/API/EventSource#events
 *
 * @param sourceUrl The URL that will be used to send the server-side events.
 * @param onMessageCallback  Function that will run when a new MessageEvent is received. The MessageEvent can be used by the callback.
 * @param connectOnInit Define if the connection should be established upon initialization. Defaults to true
 * @param onErrorCallback Function that will run when an error event is received. The error event can be used by the callback.
 * @param eventName The name of the event to listen to. For most cases, this will be the generic 'message' event.
 */
export const useSSE = (
  sourceUrl: string,
  onMessageCallback: (e: MessageEvent) => any,
  { connectOnInit = true },
  onErrorCallback: (e: SSEErrorEvent) => any = () => {},
  eventName: 'message' | 'error' = 'message',
): UseSSEHook => {
  const listeningRef = useRef(false);
  const listening = listeningRef.current;
  const setListening = (value: boolean) => {
    listeningRef.current = value;
  };

  const ctx = useContext(SSEContext);

  const isValidUrl = useCallback(() => {
    if (!isUrl(sourceUrl)) {
      captureException(
        new MentiError('[useSSE]: the sourceUrl is invalid', {
          feature: 'server-sent-events',
          cause: { sourceUrl },
        }),
      );
      return false;
    }
    return true;
  }, [sourceUrl]);

  if (!ctx) {
    const mentiError = new MentiError(
      '[useSSE]: this hook must be used within a SSEProvider',
      {
        feature: 'server-sent-events',
        cause: { sourceUrl },
      },
    );

    captureException(mentiError);
  }

  const stop = useCallback(() => {
    if (!ctx || !ctx.currentConnections.has(sourceUrl)) {
      return;
    }

    const currentConnection = ctx.getConnection(sourceUrl);
    if (currentConnection) {
      currentConnection.removeEventListener(eventName, onMessageCallback);
      ctx.removeConnection(sourceUrl);
    }
    setListening(false);
  }, [ctx, sourceUrl, eventName, onMessageCallback]);

  const onError = useCallback(
    (e: SSEErrorEvent) => {
      if (!ctx || !ctx.currentConnections.has(sourceUrl)) {
        return;
      }

      if (onErrorCallback) onErrorCallback(e);
      stop();
    },
    [ctx, onErrorCallback, sourceUrl, stop],
  );

  const start = useCallback(() => {
    if (!isValidUrl()) return;
    if (!ctx) return;

    const currentConnection = ctx.createConnection(sourceUrl);
    if (!currentConnection) {
      captureException(
        new MentiError(
          `[useSSE]: Can not start listening to non-existent connection`,
          {
            feature: 'server-sent-events',
            cause: { sourceUrl },
          },
        ),
      );

      return;
    }

    currentConnection.addEventListener(eventName, onMessageCallback);
    currentConnection.addEventListener('error', onError);
    setListening(true);
  }, [isValidUrl, ctx, sourceUrl, eventName, onMessageCallback, onError]);

  useEffect(() => {
    if (!connectOnInit) return;
    if (!isValidUrl()) return;
    if (!ctx) return;

    const currentConnection = ctx.createConnection(sourceUrl);

    if (!currentConnection) {
      captureException(
        new MentiError(
          `[useSSE]: Can not start listening to non-existent connection`,
          {
            feature: 'server-sent-events',
            cause: { sourceUrl },
          },
        ),
      );
      return;
    }

    currentConnection.addEventListener(eventName, onMessageCallback);
    currentConnection.addEventListener('error', onError);

    setListening(true);

    return () => {
      if (!ctx.currentConnections.has(sourceUrl)) {
        return;
      }

      currentConnection.removeEventListener('error', onError);
      stop();
    };
  }, [
    sourceUrl,
    onMessageCallback,
    ctx,
    eventName,
    isValidUrl,
    connectOnInit,
    onError,
    stop,
  ]);

  return { listening, stop, start };
};
