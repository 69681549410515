import React from 'react';
import type { z } from 'zod';
import { useAiApiStreamer } from './api-streamer';
import type { AiApiStreamerError } from './errors';

interface ChildProps<K> {
  data: K;
  listening: boolean;
  startListening: () => void;
  stopListening: () => void;
}

interface StreamDataHandlerProps<K> {
  schema: z.Schema;
  ablyStreamUrl: string;
  children: (props: ChildProps<K | undefined>) => React.ReactNode;
  startTimeout?: boolean | undefined;
  timeoutInMs?: number | undefined;
  onError?: ((error: AiApiStreamerError) => void) | undefined;
  onEvent?: (data: K, listening: boolean) => void;
}

export const StreamDataHandler = <K>({
  schema,
  ablyStreamUrl,
  children,
  startTimeout,
  timeoutInMs,
  onError,
  onEvent,
}: StreamDataHandlerProps<K>) => {
  const {
    data,
    listening,
    actions: { stopListening, startListening },
  } = useAiApiStreamer<K>(ablyStreamUrl, schema, {
    connectOnInit: true,
    startTimeout,
    timeoutInMs,
    onError,
  });

  React.useEffect(() => {
    if (data && onEvent) {
      onEvent(data, listening);
    }
  }, [data, listening, onEvent]);

  return children({
    data,
    listening,
    startListening,
    stopListening,
  });
};
