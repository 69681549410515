import z from 'zod';

export const analyzeEventBodySchemaV3 = z.object({
  tool: z.string().optional(),
  has_attachment: z.boolean().optional(),
  has_structure_instructions: z.boolean().optional(),
  enhanced_prompt: z.string().optional(),
  question: z.string().nullable().optional(),
  is_invalid_input: z.boolean().optional(),
  image_keyword: z.string().optional(),
  user_input: z
    .array(
      z.object({
        question: z.string(),
        answer: z.string(),
      }),
    )
    .optional(),
});

export interface AnalyzeEventBodySchemaV3 {
  tool: string;
  hasAttachment: boolean;
  hasStructureInstructions: boolean;
  enhancedPrompt: string;
  question?: string;
  isInvalidInput: boolean;
  imageKeyword: string;
  userInput: { question: string; answer: string }[];
}
