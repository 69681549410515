/* eslint-disable menti-react/filename-convention--jsx */
import { useState, useCallback } from 'react';
import { TextInput, Form } from '@mentimeter/ragnar-form';
import useSWR, { mutate } from 'swr';
import { core, TrackingContext } from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import type { TemplateResponse } from '@mentimeter/http-clients';
import { NAME_MAX_CHAR } from 'features/dashboard/presentation-view/constants';
import { useToast } from '@mentimeter/toast';
import { trackUser } from '@api/tracking/client';
import { useGroups } from 'lib/useGroups';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Check } from '@mentimeter/ragnar-ui/check';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import {
  ModalRoot,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@mentimeter/ragnar-ui/modal';
import { Button } from '@mentimeter/ragnar-ui/button';

export interface ShareWithGroupsModalProps {
  id: string;
  name: string;
  onShare: () => void;
  onUnshare: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ModalContentShareInner = ({
  id,
  name,
  description,
  sharedWithGroups,
  sharedWithOrganization,
  onShare,
  onUnshare,
  open,
  setOpen,
}: ShareWithGroupsModalProps & {
  description: TemplateResponse['description'];
  sharedWithGroups: TemplateResponse['shared_with_groups'];
  sharedWithOrganization: TemplateResponse['shared_with_organization'];
}) => {
  const toast = useToast();
  const { user } = useUser();

  const { groups, isLoading: groupsIsLoading } = useGroups();

  const [groupsToShare, setGroupsToShare] =
    useState<string[]>(sharedWithGroups);
  const [shareWithWorkspace, setShareWithWorkspace] = useState(
    sharedWithOrganization || false,
  );

  // This state is being used for tracking
  const [newShares, setNewShares] = useState<string[]>([]);
  const [newUnshares, setNewUnshares] = useState<string[]>([]);

  const trackShareTemplateWithWorkspace = useCallback(
    // @ts-expect-error-auto TS(7006) FIXME: Parameter 'templateId' implicitly has an 'any' typ... Remove this comment to see the full error message
    (templateId, templateName) => {
      trackUser({
        event: 'Shared template',
        properties: {
          Template: templateName,
          'Template ID': templateId,
          'to group id': null,
          'share with workspace': true,
          context: TrackingContext.MyPresentations,
        },
      });
    },
    [],
  );

  const trackUnshareTemplateWithWorkspace = useCallback(
    // @ts-expect-error-auto TS(7006) FIXME: Parameter 'templateId' implicitly has an 'any' typ... Remove this comment to see the full error message
    (templateId, templateName) => {
      trackUser({
        event: 'Unshared template',
        properties: {
          Template: templateName,
          'Template ID': templateId,
          'from group id': null,
          'Removed by': 'owner',
          'share with workspace': false,
          context: TrackingContext.MyPresentations,
        },
      });
    },
    [],
  );

  const trackShareTemplateWithGroup = useCallback(
    // @ts-expect-error-auto TS(7006) FIXME: Parameter 'templateId' implicitly has an 'any' typ... Remove this comment to see the full error message
    (templateId, templateName, groupId) => {
      trackUser({
        event: 'Shared template',
        properties: {
          Template: templateName,
          'Template ID': templateId,
          'to group id': groupId,
          'share with workspace': false,
          context: TrackingContext.MyPresentations,
        },
      });
    },
    [],
  );

  const trackUnshareTemplateFromGroup = useCallback(
    // @ts-expect-error-auto TS(7006) FIXME: Parameter 'templateId' implicitly has an 'any' typ... Remove this comment to see the full error message
    (templateId, templateName, groupId) => {
      trackUser({
        event: 'Unshared template',
        properties: {
          'Template ID': templateId,
          Template: templateName,
          'share with workspace': false,
          'Removed by': 'owner',
          'from group id': groupId,
          context: TrackingContext.MyPresentations,
        },
      });
    },
    [],
  );

  // @ts-expect-error-auto TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleChange = (e, groupId) => {
    const { checked } = e.target;
    if (checked) setNewShares([...newShares, groupId]);
    else setNewUnshares([...newUnshares, groupId]);
    setGroupsToShare(
      checked
        ? [...groupsToShare, groupId]
        : groupsToShare.filter((id) => id !== groupId),
    );
  };

  const handleShare = async (newName: string) => {
    try {
      setOpen(false);

      const sharedWithGroups = shareWithWorkspace
        ? []
        : groupsToShare.map((id) => ({
            id,
          }));
      const templateResponse = await core().series.template.put(id, {
        description: newName,
        shared_with_organization: shareWithWorkspace,
        shared_with_groups: sharedWithGroups,
      });

      const templateId = templateResponse.data.id;

      if (sharedWithOrganization !== shareWithWorkspace)
        if (shareWithWorkspace) {
          trackShareTemplateWithWorkspace(templateId, newName);
        } else {
          trackUnshareTemplateWithWorkspace(templateId, newName);
        }

      if (newShares.length > 0)
        newShares.forEach((groupId) => {
          trackShareTemplateWithGroup(templateId, newName, groupId);
        });
      if (newUnshares.length > 0)
        newUnshares.forEach((groupId) => {
          trackUnshareTemplateFromGroup(templateId, newName, groupId);
        });
      setNewShares([]);
      setNewUnshares([]);

      await mutate(['template-details', id]);

      if (shareWithWorkspace || groupsToShare.length > 0) {
        const shareDetails = groups
          ? shareWithWorkspace
            ? ' with all members and admins'
            : ` with ${
                groupsToShare.length > 1
                  ? `${groupsToShare.length} groups`
                  : 'one group'
              }`
          : '';

        toast.displayToast({
          description: `Shared "${newName}"${shareDetails}`,
          autoDismiss: true,
        });

        onShare();
      } else {
        toast.displayToast({
          description: `Unshared "${newName}"`,
          autoDismiss: true,
        });

        onUnshare();
      }
    } catch (_) {
      toast.displayToast({
        description: `Could not share "${newName}"`,
        autoDismiss: true,
        variant: 'negative',
      });
    }
  };

  const showAdminHint = Boolean(
    user?.ownerOrAdmin && !groupsIsLoading && !groups.length,
  );

  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer>
        <Form
          // @ts-expect-error-auto TS(2769) FIXME: No overload matches this call.
          validate={(values) => {
            const errors: { [key: string]: unknown } = {};
            if (values.newName.length <= 0) {
              errors.newName = 'Enter a descriptive name';
            }
            return errors;
          }}
          validateOnBlur={false}
          initialValues={{ newName: description ?? name }}
          onSubmit={({ newName }) => handleShare(newName)}
        >
          <ModalHeader>Create a shared template</ModalHeader>
          <ModalBody>
            <Text>
              Turn &quot;{name}&quot; into a template and share it with your
              workspace. Results will not be shared.
            </Text>
            <TextInput
              width="100%"
              autoFocus
              autoComplete="off"
              maxLength={NAME_MAX_CHAR}
              label="Template name"
              hintText="Give the template a descriptive name so that others know what it can be used for."
              name="newName"
              placeholder="Name"
              mt={3}
            />

            {groups && (
              <>
                <Heading fontSize={2} mt={3} mb={2}>
                  <Box flex="1 1 auto">Share with...</Box>
                </Heading>

                {showAdminHint && (
                  <CalloutCard
                    mt={3}
                    description="Join a group to share a template with specific people in your workspace."
                    actions={[
                      {
                        key: 'go-to-page',
                        href: '/app/groups',
                        children: 'Manage groups',
                        onClick: () => {
                          trackUser({
                            event: 'Clicked group admin hint',
                          });
                        },
                      },
                    ]}
                  />
                )}

                <Box mt={2}>
                  <Check
                    key={id}
                    id="share-with-workspace"
                    name="share-with-workspace"
                    label="All workspace members"
                    checked={shareWithWorkspace}
                    onChange={(e) => setShareWithWorkspace(e.target.checked)}
                  />

                  {groups.map(({ id, name }) => (
                    <Check
                      disabled={shareWithWorkspace}
                      name={id}
                      id={id}
                      key={id}
                      label={name}
                      checked={
                        !shareWithWorkspace && groupsToShare.includes(id)
                      }
                      onChange={(e) => handleChange(e, id)}
                    />
                  ))}
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setOpen(false)} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </ModalContainer>
    </ModalRoot>
  );
};

export const ModalContentShareWithGroups = ({
  id,
  name,
  open,
  setOpen,
  onShare,
  onUnshare,
}: ShareWithGroupsModalProps) => {
  const { data: templateResponse, error } = useSWR<
    TemplateResponse,
    any,
    [string, string]
  >(['template-details', id], async ([_, id]) => {
    const { data } = await core().series.template.get(id);
    return data;
  });

  // If no template exists, set default values
  const template = error
    ? {
        description: name,
        shared_with_organization: false,
        shared_with_groups: [],
      }
    : templateResponse;

  // Only the overlay will show until we have loaded all data necessary to display the modal
  if (!template) return null;

  return (
    <ModalContentShareInner
      id={id}
      name={name}
      open={open}
      setOpen={setOpen}
      description={template.description}
      sharedWithGroups={template.shared_with_groups}
      sharedWithOrganization={template.shared_with_organization}
      onShare={onShare}
      onUnshare={onUnshare}
    />
  );
};
