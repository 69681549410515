import {
  getRecentRequest,
  getRecentResponse,
} from '@core-api/presentation-collection/presentation-collection/v2/recent';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { userCache } from '@mentimeter/user';
import useSWR from 'swr';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import type { RecentPresentationsInitialData } from './types';

export function useRecentPresentations({
  initialData,
}: { initialData?: RecentPresentationsInitialData } = {}) {
  const { data: series, error } = useSWR<SlideDeckOverview[]>(
    'recent-presentations',
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      const response = await fetch(
        getRecentRequest({ region, userAuth: sessionToken }),
      );
      if (!response.ok && response.status !== 401 && response.status !== 404) {
        const e = new MentiError('Error fetching recent presentations', {
          feature: 'recent-presentations',
        });
        captureException(e);
        throw e;
      }

      const data = await getRecentResponse(response);

      return data.series;
    },
    {
      revalidateOnFocus: false,
      fallbackData: initialData || [],
      revalidateOnMount: !initialData,
    },
  );

  if (error) {
    return {
      loading: false,
      error,
    };
  }

  if (!series) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    data: series,
  };
}
