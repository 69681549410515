import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import { useRef } from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalRoot,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@mentimeter/ragnar-ui/modal';
import { Box } from '@mentimeter/ragnar-ui/box';
import { getGuideContent } from './guides/getGuideContent';
import type { GuideT } from './useGuide';

export function GuideModal({
  guide,
  open,
  setOpen,
  nextGuide,
  isLastGuide,
}: {
  guide: GuideT;
  open: boolean;
  setOpen: (open: boolean) => void;
  nextGuide(): void;
  isLastGuide: boolean;
}) {
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const Content = getGuideContent(guide.slug);

  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer>
        <ModalHeader>{guide.title}</ModalHeader>
        <ModalBody ref={bodyRef}>
          <Content />
        </ModalBody>
        <ModalFooter
          alignItems="center"
          justifyContent="space-around"
          flexDirection="row"
        >
          <Fader />
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="subtle"
            mr={2}
          >
            Close
          </Button>
          {isLastGuide ? (
            <Button variant="subtle" disabled={isLastGuide}>
              Last guide
            </Button>
          ) : (
            <Button
              onClick={() => {
                bodyRef.current?.scrollTo(0, 0);
                nextGuide();
              }}
              variant="primary"
            >
              Next guide
              <Box ml={1} top="1px">
                <ChevronRightIcon size={2} color="currentColor" />
              </Box>
            </Button>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
}

function Fader() {
  return (
    <Box
      width="100%"
      height="36px"
      position="absolute"
      bottom="99%"
      left="0"
      zIndex={1}
      extend={({ theme }) => ({
        background: `linear-gradient(0deg, ${theme.colors.bg}, ${theme.colors.bg}, transparent)`,
      })}
    />
  );
}
