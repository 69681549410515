export interface GuideMetaDataT {
  title: string;
  estimatedReadingTimeMinutes: number;
  type: 'video' | 'text';
}

export const GUIDES_BY_NAME = {
  WhatIsAMenti: {
    title: 'What is a Menti?',
    estimatedReadingTimeMinutes: 1,
    type: 'text',
  },
  CreatingYourFirstMenti: {
    title: 'Creating your first Menti',
    estimatedReadingTimeMinutes: 7,
    type: 'video',
  },
  HowToPresent: {
    title: 'How to present',
    estimatedReadingTimeMinutes: 1,
    type: 'text',
  },
  HowParticipantsJoin: {
    title: 'How participants join',
    estimatedReadingTimeMinutes: 2,
    type: 'text',
  },
  UsingMentimeterWithOtherTools: {
    title: 'Using Mentimeter with other tools',
    estimatedReadingTimeMinutes: 4,
    type: 'video',
  },
} as const satisfies Record<string, GuideMetaDataT>;

export type GuideSlugT = keyof typeof GUIDES_BY_NAME;

export interface GuideDataT extends GuideMetaDataT {
  slug: GuideSlugT;
}

const GUIDE_ORDER: GuideSlugT[] = [
  'WhatIsAMenti',
  'CreatingYourFirstMenti',
  'HowToPresent',
  'HowParticipantsJoin',
  'UsingMentimeterWithOtherTools',
];

const keys = Object.keys(GUIDES_BY_NAME) as GuideSlugT[];

export const GUIDES: GuideDataT[] = keys
  .map((slug) => ({
    ...GUIDES_BY_NAME[slug],
    slug,
  }))
  .sort((a, b) => GUIDE_ORDER.indexOf(a.slug) - GUIDE_ORDER.indexOf(b.slug));
