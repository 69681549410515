// This file is automatically generated. DO NOT EDIT.

import { type Series } from '../../types/response/Series';
import { type SlideDeck } from '../../types/response/SlideDeck';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface PostSeriesResponse {
  /** The series, will be null if series is migrated to a slide deck */
  series: Series | null;
  /** The slide deck of the series, null if not yet migrated */
  slideDeck: SlideDeck | null;
}

export async function postSeriesResponse(
  response: Response,
  options?: GetJSONResponseOptions<PostSeriesResponse>,
): Promise<PostSeriesResponse> {
  return getJSONResponse<PostSeriesResponse>(
    response,
    'postSeriesResponse',
    options,
  );
}
