import type React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { CloseModal } from './components';

interface InvoiceModalProps {
  title: string;
  isOpen: boolean;
  children: React.JSX.Element;
  onOpenChange?: (isOpen: boolean) => void;
  subtitle?: string;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

export const InvoiceModal = ({
  title,
  isOpen,
  children,
  onOpenChange,
  subtitle,
  secondaryButtonText,
  primaryButtonText,
  onPrimaryClick,
  onSecondaryClick,
}: InvoiceModalProps) => {
  return (
    <ModalRoot
      open={isOpen}
      onOpenChange={onOpenChange ? onOpenChange : () => {}}
    >
      <ModalContainer size="small" onOpenAutoFocus={(e) => e.preventDefault()}>
        <ModalBody width="100%" p="space10">
          {onOpenChange && (
            <CloseModal onCloseModal={() => onOpenChange(false)} iconSize={2} />
          )}
          <Text mb="space8" mt="space3" variant="dashboardHeadline4">
            {title}
          </Text>
          {subtitle && (
            <Text mb="space8" variant="ragnarBodyMd">
              {subtitle}
            </Text>
          )}
          {children}
          <Box mt="space10" flexDirection="row-reverse" width="100%">
            <Box flexDirection="row">
              {secondaryButtonText && onSecondaryClick && (
                <Button variant="secondary" onClick={onSecondaryClick}>
                  {secondaryButtonText}
                </Button>
              )}
              {primaryButtonText && onPrimaryClick && (
                <Button variant="primary" onClick={onPrimaryClick}>
                  {primaryButtonText}
                </Button>
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
};
