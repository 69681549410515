// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostGroupRequest {
  region: 'eu' | 'us';
  userAuth: string;
  data: Array<string>;
  context?: {
    heading: string;
    subheading?: string;
  };
  ablyChannelId: string;
  requestId?: string;
}

export function postGroupRequest(requestParams: PostGroupRequest): Request {
  const data = {
    data: toSnakeKeys(requestParams.data),
    context: toSnakeKeys(requestParams.context),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/group`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
