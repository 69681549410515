import React, { useCallback, useState } from 'react';
import {
  createSeriesEventBodySchema,
  createSeriesRequest,
  StreamDataHandler,
  postGenerationMetaRequest,
} from '@mentimeter/ai-api-streamer';
import type {
  AiApiStreamerError,
  IntentSchema,
  CreateSeriesEventBodySchema,
} from '@mentimeter/ai-api-streamer';
import { TrackingContext } from '@mentimeter/http-clients';
import { useOnce } from '@mentimeter/react-hooks';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { Box } from '@mentimeter/ragnar-ui/box';
import { LoaderOverlay } from '../../components/loader/LoaderOverlay';
import { Animations } from '../../components/animations';
import { ErrorScreen } from '../../components/error-screen/ErrorScreen';
import {
  trackUserEvent,
  aiStreamerErrorBreadcrumb,
  buildStepCreateSeriesRequestBreadcrumb,
  buildStepCreateSeriesResponseBreadcrumb,
} from '../../tracking';
import {
  hasMissingEngagementOrTopic,
  makeImageKeywordString,
  sanitizeIntentPropertiesForMixpanel,
} from '../../utils/intent-properties';
import { SeriesBuilder } from './series-builder/SeriesBuilder';

const TIMEOUT_DURATION_IN_MS = 60_000;

interface BuildStepProps {
  intent: IntentSchema | undefined;
  ablyChannelId: string | undefined;
  ablyStreamUrl: string | undefined;
  shouldAnimate: boolean;
  onRetry: () => void;
  handleSeriesCreationDone: (
    seriesId: string,
    questionId: string,
  ) => Promise<void>;
}

export const BuildStep = ({
  intent,
  ablyChannelId,
  ablyStreamUrl,
  shouldAnimate,
  handleSeriesCreationDone,
  onRetry,
}: BuildStepProps) => {
  const [requestState, setRequestState] = useState<
    'loading' | 'error' | 'idle'
  >('idle');

  const handleAiApiAsyncSeriesCreationRequest = async () => {
    try {
      if (!ablyChannelId) {
        setRequestState('error');
        throw new Error('ablyChannelId is not defined');
      }

      setRequestState('loading');

      buildStepCreateSeriesRequestBreadcrumb({ ablyChannelId });

      await createSeriesRequest({
        intent: intent!,
        ablyChannelId,
      });
    } catch (error) {
      const errorMessage = '[AI Menti Builder] Failed to create series request';

      aiStreamerErrorBreadcrumb(errorMessage, { ablyChannelId });

      captureException(
        new MentiError(errorMessage, {
          feature: 'ai-menti-builder',
          cause: { error, ablyChannelId },
        }),
      );

      setRequestState('error');
    }
  };

  const handleError = useCallback(
    (aiApiStreamerError: AiApiStreamerError) => {
      const errorMessage =
        '[AI Menti Builder] Error while listening for events';

      aiStreamerErrorBreadcrumb(errorMessage, { ablyChannelId });

      captureException(
        new MentiError(errorMessage, {
          feature: 'ai-menti-builder',
          cause: aiApiStreamerError,
        }),
      );
      setRequestState('error');
    },
    [ablyChannelId],
  );

  const handleCoreApiSeriesCreation =
    (
      data: CreateSeriesEventBodySchema | undefined,
      stopListening: () => void,
    ) =>
    (seriesId: string, questionId: string, generationLoadingTime: number) => {
      if (intent) {
        trackUserEvent({
          event: 'Successfully finished AI Menti Builder',
          properties: {
            context: TrackingContext.UserHome,
            series_id: seriesId,
            generation_time_in_ms: generationLoadingTime,
            engagement_type: intent.engagementType ?? '',
            topic: intent.topic ?? '',
            language: intent.language ?? '',
            image_keyword: makeImageKeywordString(intent.imageKeywords),
            missing_keys: hasMissingEngagementOrTopic(intent),
            intent_object: sanitizeIntentPropertiesForMixpanel(intent),
          },
        });

        buildStepCreateSeriesResponseBreadcrumb({ ablyChannelId });

        postGenerationMetaRequest({
          data: {
            prompt_step: 'build',
            intent,
            createSeriesResponse: data,
          },
          aiSessionId: ablyChannelId ?? '',
          seriesId,
          eventName: 'menti_builder/create_series',
        });
      }

      handleSeriesCreationDone(seriesId, questionId);
      stopListening();
    };

  useOnce(requestState === 'error', () => {
    trackUserEvent({
      event: 'Viewed error message AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
        error_step: 'build',
      },
    });
  });

  if (requestState === 'error') {
    return (
      <ErrorScreen
        onRetry={() => {
          trackUserEvent({
            event: 'Clicked try again AI Menti Builder',
            properties: {
              context: TrackingContext.UserHome,
            },
          });
          onRetry();
          setRequestState('idle');
        }}
      />
    );
  }

  if (!ablyStreamUrl) {
    return <LoaderOverlay />;
  }

  return (
    <Animations.FadeIn delay={100}>
      <Box alignItems="center">
        <StreamDataHandler<CreateSeriesEventBodySchema>
          schema={createSeriesEventBodySchema}
          ablyStreamUrl={ablyStreamUrl}
          onError={handleError}
          startTimeout={requestState === 'loading'}
          timeoutInMs={TIMEOUT_DURATION_IN_MS}
        >
          {({ data: createSeriesEventResponse, stopListening }) => (
            <SeriesBuilder
              data={createSeriesEventResponse}
              shouldAnimate={shouldAnimate}
              onSeriesCreationDone={handleCoreApiSeriesCreation(
                createSeriesEventResponse,
                stopListening,
              )}
              onStart={handleAiApiAsyncSeriesCreationRequest}
              onError={() => setRequestState('error')}
              loaderDurationInMs={TIMEOUT_DURATION_IN_MS}
            />
          )}
        </StreamDataHandler>
      </Box>
    </Animations.FadeIn>
  );
};
