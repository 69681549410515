// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostGenerateSentimentResponse {
  status: string;
  requestId: string;
}

export async function postGenerateSentimentResponse(
  response: Response,
): Promise<PostGenerateSentimentResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostGenerateSentimentResponse;
}
