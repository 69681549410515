import type {
  PublicTemplateT,
  PublicTemplateWithSeriesT,
  Series,
  UserProfileT,
} from '@mentimeter/http-clients';
import { useState } from 'react';
import { useMatch } from '@mentimeter/ragnar-device';
import {
  ModalRoot,
  ModalContainer,
  ModalBody,
  ModalHeader,
} from '@mentimeter/ragnar-ui/modal';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { TemplateSlidePreview } from '../src/components/TemplateSlidePreview';
import type { ModalOpeningContextT, ModalTrackingContextT } from '../types';
import { StartFromScratchUI } from '../TemplateModal/StartFromScratch';
import { TemplateCardUI } from '../TemplateCard';
import { useApiTemplates } from '../hooks/usePublicTemplates';
import { TemplateDetails } from '../TemplateDetails/TemplateDetails';
import {
  filterTemplates,
  PRESENTATION_TYPE_TO_DISPLAY_QUESTION_ID,
  type TemplateFilterInclusive,
} from './filter-templates';

interface FilteredTemplatesModalProps {
  open: boolean;
  heading?: string;
  templateHeading: string;
  templateSubheading: string;
  createBlankText?: string;
  mode?: 'full-templates' | 'question-templates';
  filter: TemplateFilterInclusive;
  mainUsage: UserProfileT['main_usage'];
  trackingContext: ModalTrackingContextT;
  modalOpeningContext: ModalOpeningContextT;
  addTemplateButtonState: RagnarButtonState | undefined;
  onDismiss(): void;
  onAddTemplate(template: PublicTemplateWithSeriesT): void;
  onCreateBlank(): void;
}

export function FilteredTemplatesModal({
  open,
  heading = 'Templates',
  templateHeading,
  templateSubheading,
  createBlankText = 'Blank',
  mode = 'full-templates',
  filter,
  mainUsage,
  trackingContext,
  modalOpeningContext,
  addTemplateButtonState,
  onDismiss,
  onAddTemplate,
  onCreateBlank,
}: FilteredTemplatesModalProps) {
  const { templates, isLoading } = useFilteredTemplates({ filter, mainUsage });
  const [previewTemplate, setPreviewTemplate] =
    useState<PublicTemplateT | null>(null);
  const isMobileOrTablet = useMatch({ lessThan: 4 });
  const isMobile = useMatch({ lessThan: 2 });

  return (
    <ModalRoot
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onDismiss();
        }
      }}
    >
      <ModalContainer
        width="1140px"
        height="800px"
        data-testid="filtered-templates-modal"
      >
        <ModalHeader>{heading}</ModalHeader>
        <ModalBody px={0} py={0}>
          {previewTemplate && (
            <Box px={4} pt={4}>
              <TemplateDetails
                closeModal={onDismiss}
                insertAtIndex={0}
                isNewPresentationMode
                trackingContext={trackingContext}
                modalOpeningContext={modalOpeningContext}
                onBack={() => setPreviewTemplate(null)}
                template={previewTemplate}
              />
            </Box>
          )}
          {!previewTemplate && (
            <Box
              width="100%"
              flex="1"
              maxHeight="100%"
              extend={() => ({ overflowY: 'auto' })}
            >
              <Box width="100%" px={4} mt={3}>
                <Box
                  width="100%"
                  borderBottomWidth={2}
                  borderColor="borderWeak"
                  borderStyle="solid"
                />
              </Box>
              <Box px={4} pt={4}>
                <Text fontSize={3} fontWeight="semiBold" color="text" pb={2}>
                  {templateHeading}
                </Text>
                <Text fontSize={2} maxWidth="64ch">
                  {templateSubheading}
                </Text>
              </Box>
              <Box
                display="grid"
                width="100%"
                px={4}
                py={4}
                extend={() => {
                  const numberOfColumns = isMobileOrTablet
                    ? isMobile
                      ? 1
                      : 2
                    : 3;
                  return {
                    gridGap: '24px',
                    gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
                  };
                }}
              >
                {isLoading && <SkeletonTemplates />}
                {!isLoading && (
                  <StartFromScratchUI
                    text={createBlankText}
                    onClick={onCreateBlank}
                  />
                )}
                {!isLoading &&
                  templates
                    .filter(
                      (
                        template,
                      ): template is PublicTemplateT & { series: Series } => {
                        return Boolean(template.series);
                      },
                    )
                    .map((template) => (
                      <TemplateCardUI
                        key={template.id}
                        template={template}
                        showSlideCount={mode === 'full-templates'}
                        addTemplateButtonState={addTemplateButtonState}
                        onClick={
                          mode === 'full-templates'
                            ? () => setPreviewTemplate(template)
                            : () => onAddTemplate(template)
                        }
                        onPreview={
                          mode === 'full-templates'
                            ? () => setPreviewTemplate(template)
                            : undefined
                        }
                        onAdd={() => onAddTemplate(template)}
                      >
                        <TemplateSlidePreview
                          seriesId={template.series.id}
                          displaySlideId={PRESENTATION_TYPE_TO_DISPLAY_QUESTION_ID[
                            filter.presentationType
                          ](template)}
                        />
                      </TemplateCardUI>
                    ))}
              </Box>
            </Box>
          )}
          <Fader />
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
}

function SkeletonTemplates() {
  return (
    <>
      <Box width="100%" extend={() => ({ aspectRatio: '1.77' })}>
        <Skeleton width="100%" height="100%" variant="rectangle" />
      </Box>

      <Box width="100%" extend={() => ({ aspectRatio: '1.77' })}>
        <Skeleton width="100%" height="100%" variant="rectangle" />
      </Box>

      <Box width="100%" extend={() => ({ aspectRatio: '1.77' })}>
        <Skeleton width="100%" height="100%" variant="rectangle" />
      </Box>

      <Box width="100%" extend={() => ({ aspectRatio: '1.77' })}>
        <Skeleton width="100%" height="100%" variant="rectangle" />
      </Box>

      <Box width="100%" extend={() => ({ aspectRatio: '1.77' })}>
        <Skeleton width="100%" height="100%" variant="rectangle" />
      </Box>

      <Box width="100%" extend={() => ({ aspectRatio: '1.77' })}>
        <Skeleton width="100%" height="100%" variant="rectangle" />
      </Box>
    </>
  );
}

function Fader() {
  return (
    <Box
      width="100%"
      height="36px"
      position="fixed"
      bottom="0"
      left="0"
      zIndex={1}
      extend={({ theme }) => ({
        background: `linear-gradient(0deg, ${theme.colors.bg}, ${theme.colors.bg}, transparent)`,
      })}
    />
  );
}

function useFilteredTemplates({
  filter,
  mainUsage,
}: {
  filter: TemplateFilterInclusive;
  mainUsage: UserProfileT['main_usage'];
}) {
  const { data } = useApiTemplates(false, mainUsage, {
    include: { series: true },
    largeTemplates: true,
  });
  if (!data) return { templates: [], isLoading: true };

  return { templates: filterTemplates(data, filter), isLoading: false };
}
