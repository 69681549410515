import React from 'react';
import type { Pictogram } from '../types';
import { Svg } from '../../svg';
import { COLOR_MAP } from '../colorMap';

export const Ranking = ({ userColor, variant = 'coral' }: Pictogram) => {
  return (
    <Svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      aria-hidden="true"
      className="light"
    >
      <path
        d="M150 49H50v102h33v-34h33V83h34V49Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
    </Svg>
  );
};
