import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@mentimeter/ragnar-visuals';
import {
  animated,
  easings,
  type SpringValues,
  useSpring,
} from '@react-spring/web';
import { Lottie } from '@mentimeter/motion';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { IconButton } from '@mentimeter/ragnar-ui/icon-button';
import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';
import { PromptForm } from '../PromptForm';
import { Animations } from '../animations';
import { CircleButton } from '../CircleButton';
import type { AIBuilderStepState } from '../../hooks';
import AiLoader from '../../assets/ai-sparkles-white';

export const SuggestionTextareaPrompt = ({
  label,
  defaultValue,
  placeholder,
  suggestions,
  shouldAnimate,
  onSubmit,
  isLoading,
  onSuggestionClicked,
  onBack,
}: {
  label: string;
  defaultValue?: string;
  suggestions?: string[];
  shouldAnimate: boolean;
  onSubmit: (
    prompt: string,
    suggestion?: string | undefined,
    suggestionIndex?: number | undefined,
  ) => void;
  isLoading: boolean;
  placeholder?: string;
  onSuggestionClicked?: (suggestion: string, position: number) => void;
  onBack?: (() => AIBuilderStepState) | undefined;
}) => {
  const [prompt, setPrompt] = React.useState<string>(defaultValue ?? '');
  const [selectedSuggestion, setSelectedSuggestion] = React.useState<
    string | undefined
  >();

  const MAX_CLICK_TOOLTIP_TRIGGER = 3;
  const [showIconButton, setShowIconButton] = useState(false);
  const [aiLoaderClickCounter, setAiLoaderClickCounter] = React.useState(0);
  const timeout = useRef<undefined | NodeJS.Timeout>(undefined);
  const style = useSpring({
    // numbers are hardcoded to match the original design of the button and to smooth the transition
    to: {
      width: isLoading ? 40 : 120,
      paddingLeft: isLoading ? 0 : 24,
      paddingRight: isLoading ? 0 : 24,
    },
    from: {
      width: 120,
      paddingLeft: 24,
      paddingRight: 24,
    },
    config: { duration: 300, easing: easings.easeOutQuad },
    // timeout is needed in order to let react spring finish animation before setting the icon
    onRest: () => {
      timeout.current = setTimeout(() => setShowIconButton(isLoading), 150);
    },
  });

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    };
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const suggestionIndex = selectedSuggestion
      ? suggestions?.indexOf(selectedSuggestion)
      : undefined;

    onSubmit(prompt, selectedSuggestion, suggestionIndex);
  };

  const handlePromptSuggestionClick = (
    suggestion: string,
    position: number,
  ) => {
    if (onSuggestionClicked) {
      onSuggestionClicked(suggestion, position);
    }
    setSelectedSuggestion(suggestion);
    setPrompt(suggestion);
  };

  return (
    <PromptForm onSubmit={handleSubmit}>
      <Animations.FadeIn delay={100} skip={!shouldAnimate}>
        <PromptForm.Label>{label}</PromptForm.Label>
      </Animations.FadeIn>
      <Animations.FadeIn
        delay={500}
        style={{ width: '100%', position: 'relative' }}
        skip={!shouldAnimate}
      >
        {onBack && (
          <Box
            position="absolute"
            left={-120}
            bottom="50%"
            style={{ transform: 'translate(0,50%)' }}
          >
            <Animations.FadeIn delay={250}>
              <CircleButton aria-label="Go back" onClick={onBack}>
                <ArrowLeftIcon />
              </CircleButton>
            </Animations.FadeIn>
          </Box>
        )}
        <PromptForm.TextArea
          my={4}
          id="prompt"
          data-testid="prompt"
          value={prompt}
          minHeight="150px"
          placeholder={placeholder}
          onChange={(e) => setPrompt(e.target.value)}
          disabled={!prompt}
          isLoading={isLoading}
          maxLength={30000}
        />
      </Animations.FadeIn>

      <Animations.FadeIn delay={1000} skip={!shouldAnimate}>
        {suggestions && !prompt ? (
          <PromptForm.Suggestions
            disabled={isLoading}
            suggestions={suggestions}
            onClick={handlePromptSuggestionClick}
          />
        ) : (
          <Box width="auto" alignItems="center" height={134}>
            {showIconButton ? (
              <>
                {aiLoaderClickCounter >= MAX_CLICK_TOOLTIP_TRIGGER && (
                  <TooltipOld
                    referenceId="ai-builder-prompt-loader"
                    description="Hang in there - I’m digesting your wishes and will be ready soon!"
                  />
                )}

                <IconButton
                  size="large"
                  borderRadius="full"
                  id="ai-builder-prompt-loader"
                  data-testid="ai-builder-prompt-icon"
                  onClick={() =>
                    setAiLoaderClickCounter(aiLoaderClickCounter + 1)
                  }
                >
                  <Lottie animationData={AiLoader} autoplay loop />
                </IconButton>
              </>
            ) : (
              <Button
                variant="primary"
                type="submit"
                size="large"
                as={animated.button}
                aria-label="Submit prompt"
                disabled={isLoading || !prompt}
                style={{ ...(style as SpringValues), overflow: 'hidden' }}
              >
                Submit
                <Box ml={1}>
                  <ArrowRightIcon size={2} color="inherit" />
                </Box>
              </Button>
            )}
          </Box>
        )}
      </Animations.FadeIn>
    </PromptForm>
  );
};
