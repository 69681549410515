import { LinkButton } from '@mentimeter/next-navigation';
import { useMatch } from '@mentimeter/ragnar-device';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';

export function ArrowLink({
  actionText,
  shortActionText,
  actionHref,
  onAction,
}: {
  actionText: string;
  shortActionText?: string;
  actionHref?: string;
  onAction?: () => void;
}) {
  const isMobile = useMatch({ lessThan: 3 });

  return (
    <Box flexDirection="row" flex="1" justifyContent="flex-end">
      <LinkButton
        variant="tertiary"
        size="compact"
        iconTrailing={ChevronRightIcon}
        href={actionHref || ''}
        onClick={(e) => {
          if (onAction) {
            e.preventDefault();
            onAction();
          }
        }}
      >
        {isMobile ? (shortActionText ?? actionText) : actionText}
      </LinkButton>
    </Box>
  );
}
