/* eslint-disable menti-react/filename-convention--jsx */
import { Link } from '@mentimeter/next-navigation';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Emphasis, Heading, Media, Paragraph, Section, Strong } from './styles';

export function UsingMentimeterWithOtherTools() {
  return (
    <Box>
      <Section>
        <Media
          mediaType="lottie"
          mediaUrl="https://static.mentimeter.com/static/motion/favorite-tools.lottie"
        />
      </Section>
      <Section>
        <Heading>Add a Menti to your PowerPoint</Heading>
        <Paragraph>
          If you already have a presentation ready in Powerpoint, we have a{' '}
          <Link href="https://appsource.microsoft.com/en-us/product/office/WA104379261?exp=ubp8&tab=Overview">
            PowerPoint add-in
          </Link>{' '}
          that makes it easy to add our most popular Menti questions directly in
          your presentation.
        </Paragraph>
      </Section>
      <Section>
        <Heading>Use our MS teams of Zoom app during your video call</Heading>
        <Paragraph>
          We have apps for{' '}
          <Link href="https://www.mentimeter.com/microsoft-teams">
            Microsoft Teams
          </Link>{' '}
          & <Link href="https://www.mentimeter.com/zoom">Zoom</Link> and that
          make it easy for participants to vote directly in the video call tool.
          Important to note is that participants have to download the app to
          vote, so this works best if you are regularly using Mentimeter at your
          workplace.
        </Paragraph>
      </Section>
      <Section>
        <Heading>Import Google Slides or PowerPoint into your Menti</Heading>
        <Paragraph>
          If you are paying for Mentimeter, you can easily import your{' '}
          <Link href="https://help.mentimeter.com/en/articles/6445389-embed-a-google-slides-presentation-into-mentimeter">
            Google Slides
          </Link>{' '}
          or{' '}
          <Link href="https://help.mentimeter.com/en/articles/6445677-embed-a-powerpoint-presentation-into-mentimeter">
            PowerPoint presentations
          </Link>{' '}
          into your Menti. It also works for any PDF file. Simply click{' '}
          <Strong>Import presentation</Strong> in the editor to get started.
        </Paragraph>
      </Section>

      <Section>
        <Paragraph>
          <Emphasis>
            {' '}
            You can find our full list of all apps and integrations{' '}
            <Link href="https://help.mentimeter.com/en/collections/3187256-apps-and-integrations">
              here
            </Link>
            .
          </Emphasis>
        </Paragraph>
      </Section>
    </Box>
  );
}
