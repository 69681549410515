import { trackUser } from '@api/tracking/client';
import {
  type TrackingPayload,
  type TrackingContext,
} from '@mentimeter/http-clients';

export type Context = TrackingContext;

interface ViewedAIMentiBuilder extends TrackingPayload {
  event: 'Viewed AI Menti Builder';
  properties: {
    context: Context;
    version?: string;
  };
}

interface ClickedPromptSuggestionAIMentiBuilder extends TrackingPayload {
  event: 'Clicked prompt suggestion AI Menti Builder';
  properties: {
    context: Context;
    prompt_step: PromptStep;
    suggestion: string;
    suggestion_position: number;
    version?: string;
  };
}

export type PromptStep = 'initial' | 'follow-up' | 'nonsense';

export type MissingPromptKey = 'Engagement type' | 'Topic' | '';

interface SubmittedPromptAIMentiBuilder extends TrackingPayload {
  event: 'Submitted prompt AI Menti Builder';
  properties: {
    context: Context;
    prompt_step: PromptStep;
    prompt_length: number;
    suggestion?: string | undefined;
    suggestion_position?: number | undefined;
    modified_suggestion?: boolean | undefined;
    follow_up_question?: string | undefined;
    missing_key?: MissingPromptKey;
    version?: string;
  };
}

interface SelectedThemeAIMentiBuilder extends TrackingPayload {
  event: 'Selected theme AI Menti Builder';
  properties: {
    context: Context;
    selected_theme: string;
    theme_id: number;
    theme: string;
    version?: string;
  };
}

interface ViewedSlideGeneratorLoadingAIMentiBuilder extends TrackingPayload {
  event: 'Viewed slides generator loading AI Menti Builder';
  properties: {
    context: Context;
    version?: string;
  };
}

interface SuccessfullyFinishedAIMentiBuilder extends TrackingPayload {
  event: 'Successfully finished AI Menti Builder';
  properties: {
    context: Context;
    series_id: string;
    generation_time_in_ms: number;
    engagement_type?: string;
    topic?: string;
    language?: string;
    image_keyword: string;
    missing_keys?: string;
    intent_object?: Record<string, any> | undefined;
    tool?: string;
    has_attachment?: boolean;
    has_structure_instructions?: boolean;
    version?: string;
  };
}

interface LandedInEditorAIMentiBuilder extends TrackingPayload {
  event: 'Landed in editor from AI Menti Builder';
  properties: {
    context: Context;
    series_id: string;
  };
}

interface ViewedErrorMessageAIMentiBuilder extends TrackingPayload {
  event: 'Viewed error message AI Menti Builder';
  properties: {
    context: Context;
    error_step: 'initial' | 'nonsense' | 'follow-up' | 'build';
    cause?: string;
    version?: string;
  };
}

interface ClickedTryAgainAIMentiBuilder extends TrackingPayload {
  event: 'Clicked try again AI Menti Builder';
  properties: {
    context: Context;
    version?: string;
  };
}

type TrackingEventPayload =
  | ViewedAIMentiBuilder
  | ClickedPromptSuggestionAIMentiBuilder
  | SubmittedPromptAIMentiBuilder
  | SelectedThemeAIMentiBuilder
  | ViewedSlideGeneratorLoadingAIMentiBuilder
  | SuccessfullyFinishedAIMentiBuilder
  | LandedInEditorAIMentiBuilder
  | ViewedErrorMessageAIMentiBuilder
  | ClickedTryAgainAIMentiBuilder;

type TrackUserEvent = (payload: TrackingEventPayload) => void;

export const trackUserEvent: TrackUserEvent = (payload) => {
  const trackingPayload: TrackingPayload = payload;

  trackUser(trackingPayload);
};
