// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostExtractKeyPointsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  activeSlideWithResults: {
    type: string;
    content: unknown;
    results: unknown;
  };
  userPrompt?: string;
  ablyChannelId: string;
  requestId?: string;
}

export function postExtractKeyPointsRequest(
  requestParams: PostExtractKeyPointsRequest,
): Request {
  const data = {
    active_slide_with_results: toSnakeKeys(
      requestParams.activeSlideWithResults,
    ),
    user_prompt: toSnakeKeys(requestParams.userPrompt),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/extract-key-points`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
