'use client';

import React from 'react';
import { TrackingContext, type WorkspaceRole } from '@mentimeter/http-clients';
import type { UserCollaborationRole } from '@mentimeter/share-modal';
import { UsersSolidIcon } from '@mentimeter/ragnar-visuals';
import { useUser } from '@mentimeter/user';
import { TRACKING_PLACEMENT } from 'features/dashboard/presentation-view/constants';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { HomeWidget } from '../shared/home-widget';
import { useBreakpoints } from '../shared/use-breakpoints';
import { PresentationCard } from './presentation-card';
import { HomePresentationContextMenu } from './presentation-card/HomePresentationContextMenu';
import { useRecentPresentations } from './useRecentPresentations';
import type { RecentPresentationsInitialData } from './types';

export function RecentPresentations({
  presentations,
}: { presentations: RecentPresentationsInitialData }) {
  const { user } = useUser();
  const { data: series } = useRecentPresentations({
    initialData: presentations,
  });

  const { activeBreakpointIndex } = useBreakpoints();
  const itemsPerBreakpoint = [3, 2, 2, 3];

  const latestSeries =
    series?.slice(0, itemsPerBreakpoint[activeBreakpointIndex]) ?? [];

  if (latestSeries.length === 0) return null;

  return (
    <HomeWidget.Root>
      <HomeWidget.Header
        heading="Recently viewed"
        id="recently-viewed-widget"
      />

      <Box
        width="100%"
        display="grid"
        gridTemplateColumns={[
          'repeat(auto-fit, minmax(300px, 1fr))',
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
        ]}
        gap={['space8', 'space5']}
      >
        {latestSeries.map((serie, i) => (
          <PresentationCard
            key={serie.slideDeckPublicKey}
            id={i === 0 ? 'recently-viewed-first-card' : undefined}
            seriesId={serie.slideDeckPublicKey}
            seriesName={serie.name}
            seriesUpdatedAt={serie.updatedAt}
            dashboardType="recent"
            sharedIcon={
              (serie.shared || serie.workspaceId) && <UsersSolidIcon size={0} />
            }
            hasResults={serie.hasResults}
            ownerAvatar={{
              id: serie.ownerId,
              name: serie.ownerName ?? 'Unknown User',
              pictureUrl: serie.ownerProfilePictureUrl,
            }}
            actions={
              <HomePresentationContextMenu
                item={serie}
                user={user}
                trackingContext={TrackingContext.UserHome}
                trackingPlacement={TRACKING_PLACEMENT.RECENT_PRESENTATIONS}
              />
            }
            showTemplateHelpers={!!user?.features.template_editor}
            onClickPresentation={() => {
              if (!user) return;

              trackClickedPresentation({
                seriesId: serie.slideDeckPublicKey,
                userWorkspaceId: user.workspaceId,
                trackingContext: TrackingContext.UserHome,
                shared: Boolean(serie.shared),
                role: user.id === serie.ownerId ? 'Owner' : 'Collaborator',
                folderId: serie.folderId ?? null,
              });
            }}
            onClickViewResults={() => {
              if (!user) return;

              trackClickViewResults({
                userId: user.id,
                userRole: user.role,
                seriesId: serie.slideDeckPublicKey,
                seriesOwnerId: serie.ownerId,
                seriesWorkspaceId: serie.workspaceId,
                trackingContext: TrackingContext.UserHome,
                inUserSharedFolder: serie.inUserSharedFolder,
              });
            }}
          />
        ))}
      </Box>
    </HomeWidget.Root>
  );
}
function trackClickedPresentation({
  seriesId,
  userWorkspaceId,
  trackingContext,
  shared,
  role,
  folderId,
}: {
  seriesId: string;
  userWorkspaceId: number | undefined;
  trackingContext: TrackingContext;
  shared: boolean;
  role: UserCollaborationRole;
  folderId: number | null;
}) {
  trackUser({
    event: 'Clicked presentation',
    properties: {
      context: trackingContext,
      'series id': seriesId,
      'workspace id': userWorkspaceId,
      'folder id': folderId,
      role,
      shared,
    },
  });
}

function trackClickViewResults({
  seriesId,
  seriesOwnerId,
  seriesWorkspaceId,
  userId,
  userRole,
  trackingContext,
  inUserSharedFolder,
}: {
  seriesId: string;
  seriesOwnerId: number;
  seriesWorkspaceId: number | null;
  userId: number;
  userRole: WorkspaceRole;
  trackingContext: TrackingContext;
  inUserSharedFolder: boolean;
}) {
  const seriesRole = seriesOwnerId === userId ? 'Owner' : 'Collaborator';
  trackUser({
    event: 'Opened results view',
    properties: {
      context: trackingContext,
      placement: TRACKING_PLACEMENT.PRESENTATION_GRID,
      role: seriesRole,
      'presentation owner': seriesRole === 'Owner',
      'workspace role': userRole,
      'shared presentation': Boolean(seriesWorkspaceId),
      'series id': seriesId,
      'user shared folder': inUserSharedFolder,
    },
  });
}
