import { z } from 'zod';

const multipleChoiceSchema = z.object({
  type: z.literal('choices'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string() })),
  }),
});

const scalesSchema = z.object({
  type: z.literal('scales'),
  content: z.object({
    title: z.string(),
    labels: z.object({ min: z.string(), max: z.string() }),
    items: z.array(z.object({ item: z.string() })),
  }),
});

const openEndedSchema = z.object({
  type: z.literal('open_ended'),
  content: z.object({
    title: z.string(),
    subtitle: z.string(),
  }),
});

const rankingSchema = z.object({
  type: z.literal('ranking'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string() })),
  }),
});

const bigSchema = z.object({
  type: z.literal('big'),
  content: z.object({
    title: z.string(),
  }),
});

const paragraphSchema = z.object({
  type: z.literal('paragraph'),
  content: z.object({
    title: z.string(),
    subtitle: z.string(),
  }),
});

const headingSchema = z.object({
  type: z.literal('heading'),
  content: z.object({
    title: z.string(),
    subtitle: z.string(),
  }),
});

const wordcloudSchema = z.object({
  type: z.literal('wordcloud'),
  content: z.object({
    title: z.string(),
  }),
});

const quizSchema = z.object({
  type: z.literal('quiz'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string(), correct: z.boolean() })),
  }),
});

const quizOpenSchema = z.object({
  type: z.literal('quiz_open'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string() })),
  }),
});

const quizLeaderboardSchema = z.object({
  type: z.literal('quiz_leaderboard'),
  content: z.object({
    title: z.string(),
  }),
});

const trueOrFalseSchema = z.object({
  type: z.literal('true_or_false'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string(), correct: z.boolean() })),
  }),
});

const prioritisationSchema = z.object({
  type: z.literal('prioritisation'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string() })),
  }),
});

const bulletsSchema = z.object({
  type: z.literal('bullets'),
  content: z.object({
    title: z.string(),
    items: z.array(z.object({ item: z.string() })),
  }),
});

const quoteSchema = z.object({
  type: z.literal('quote'),
  content: z.object({
    title: z.string(),
    subtitle: z.string(),
  }),
});

const commonSlideSchema = z.object({
  speaker_notes: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
});

export const generateSlideBodySchema = z.intersection(
  commonSlideSchema,
  z.union([
    multipleChoiceSchema,
    scalesSchema,
    openEndedSchema,
    rankingSchema,
    bigSchema,
    paragraphSchema,
    headingSchema,
    wordcloudSchema,
    quizSchema,
    quizOpenSchema,
    quizLeaderboardSchema,
    prioritisationSchema,
    bulletsSchema,
    quoteSchema,
    trueOrFalseSchema,
  ]),
);

export type GenerateSlideResponse = z.infer<typeof generateSlideBodySchema>;
