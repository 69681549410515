import React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const RangeStyled = styled('input', {
  displayName: 'Range',
})('kosmosSpacing');

export interface RangeT extends React.ComponentProps<typeof RangeStyled> {
  thumbColor?: string;
  trackColor?: string;
}

export const Range = (props: RangeT) => {
  const { className, thumbColor, trackColor } = props;
  const defaultClasses = [
    'r-range', // reset selector
    'w-full',
    'appearance-none',
    props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    'bg-transparent',
    'overflow-visible',
    'h-10',
  ];

  const thumbClass = [
    //thumb classes Chrome
    '[&::-webkit-slider-thumb]:appearance-none',
    '[&::-webkit-slider-thumb]:border-2',
    '[&::-webkit-slider-thumb]:border-solid',
    '[&::-webkit-slider-thumb]:border-[theme(backgroundColor.DEFAULT)]',
    '[&::-webkit-slider-thumb]:rounded-full',
    '[&::-webkit-slider-thumb]:transition-all',
    '[&::-webkit-slider-thumb]:z-[2]',
    '[&::-webkit-slider-thumb]:h-6',
    '[&::-webkit-slider-thumb]:w-6',
    '[&::-webkit-slider-thumb]:translate-y-[calc(2px-1.5rem/2)]',
    props.disabled
      ? '[&::-webkit-slider-thumb]:bg-[theme(backgroundColor.disabled.strong)]'
      : '[&::-webkit-slider-thumb]:bg-[var(--range-thumb-color,theme(backgroundColor.brand.DEFAULT))]',
    //thumb classes Firefox
    '[&::-moz-range-thumb]:appearance-none',
    '[&::-moz-range-thumb]:border-2',
    '[&::-moz-range-thumb]:border-solid',
    '[&::-moz-range-thumb]:border-[theme(backgroundColor.DEFAULT)]',
    '[&::-moz-range-thumb]:rounded-full',
    '[&::-moz-range-thumb]:transition-all',
    '[&::-moz-range-thumb]:z-[2]',
    '[&::-moz-range-thumb]:h-6',
    '[&::-moz-range-thumb]:w-6',
    '[&::-moz-range-thumb]:origin-[0px_-40px]',
    props.disabled
      ? '[&::-moz-range-thumb]:bg-[theme(backgroundColor.disabled.strong)]'
      : '[&::-moz-range-thumb]:bg-[var(--range-thumb-color,theme(backgroundColor.brand.DEFAULT))]',
    //thumb classes IE/ Edge
    '[&::-ms-thumb]:appearance-none',
    '[&::-ms-thumb]:border-2',
    '[&::-ms-thumb]:border-solid',
    '[&::-ms-thumb]:border-[theme(backgroundColor.DEFAULT)]',
    '[&::-ms-thumb]:rounded-full',
    '[&::-ms-thumb]:transition-all',
    '[&::-ms-thumb]:z-[2]',
    '[&::-ms-thumb]:h-6',
    '[&::-ms-thumb]:w-6',
    '[&::-ms-thumb]:transform-none',
    props.disabled
      ? '[&::-ms-thumb]:bg-[theme(backgroundColor.disabled.strong)]'
      : '[&::-ms-thumb]:bg-[var(--range-thumb-color,theme(backgroundColor.brand.DEFAULT))]',

    //thumb focus classes Chrome
    '[&:focus::-webkit-slider-thumb]:outline-[16px]',
    '[&:focus::-webkit-slider-thumb]:outline',
    '[&:focus::-webkit-slider-thumb]:outline-interactive-focused',
    '[&:focus::-webkit-slider-thumb]:translate-y-[calc(2px-1.5rem/2)]',
    // thumb focus classes Firefox
    '[&:focus::-moz-range-thumb]:outline-[16px]',
    '[&:focus::-moz-range-thumb]:outline',
    '[&:focus::-moz-range-thumb]:outline-interactive-focused',
    //thumb focus classes IE/Edge
    '[&:focus::-ms-thumb]:outline-[16px]',
    '[&:focus::-ms-thumb]:outline',
    '[&:focus::-ms-thumb]:outline-interactive-focused',
  ];
  const trackClass = [
    //track classes Chrome
    '[&::-webkit-slider-runnable-track]:z-1',
    '[&::-webkit-slider-runnable-track]:h-[2px]',
    '[&::-webkit-slider-runnable-track]:rounded-full',
    '[&::-webkit-slider-runnable-track]:transition-colors',
    props.disabled
      ? '[&::-webkit-slider-runnable-track]:bg-[theme(backgroundColor.disabled.strong)]'
      : '[&::-webkit-slider-runnable-track]:bg-[var(--range-track-color,theme(textColor.DEFAULT))]',
    //track classes Firefox
    '[&::-moz-range-track]:z-1',
    '[&::-moz-range-track]:h-[2px]',
    '[&::-moz-range-track]:rounded-full',
    '[&::-moz-range-track]:transition-colors',
    props.disabled
      ? '[&::-moz-range-track]:bg-[theme(backgroundColor.disabled.strong)]'
      : '[&::-moz-range-track]:bg-[var(--range-track-color,theme(textColor.DEFAULT))]',
    //track classes IE/ Edge
    '[&::-ms-track]:z-1',
    '[&::-ms-track]:h-[2px]',
    '[&::-ms-track]:rounded-full',
    '[&::-ms-track]:transition-colors',
    '[&::-ms-track]:overflow-visible',
    props.disabled
      ? '[&::-ms-track]:bg-[theme(backgroundColor.disabled.strong)]'
      : '[&::-ms-track]:bg-[var(--range-track-color,theme(textColor.DEFAULT))]',
  ];
  return (
    <RangeStyled
      className={cn(defaultClasses, trackClass, thumbClass, className)}
      type="range"
      {...props}
      style={
        {
          '--range-thumb-color': thumbColor,
          '--range-track-color': trackColor,
        } as React.CSSProperties
      }
    />
  );
};
