import z from 'zod';

const Structure = z.union([z.string(), z.null()]);
const EngagementType = z.union([
  z.enum([
    'Ideation Workshop',
    'Feedback and Review Workshop',
    'Retrospective Workshop',
    'Problem-Solving Workshop',
    'Project Planning Workshop',
    'Team Building Session',
    'Seminars',
    'Generic Survey',
    'Employee Satisfaction Survey',
    'Event Feedback Survey',
    'Assessing Knowledge Survey',
    'General Knowledge Quiz',
    'Educational Quiz',
    'Corporate Compliance Quiz',
    'Educational Presentation',
    'Business Presentation',
    'Company All Hands Meeting',
    'Team Meeting',
    'Training',
    'Elevator Pitch',
    'Public Engagement',
  ]),
  z.string(),
  z.null(),
]);

const SubType = z.union([
  z.enum(['retrospective', 'pulse check', 'ideation', 'brainstorming']),
  z.string(),
  z.null(),
]);

const Topic = z.union([z.string(), z.null()]);

const Language = z.union([z.string(), z.null()]);

const Objective = z.union([z.string(), z.null()]);

const SpecificRequests = z.union([z.string(), z.null()]);

const KeyPoints = z.union([z.string(), z.null()]);

const ContentProficiencyLevel = z.union([
  z.enum(['beginner', 'intermediate', 'advanced', 'mastery']),
  z.string(),
  z.null(),
]);

const ToneOfVoice = z.union([
  z.enum(['formal', 'informal', 'friendly', 'serious', 'humorous']),
  z.string(),
  z.null(),
]);

const Design = z.union([z.string(), z.null()]);

const Length = z.union([z.number(), z.null()]);

const ImageKeywords = z.array(z.string()).nullable();

const Nonsensical = z.union([z.enum(['Nonsensical', 'Relevant']), z.null()]);

const Question = z.string();

const Answer = z.string();

const UserInputs = z.array(
  z.object({
    question: Question,
    answer: Answer,
  }),
);

export const intentSchema = z.object({
  sub_type: SubType.optional(),
  content_proficiency_level: ContentProficiencyLevel.optional(),
  tone_of_voice: ToneOfVoice.optional(),
  structure: Structure.optional(),
  engagement_type: EngagementType.optional(),
  topic: Topic.optional(),
  language: Language.optional(),
  objective: Objective.optional(),
  specific_requests: SpecificRequests.optional(),
  key_points: KeyPoints.optional(),
  design: Design.optional(),
  number_of_slides: Length.optional(),
  image_keywords: ImageKeywords.optional(),
  nonsensical: Nonsensical.optional(),
  user_inputs: UserInputs.optional(),
  is_complete: z.boolean(),
});

export const followUpSchema = z
  .object({
    question: z.object({
      content: z.string().nullable(),
      // TODO: remove emphasis when it will be removed from the AI api response
      emphasis: z.string().nullable().optional(),
    }),
    suggestions: z.array(z.string()).nullable(),
  })
  .nullable();

export const analyzeEventBodySchema = z.object({
  intent: intentSchema,
  follow_up: followUpSchema,
});
