import React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { Box } from '@mentimeter/ragnar-ui/box';

export function GuideProgressBar({
  progress,
  lastReadAt,
}: {
  progress: number;
  lastReadAt: string;
}) {
  const { renderer } = useRagnar();

  const visualProgress = 0.25 + progress * 0.75;
  const done = visualProgress >= 1;

  const timeSinceLastDone = Date.now() - Date.parse(lastReadAt);
  const animateDone = done && timeSinceLastDone < 5000;

  const keyframes =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '0%': {
          transform: 'scale(1) rotate(0deg)',
        },
        '25%': {
          transform: 'rotate(8deg)',
        },
        '50%': {
          transform: 'scale(1.1) rotate(-7deg)',
        },
        '70%': {
          transform: 'rotate(5deg)',
        },
        '90%': {
          transform: 'rotate(4deg)',
        },
        '100%': {
          transform: 'scale(1) rotate(0deg)',
        },
      }),
      {},
    );

  return (
    <Box height="100%" flexDirection="row" alignItems="center" ml={2} mr={2}>
      <Box
        height="4px"
        width="160px"
        borderRadius="full"
        bg="neutralWeakest"
        overflow="hidden"
        extend={() => ({
          ...(animateDone
            ? {
                transform: 'rotate(0deg)',
                animationDelay: '0ms',
                animationDuration: '400ms',
                animationIterationCount: '1',
                animationTimingFunction: 'ease',
                animationName: keyframes,
                animationFillMode: 'forwards',
              }
            : { transform: 'rotate(0deg)' }),
        })}
      >
        <Box
          borderRadius="full"
          height="100%"
          width={`${visualProgress * 100}%`}
          bg="secondary"
          extend={() => ({
            transition: 'width .18s ease-out',
          })}
        />
      </Box>
    </Box>
  );
}
