import type { Question, QuestionDimension } from '@mentimeter/http-clients';
import type { AiApiGeneratedSlideSchemaV3 } from '@mentimeter/ai-api-streamer';
import type { JSONContent } from '@tiptap/core';
import { getLayoutProperties } from './core-questions/layout-property-setters';
import { isInteractiveQuestion } from './default-core-question-factory';

function extractFreeTextHeading(freeTextContent: JSONContent): string {
  const heading = freeTextContent.content?.find(
    (content) => 'type' in content && content.type === 'heading',
  );

  if (!heading || !heading.content) {
    return '';
  }

  const headingContent = heading.content[0];

  if (!headingContent) {
    return '';
  }

  return headingContent.text || '';
}

export function setQuestion(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'free_text':
      coreQuestion.question = extractFreeTextHeading(aiSlide.content);
      return;
    default:
      coreQuestion.question = aiSlide.content.title || 'Untitled';
  }
}

export function setQuestionDescriptionStyled(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  if (aiSlide.type !== 'free_text') {
    return;
  }

  coreQuestion.question_description_styled = aiSlide.content;
}

export function setSlideDimensions(
  dimensions: Partial<QuestionDimension>,
): QuestionDimension {
  const { max = '', min = '' } = dimensions;
  return {
    max,
    min,
    label: dimensions.label || '',
    mid_values: dimensions.mid_values || [],
  };
}

export function setSuggestedImage(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  const suggestedImage = aiSlide.image;

  if (!suggestedImage) {
    return;
  }

  if (isInteractiveQuestion(coreQuestion)) {
    return;
  }

  coreQuestion.question_image_url = suggestedImage;
  coreQuestion.cropped_image_url = suggestedImage;

  const randomLayout = Math.random() < 0.5 ? 'right' : 'left';
  coreQuestion.layout = getLayoutProperties(randomLayout, 'left-align');
}

export function setBulletProperties(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'bullets':
      coreQuestion.show_all_bullets = true;
      return;
  }
}

export function setSkipSlide(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  if (aiSlide.skip_slide) {
    coreQuestion.skip_slide = aiSlide.skip_slide;
  }
}

export function setDimensions(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  if (aiSlide.type === 'free_text' || !aiSlide.content.items) {
    return;
  }

  switch (aiSlide.type) {
    case 'quiz_select_answer':
      let dimensions = aiSlide.content?.items
        ?.map((item) => ({
          label: item.text,
          correct: item.correct,
        }))
        .map(setSlideDimensions);
      if (!dimensions) {
        dimensions = [setSlideDimensions({}), setSlideDimensions({})];
      }

      coreQuestion.dimensions = [dimensions[0]!, dimensions[1]!];
      break;
    case 'scales':
      coreQuestion.dimensions = [
        setSlideDimensions({
          min: aiSlide.content.labels?.min,
          max: aiSlide.content.labels?.max,
          mid_values: ['', '', ''],
        }),
        setSlideDimensions({
          min: '',
          max: '',
        }),
      ];
      return;
  }
}

export function setShowCorrect(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'true_or_false':
      coreQuestion.show_correct = true;
      return;

    default:
      return;
  }
}

export function setChoices(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'multiple_choice':
    case 'choices':
    case 'quiz_select_answer':
    case 'ranking':
    case 'bullets':
    case 'scales':
    case 'quiz':
    case 'quiz_open':
    case 'prioritisation':
    case 'true_or_false':
      coreQuestion.choices =
        aiSlide?.content?.items?.map((item, index) => {
          const correct_answer = 'correct' in item ? item.correct : false;
          return {
            label: item.item,
            id: index,
            position: index,
            correct_answer,
          };
        }) || [];
      return;

    case 'quiz_type_answer':
      coreQuestion.choices = aiSlide?.content.correctAnswers.map(
        (item, index) => {
          return {
            label: item,
            id: index,
            position: index,
            correct_answer: true,
          };
        },
      );
      return;
    default:
      return;
  }
}

export function setQuestionDescription(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'heading':
    case 'paragraph':
    case 'quote':
    case 'open_ended':
      coreQuestion.question_description = aiSlide.content.subtitle || '';
      return;
    case 'free_text':
      coreQuestion.question_description = extractFreeTextHeading(
        aiSlide.content,
      );
  }
}

export function setReactions(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'heading':
    case 'paragraph':
    case 'bullets':
    case 'quote':
    case 'open_ended':
    case 'ranking':
    case 'true_or_false':
      coreQuestion.reactions = ['heart1', 'thumbsup'];
  }
}

export function setRange(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'scales':
      coreQuestion.range = {
        min: 1,
        max: 5,
      };
      return;
  }
}

export function setVotes(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'open_ended':
      coreQuestion.multiple_votes_on_answers = true;
      coreQuestion.max_votes = 3;
      coreQuestion.duration_vote = 10;
      coreQuestion.duration_upvote = 5;
      coreQuestion.steps = 1;
      return;
  }
}

export function setSteps(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  switch (aiSlide.type) {
    case 'bullets':
      coreQuestion.steps = aiSlide.content.items.length;
      return;
  }
}

export function setPinImage(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  if (aiSlide.type === 'pin_on_image') {
    coreQuestion.question_image_url = aiSlide.content.imageToPin;
    coreQuestion.cropped_image_url = aiSlide.content.imageToPin;
    coreQuestion.layout = undefined;
  }
}

export function setSpeakerNotes(
  coreQuestion: Partial<Question>,
  aiSlide: AiApiGeneratedSlideSchemaV3,
) {
  // TODO fix the issue with parsing for the speaker notes
  if ((aiSlide as any).speakerNotes!) {
    coreQuestion.speaker_notes = (aiSlide as any).speakerNotes;
  }
}
