// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostGetSlidesQualityRequest {
  region: 'eu' | 'us';
  userAuth: string;
  presentationSlides: Array<unknown>;
  presentationMeta?: unknown;
  userPrompt?: string;
  ablyChannelId: string;
  requestId?: string;
}

export function postGetSlidesQualityRequest(
  requestParams: PostGetSlidesQualityRequest,
): Request {
  const data = {
    presentation_slides: requestParams.presentationSlides,
    presentation_meta: requestParams.presentationMeta,
    user_prompt: toSnakeKeys(requestParams.userPrompt),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/get-slides-quality`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
