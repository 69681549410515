import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { Label } from '../label';
import { CheckItem } from '../check-item';
import type { CheckItemT } from '../check-item';
import { Text } from '../text';
import { Tooltip, TriggerDescriptive, type TooltipSideT } from '../tooltip';

export interface CheckT extends CheckItemT {
  children?: React.ReactNode;
  label: React.ReactNode;
  meta?: string | undefined;
  description?: string | undefined;
  id: string;
  disabled?: boolean | undefined;
  tooltipPlacement?: TooltipSideT['side'];
  fontWeight?: 'semiBold' | 'regular';
  className?: string;
}

export const Check = ({
  label,
  meta,
  description,
  id,
  name,
  children,
  m,
  ml,
  mr,
  my,
  mt,
  mx,
  mb,
  disabled,
  tooltipPlacement = 'top',
  fontWeight = 'semiBold',
  className,
  ...rest
}: CheckT) => {
  const margins = { m, ml, mr, my, mt, mx, mb };

  return (
    <Box
      className={clsx('flex-row items-center w-full mb-0', className)}
      {...margins}
      data-testid="check-container"
    >
      <CheckItem
        name={name}
        id={id}
        {...(meta ? { 'aria-describedby': `aria-describedby-${id}` } : {})}
        disabled={disabled}
        {...rest}
      />

      <Box className="ml-3">
        <Box className="flex-row" id={`${id}-check-item`}>
          <Tooltip
            side={tooltipPlacement}
            className="max-w-[200px]"
            trigger={
              <Label
                htmlFor={id}
                className={clsx(
                  fontWeight === 'regular' ? 'font-normal' : 'font-semibold',
                  'mb-0',
                  disabled ? 'text-disabled' : 'text',
                )}
              >
                {label}
              </Label>
            }
          >
            {description && disabled ? description : null}
          </Tooltip>
          {description && !disabled ? (
            <Tooltip
              trigger={<TriggerDescriptive />}
              side={tooltipPlacement}
              id={`${id}-label-description`}
              className="max-w-[400px]"
            >
              {description}
            </Tooltip>
          ) : null}
        </Box>

        {meta ? (
          <Text
            id={`aria-describedby-${id}`}
            className="text-87.5 text-weaker leading-normal"
          >
            {meta}
          </Text>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};
