import * as React from 'react';
import { designSystemConfig, isThemeColor } from '@mentimeter/ragnar-dsc';
import { withTheme } from '@mentimeter/ragnar-react';
import { Svg } from '../svg';
import type { IconT, DefaultIconT } from '../legacy-system-icon';

function DefaultIcon({
  size = 2,
  color = 'text',
  'aria-label': ariaLabel,
  children,
  theme = designSystemConfig,
  viewBox = '0 0 16 16',
}: DefaultIconT) {
  return (
    <Svg
      viewBox={viewBox}
      aria-label={ariaLabel}
      height={typeof size === 'number' ? theme.fontSizes[size] || size : size}
      width={typeof size === 'number' ? theme.fontSizes[size] || size : size}
      fill="none"
    >
      <g color={isThemeColor(theme, color) ? theme.colors[color] : color}>
        {children}
      </g>
    </Svg>
  );
}

export const Icon = withTheme<DefaultIconT>(DefaultIcon);

/**
 * @deprecated use functional icon, pictogram, or question module icon equivalent instead
 */
export function OpenEndedIcon(props: IconT) {
  return (
    <Icon {...props}>
      <path
        d="M9.52482 2.86428V5.87919H8.00012C7.8021 5.87919 7.60602 5.91824 7.42311 5.99411C7.24021 6.06999 7.07406 6.18119 6.93418 6.32136C6.79431 6.46152 6.68345 6.6279 6.60796 6.81097C6.53247 6.99403 6.49382 7.19019 6.49423 7.3882V8.89409H4.99149V10.4031L3.48561 8.89409H1.97659C1.5772 8.89409 1.19417 8.73543 0.911766 8.45302C0.629358 8.17062 0.470703 7.78759 0.470703 7.3882L0.470703 2.86428C0.470703 2.4649 0.629358 2.08187 0.911766 1.79946C1.19417 1.51706 1.5772 1.3584 1.97659 1.3584H8.0158C8.21382 1.35799 8.40998 1.39664 8.59304 1.47213C8.77611 1.54762 8.94248 1.65848 9.08265 1.79835C9.22282 1.93823 9.33402 2.10438 9.40989 2.28728C9.48577 2.47019 9.52482 2.66626 9.52482 2.86428Z"
        fill="currentColor"
      />
      <path
        d="M15.5297 7.57022V11.9624C15.5297 12.1548 15.4918 12.3453 15.4182 12.5231C15.3445 12.7008 15.2366 12.8623 15.1006 12.9984C14.9645 13.1344 14.803 13.2423 14.6253 13.316C14.4475 13.3896 14.257 13.4275 14.0646 13.4275H12.5995L11.1375 14.902V13.4338H8.19166C7.99926 13.4338 7.80875 13.3959 7.63099 13.3222C7.45324 13.2486 7.29173 13.1407 7.15568 13.0046C7.01963 12.8686 6.91171 12.7071 6.83809 12.5293C6.76446 12.3516 6.72656 12.1611 6.72656 11.9687V7.5765C6.72822 7.18902 6.88331 6.81798 7.15789 6.54457C7.43247 6.27117 7.80418 6.11767 8.19166 6.11768H14.0646C14.451 6.11766 14.8218 6.2703 15.0962 6.54235C15.3706 6.8144 15.5264 7.18384 15.5297 7.57022Z"
        fill="currentColor"
      />
    </Icon>
  );
}

/**
 * @deprecated use functional icon, pictogram, or question module icon equivalent instead
 */
export function QuizIcon(props: IconT) {
  return (
    <Icon {...props}>
      <path
        d="M2.37179 10.6323L0.944336 15.0559L5.36786 13.6284L2.37179 10.6323Z"
        fill="currentColor"
      />
      <path
        d="M3.37305 9.60456L10.3365 2.64111L13.3649 5.66919L6.40112 12.6326L3.37305 9.60456Z"
        fill="currentColor"
      />
      <path
        d="M15.0025 2.99623L13.0198 0.997795C12.9555 0.930011 12.8782 0.876023 12.7924 0.839127C12.7067 0.802232 12.6143 0.783203 12.5209 0.783203C12.4276 0.783203 12.3352 0.802232 12.2494 0.839127C12.1637 0.876023 12.0863 0.930011 12.0221 0.997795L11.3633 1.64093L14.3594 4.63701L14.9868 4.00956C15.0581 3.94629 15.1154 3.86887 15.1552 3.78224C15.1949 3.69561 15.2162 3.60165 15.2177 3.50635C15.2192 3.41104 15.2008 3.31647 15.1638 3.22865C15.1267 3.14083 15.0718 3.06168 15.0025 2.99623Z"
        fill="currentColor"
      />
    </Icon>
  );
}
