import useSWR from 'swr';
import { core, type ThemeResponseT } from '@mentimeter/http-clients';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import {
  fromThemeResponseToThemeDefinition,
  getWorkspaceDefaultTheme,
} from '../utils/theme-list-factory';
import {
  DEFAULT_THEMES,
  DEFAULT_THEMES_NAME_LIST,
  type ThemeDefinition,
  type ThemeList,
} from '../types';

function filterCoreThemesByLocalList(coreTheme: ThemeResponseT): boolean {
  return !!DEFAULT_THEMES_NAME_LIST.find(
    (themeName: string) => themeName === coreTheme?.name?.toLowerCase(),
  );
}

function transformCoreThemeToThemeDefinition(
  coreTheme: ThemeResponseT,
): ThemeDefinition {
  return fromThemeResponseToThemeDefinition(coreTheme);
}

async function fetchThemesAndWorkspaceData() {
  const [{ data: coreThemeData }, { data: coreWorkspaceData }] =
    await Promise.all([
      core().themes.getAll(),
      core().workspaces.getActiveWorkspace(),
    ]);

  return { coreThemeData, coreWorkspaceData };
}

const availableThemeFetcher = async (): Promise<ThemeList> => {
  try {
    const { coreThemeData, coreWorkspaceData } =
      await fetchThemesAndWorkspaceData();

    const workspaceDefaultTheme = getWorkspaceDefaultTheme(
      coreThemeData,
      coreWorkspaceData,
    );

    const publicThemes = coreThemeData
      .filter(filterCoreThemesByLocalList)
      .map(transformCoreThemeToThemeDefinition);

    if (!workspaceDefaultTheme) {
      return publicThemes;
    }

    return [
      fromThemeResponseToThemeDefinition(workspaceDefaultTheme, true),
      ...publicThemes,
    ];
  } catch (error) {
    captureException(
      new MentiError('Failed to fetch theme data from core', {
        feature: 'ai-menti-builder',
        cause: error,
      }),
    );
    return DEFAULT_THEMES;
  }
};

export const useFetchAvailableThemes = () => {
  const {
    isLoading,
    data: availableThemes,
    error,
  } = useSWR('available-theme-fetcher', availableThemeFetcher);

  if (error) {
    return { availableThemes: DEFAULT_THEMES, isLoading: false };
  }

  return { availableThemes, isLoading };
};
