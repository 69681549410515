import React from 'react';
import { Piece } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';

export const ErrorScreen = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <Box alignItems="center">
      <Box width="150px" height="150px">
        <Piece variant="coral" />
      </Box>
      <Text
        textAlign="center"
        color="text"
        mb="space3"
        fontSize="125"
        fontFamily="heading"
        lineHeight="150"
      >
        Sorry, something went wrong. Please try again.
      </Text>

      <Text
        fontSize={2}
        fontWeight="regular"
        color="textWeaker"
        textAlign="center"
        mb={3}
      >
        If this keeps happening, try again a bit later. Thanks for your
        patience.
      </Text>

      <Button onClick={onRetry}>Try again</Button>
    </Box>
  );
};
