import type { IntentFormElements } from '../types';
import { commaSeparatedStringToArray } from './comma-seprated-to-array';
import { getProficiencyLevel } from './content-proficiency-level';
import { getToneOfVoice } from './tone-of-voice';

interface IntentObject {
  engagementType: string;
  topic: string;
  language: string;
  objective: string;
  specificRequests: string;
  keyPoints: string;
  contentProficiencyLevel: string;
  toneOfVoice: string;
  design: string;
  numberOfSlides: number;
  imageKeywords: string[];
  nonsensical: string;
  userInputs: UserInput[];
  isComplete: boolean;
}

interface UserInput {
  question: string;
  answer: string;
}

export function serializeIntent(intent: IntentFormElements): IntentObject {
  return {
    engagementType: intent['engagement-type'].value,
    topic: intent.topic.value,
    language: intent.language.value,
    objective: intent.objective.value,
    specificRequests: intent['specific-requests'].value,
    keyPoints: intent['key-points'].value,
    contentProficiencyLevel: getProficiencyLevel(
      intent['content-proficiency'].value,
    ),
    toneOfVoice: getToneOfVoice(intent['tone-of-voice'].value),
    design: intent['design-preferences'].value,
    numberOfSlides: parseInt(intent['number-of-slides'].value),
    imageKeywords: commaSeparatedStringToArray(intent['image-keywords'].value),
    nonsensical: 'Relevant',
    userInputs: [
      {
        question: 'What do you want to create today?',
        answer: '',
      },
    ],
    isComplete: true,
  };
}
