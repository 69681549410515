import type { FollowUpSchema, IntentSchema } from '@mentimeter/ai-api-streamer';
import type { MissingPromptKey } from '../tracking';

export function makeFinalPrompt(userInputs: IntentSchema['userInputs']) {
  if (!userInputs || userInputs?.length === 0) return '';

  return userInputs.reduce((acc, curr) => {
    if (!acc) return curr.answer;
    if (!curr.answer) return acc;

    return `${acc};${curr.answer}`;
  }, '');
}

export function makeImageKeywordString(
  imageKeywords: IntentSchema['imageKeywords'],
) {
  if (!imageKeywords || !imageKeywords.length) return '';

  return imageKeywords.join(';');
}

export function hasMissingEngagementOrTopic(
  intent: IntentSchema | undefined,
): MissingPromptKey {
  if (!intent?.engagementType) return 'Engagement type';
  if (!intent?.topic) return 'Topic';

  return '';
}

export function getFollowUpQuestion(followUp: FollowUpSchema | undefined) {
  if (!followUp?.question?.content) return '';

  return followUp.question.content;
}

export function sanitizeIntentPropertiesForMixpanel(
  intent: IntentSchema | undefined,
) {
  if (!intent) return {};

  /**
   * We are purposely ignoring the following properties:
   * - userInputs
   * - specificRequest
   * - objective
   * since they are not allowed to be sent to MixPanel
   * https://linear.app/mentimeter/issue/USE-848/tracking-updates
   */
  return {
    engagement_type: intent.engagementType,
    topic: intent.topic,
    language: intent.language,
    key_points: intent.keyPoints,
    content_proficiency_level: intent.contentProficiencyLevel,
    tone_of_voice: intent.toneOfVoice,
    design: intent.design,
    number_of_slides: intent.numberOfSlides,
    image_keywords: intent.imageKeywords,
    nonsensical: intent.nonsensical,
    is_complete: intent.isComplete,
  };
}
