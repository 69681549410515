// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toQueryParam } from '@api/internal';

export interface GetSuggestRequest {
  region: 'eu' | 'us';
  userAuth: string;
  imu?: 'business' | 'education';
  limit?: number;
}

export function getSuggestRequest(requestParams: GetSuggestRequest): Request {
  const queryParams = new URLSearchParams();
  if (requestParams.imu !== undefined) {
    queryParams.set('imu', toQueryParam(requestParams.imu));
  }
  if (requestParams.limit !== undefined) {
    queryParams.set('limit', toQueryParam(requestParams.limit));
  }

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/draft-builder/suggest?${queryParams.toString()}`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
