/* eslint-disable menti-react/filename-convention--jsx */
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading, Media, Paragraph, Section } from './styles';

export function WhatIsAMenti() {
  return (
    <Box>
      <Section>
        <Media
          mediaType="lottie"
          mediaUrl="https://boris.mentimeter.org/m/4f6af527ba79b676/original/Lottie_QuickGuide_WhatIsAMenti.lottie"
        />
      </Section>

      <Section>
        <Paragraph>
          A Menti is an interactive presentation, quiz, or survey where
          participants can provide real-time feedback or answers.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Create an interactive question</Heading>
        <Paragraph>
          Build your Menti from scratch in our easy to use editor, or use one of
          our pre-built templates.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Ask your participants to join via their phone</Heading>
        <Paragraph>
          Let your participants take part, either via a code, participation link
          or by scanning the QR code.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Visualize the results in real time</Heading>
        <Paragraph>{`Gather input from your audience and watch it appear in real time. And remember—it's fully anonymous!`}</Paragraph>
      </Section>
    </Box>
  );
}
