// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostCreateSeriesResponse {
  /** the job status */
  status: string;
  requestId: string;
}

export async function postCreateSeriesResponse(
  response: Response,
): Promise<PostCreateSeriesResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostCreateSeriesResponse;
}
