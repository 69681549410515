// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostGeneralPromptRequest {
  region: 'eu' | 'us';
  userAuth: string;
  userPrompt: string;
  activeSlideWithResults: {
    type: string;
    content: unknown;
    results: unknown;
  };
  ablyChannelId: string;
  requestId?: string;
}

export function postGeneralPromptRequest(
  requestParams: PostGeneralPromptRequest,
): Request {
  const data = {
    user_prompt: toSnakeKeys(requestParams.userPrompt),
    active_slide_with_results: toSnakeKeys(
      requestParams.activeSlideWithResults,
    ),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/general-prompt`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
