import React from 'react';
import { useTranslations } from '@mentimeter/i18n';
import { PaymentMethodType } from '@mentimeter/http-clients';
import type { PaymentMethodT } from '@mentimeter/billing';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';
import { Text } from '@mentimeter/ragnar-ui/text';

interface BankDetailsProps {
  bankDetails: PaymentMethodT | undefined;
  isFetchingBankDetails: boolean;
  noBankDetails: boolean;
}

export function BankDetails({
  bankDetails,
  isFetchingBankDetails,
  noBankDetails,
}: BankDetailsProps) {
  const t = useTranslations('checkoutUiPackage');

  // If we for some reason don't get bank details from the BE, we refer to the invoice for bank details
  if (noBankDetails)
    return (
      <Text variant="ragnarBodyMd">
        {t('request_invoice_success_modal.bank_details_on_invoice_info')}
      </Text>
    );

  return (
    <Box
      width="100%"
      p="space6"
      gap="space1"
      bg="secondaryWeakest"
      borderWidth="2px"
      borderRadius="xl"
      borderStyle="solid"
      borderColor="borderSecondaryWeakest"
    >
      <Text variant="dashboardHeadline5">
        {t('request_invoice_success_modal.details')}
      </Text>
      {isFetchingBankDetails && <Skeleton width={0.4} />}
      {bankDetails && bankDetails.type === PaymentMethodType.VirtualAccount && (
        <>
          <Text>IBAN: {bankDetails.iban}</Text>
          <Text>BIC: {bankDetails.bic}</Text>
          <Text>
            {t('request_invoice_success_modal.reference')}:{' '}
            {bankDetails.reference}
          </Text>
        </>
      )}
      {bankDetails && bankDetails.type === PaymentMethodType.BankAccount && (
        <>
          <Text>
            {t('request_invoice_success_modal.bank')}: {bankDetails.bank}
          </Text>
          <Text>
            {t('request_invoice_success_modal.address')}: {bankDetails.address}
          </Text>
          <Text>IBAN: {bankDetails.iban}</Text>
          <Text>Swift: {bankDetails.swift}</Text>
          <Text>
            {t('request_invoice_success_modal.reference')}:{' '}
            {bankDetails.reference}
          </Text>
          <Text>
            {t('request_invoice_success_modal.bank_account_name')}:{' '}
            {bankDetails.accountName}
          </Text>
        </>
      )}
    </Box>
  );
}
