import { create } from 'zustand';
import { v4 } from 'uuid';
import { z } from 'zod';
import { useSSE } from '@mentimeter/server-sent-events';
import React from 'react';
import {
  addBreadcrumb,
  type MentiErrorOptions,
} from '@mentimeter/errors/sentry';
import {
  parseSSEEventBase,
  parseSSEEventDataWithSchema,
} from './validators/sse-event-validator';
import { AiApiStreamerError, AiApiFetcherError } from './errors';

interface AiRequestCacheEntry {
  cacheKey: string;
  isLoading: boolean;
  error: AiApiFetcherError | AiApiStreamerError | undefined;
  data: any;
}

type AiApiError = AiApiFetcherError | AiApiStreamerError;
interface State {
  cache: Record<string, AiRequestCacheEntry>;
  updateCacheData: (data: {
    cacheKey: string;
    data?: unknown;
    loading?: boolean;
    error?: AiApiError | undefined;
  }) => void;
}

const useAiApiStore = create<State>((set) => ({
  cache: {},
  updateCacheData: ({ cacheKey, data, loading, error }) =>
    set((state) => {
      const cacheEntry = state.cache[cacheKey];
      state.cache[cacheKey] = {
        cacheKey,
        data: data ?? cacheEntry?.data,
        isLoading: Boolean(loading ?? cacheEntry?.isLoading),
        error: error ?? cacheEntry?.error,
      };
      return { cache: { ...state.cache } };
    }),
}));

interface Fetcher<Params> {
  (
    params: Params & { requestId: string; ablyChannelId: string },
  ): Promise<{ status: string; requestId: string }>;
}

interface Config<Schema extends z.ZodSchema> {
  streamUrl: string;
  ablyChannelId: string;
  validationSchema: Schema;
  feature: MentiErrorOptions['feature'];
  timeout?: number;
  onError?: (error: AiApiError) => void;
  onMessage?: (data: z.infer<Schema>) => void;
}

export interface Return<Params, Schema extends z.ZodSchema> {
  isReady: boolean;
  data: z.infer<Schema> | undefined;
  error: AiApiError | undefined;
  isLoading: boolean;
  sendRequest: (params: Params) => void;
}

export function useAi<Params, Schema extends z.ZodSchema>(
  cacheKey: string,
  fetcher: Fetcher<Params>,
  config: Config<Schema>,
): Return<Params, Schema> {
  const {
    streamUrl,
    ablyChannelId,
    validationSchema,
    feature,
    onError,
    onMessage,
    timeout = 30000,
  } = config;
  // Handle timeouts using refs to prevent dependency changes
  const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  const resetTimeoutRef = React.useRef(() => {
    if (timeoutRef.current) {
      addBreadcrumb({
        message: 'Reset timeout for AI API request',
        data: {
          feature,
          requestId: requestIdRef.current,
          timeoutId: timeoutRef.current,
        },
      });
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  });

  // Keep track of the request ID to match the response
  const requestIdRef = React.useRef<string | undefined>(undefined);

  // The cache which is shared between hook instances
  const cached = useAiApiStore((state) => state.cache[cacheKey]);
  const updateCacheData = useAiApiStore((state) => state.updateCacheData);

  // Handle incoming messages from the SSE
  const handleMessage = React.useCallback(
    ({ data: rawData }: MessageEvent<string>) => {
      try {
        const ablyEvent = parseSSEEventBase(rawData);

        // Check if the message is related to our request. If not, ignore it
        const sentRequestId = z
          .object({ request_id: z.string().optional() })
          .parse(JSON.parse(ablyEvent.data));
        if (sentRequestId.request_id !== requestIdRef.current) {
          return;
        }

        switch (ablyEvent.name) {
          case 'data': {
            const data = parseSSEEventDataWithSchema(
              ablyEvent.data,
              validationSchema,
            );
            updateCacheData({
              cacheKey,
              data,
              loading: false,
              error: undefined,
            });
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onMessage && onMessage(data);
            return;
          }
          case 'error': {
            const error = new AiApiStreamerError(
              'Error message sent from AI API',
              {
                feature,
              },
            );
            updateCacheData({
              cacheKey,
              loading: false,
              error,
            });
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onError && onError(error);
            return;
          }
        }
      } catch (e) {
        const error = new AiApiStreamerError(
          'Message validation failed from AI API',
          {
            feature,
            cause: e,
          },
        );
        updateCacheData({
          cacheKey,
          loading: false,
          error,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onError && onError(error);
      }
    },
    [cacheKey, feature, onError, updateCacheData, validationSchema, onMessage],
  );

  // The SSE connection
  const { listening } = useSSE(streamUrl, handleMessage, {
    connectOnInit: true,
  });

  // Call send request to initiate the request to the AI API and listen to the specific response
  const sendRequest = React.useCallback(
    async (params: Params) => {
      try {
        resetTimeoutRef.current();
        const requestId = v4();
        requestIdRef.current = requestId;
        updateCacheData({
          cacheKey,
          loading: true,
          error: undefined,
        });
        await fetcher({ ...params, requestId, ablyChannelId });
      } catch (e) {
        const error = new AiApiFetcherError('Unable to fetch to AI API', {
          feature,
          cause: e,
        });
        updateCacheData({
          cacheKey,
          loading: false,
          error,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onError && onError(error);
      }
    },
    [updateCacheData, cacheKey, fetcher, ablyChannelId, feature, onError],
  );

  // Set a timeout for the request based on the loading flag
  React.useEffect(() => {
    if (cached?.isLoading && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        const error = new AiApiStreamerError('Request timed out', {
          feature,
          tags: {
            ablyChannelId,
            requestId: requestIdRef.current ?? 'unknown',
          },
        });
        updateCacheData({
          cacheKey,
          loading: false,
          error,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onError && onError(error);
      }, timeout);
      addBreadcrumb({
        message: 'Started timeout for AI API request',
        data: {
          feature,
          timeoutId: timeoutRef.current,
          requestId: requestIdRef.current,
        },
      });
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      resetTimeoutRef.current();
    };
  }, [
    timeout,
    cached?.isLoading,
    cacheKey,
    updateCacheData,
    feature,
    onError,
    ablyChannelId,
  ]);

  return {
    isReady: listening,
    data: cached?.data,
    error: cached?.error,
    isLoading: cached?.isLoading ?? false,
    sendRequest,
  };
}
