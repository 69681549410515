import React from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  QuizIcon,
  SmartphoneIcon,
} from '@mentimeter/ragnar-visuals';
import { useRouter } from '@mentimeter/next-navigation';
import {
  CREATE_NEW_HREF,
  CreateNewMentiButton,
} from 'features/dashboard/CreateNewMentiButton';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  DropdownMenuContainer,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import { Text } from '@mentimeter/ragnar-ui/text';
import { trackCreatePresentation } from './CreateButton';

export function CreateNewGroup() {
  const [expanded, setExpanded] = React.useState(false);
  const router = useRouter();

  return (
    <DropdownMenuRoot modal open={expanded} onOpenChange={setExpanded}>
      <Box width={['100%', 'auto']} flexDirection="row" alignItems="stretch">
        <CreateNewMentiButton
          href={CREATE_NEW_HREF}
          onClick={() => {
            trackCreatePresentation('Create presentation');
            setExpanded(false);
          }}
          extend={({ theme }) => ({
            flex: '1 1 auto',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            paddingRight: `${theme.kosmosSpacing['space6']}px`,
          })}
        />
        <DropdownMenuTrigger>
          <Button
            variant="primary"
            onClick={() => setExpanded(!expanded)}
            p="space3"
            extend={({ theme }) => ({
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,

              '::before': {
                content: '""',
                display: 'block',
                width: '1px',
                backgroundColor: theme.colors.onPrimary,
                position: 'absolute',
                opacity: 0.4,
                height: `calc(100% - ${theme.kosmosSpacing['space2']}px)`,
                left: 0,
                zIndex: 2,
              },
            })}
          >
            {expanded ? (
              <ChevronUpIcon color="onPrimary" />
            ) : (
              <ChevronDownIcon color="onPrimary" />
            )}
          </Button>
        </DropdownMenuTrigger>
      </Box>

      <DropdownMenuContainer
        width="155px"
        mt="space1"
        p="space2"
        align="end"
        showArrow={false}
      >
        <CreateNewMenuItem
          onClick={() => {
            trackCreatePresentation('Create quiz');
            router.push(
              `${CREATE_NEW_HREF}?quick-create=quiz&series-title=New%20quiz`,
            );
            setExpanded(false);
          }}
          icon={<QuizIcon />}
          text="New quiz"
        />

        <CreateNewMenuItem
          onClick={() => {
            trackCreatePresentation('Create survey');
            router.push(
              `${CREATE_NEW_HREF}?quick-create=survey&series-title=New%20survey`,
            );
            setExpanded(false);
          }}
          icon={<SmartphoneIcon />}
          text="New survey"
        />
      </DropdownMenuContainer>
    </DropdownMenuRoot>
  );
}

function CreateNewMenuItem({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}) {
  return (
    <DropdownMenuItem onClick={onClick}>
      <Box
        width="100%"
        flexDirection="row"
        gap="space3"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box width="20px" justifyContent="center" alignItems="center">
          {icon}
        </Box>
        <Text>{text}</Text>
      </Box>
    </DropdownMenuItem>
  );
}
