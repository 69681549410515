/* eslint-disable menti-react/filename-convention--jsx */
import { Video } from '@mentimeter/motion';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Image } from '@mentimeter/ragnar-ui/image';
import { Text } from '@mentimeter/ragnar-ui/text';

export function Heading({ children }: { children: React.ReactNode }) {
  return (
    <Text fontSize={2} color="text" fontWeight="bold">
      {children}
    </Text>
  );
}

export function Paragraph({ children }: { children: React.ReactNode }) {
  return <Text>{children}</Text>;
}

export function Strong({ children }: { children: React.ReactNode }) {
  return <Text as="strong">{children}</Text>;
}

export function Emphasis({ children }: { children: React.ReactNode }) {
  return <Text as="em">{children}</Text>;
}

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <Box mb={4} width="100%">
      {children}
    </Box>
  );
}

export function ListRoot({ children }: { children: React.ReactNode }) {
  return (
    <Box width="100%" as="ul" pl="24px">
      {children}
    </Box>
  );
}

export function ListItem({ children }: { children: React.ReactNode }) {
  return <Text as="li">{children}</Text>;
}

const MAX_MEDIA_RESPONSIVE_WIDTH = '36vw';
const MIN_MEDIA_RESPONSIVE_WIDTH = '76vw';
const MIN_MEDIA_FIXED_WIDTH = '420px';

export function Media({
  mediaUrl,
  mediaType,
}: {
  mediaUrl: string;
  mediaType: 'video' | 'image' | 'embed' | 'lottie';
}) {
  const containerProps = {
    maxWidth: MAX_MEDIA_RESPONSIVE_WIDTH,
    minWidth: `min(${MIN_MEDIA_RESPONSIVE_WIDTH}, ${MIN_MEDIA_FIXED_WIDTH})`,
    margin: '0 auto',
  };

  if (mediaType === 'video') {
    return (
      <Video
        src={mediaUrl}
        play
        width="100%"
        extend={() => ({ aspectRatio: '16/9', ...containerProps })}
      />
    );
  } else if (mediaType === 'image') {
    return (
      <Box
        width="100%"
        borderWidth="2px"
        borderColor="borderWeak"
        borderRadius={1}
        borderStyle="solid"
        overflow="hidden"
        extend={() => ({ aspectRatio: '16/9', ...containerProps })}
      >
        <Image src={mediaUrl} width="100%" />
      </Box>
    );
  } else if (mediaType === 'embed') {
    return (
      <iframe
        width="100%"
        src={mediaUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        style={{ aspectRatio: '16/9', ...containerProps }}
      />
    );
  } else if (mediaType === 'lottie') {
    return (
      <DotLottiePlayer
        src={mediaUrl}
        autoplay
        loop
        style={{
          aspectRatio: '16/9',
          ...containerProps,
        }}
      />
    );
  }

  return null;
}
