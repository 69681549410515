// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface PostRealtimeTokenRequest {
  region: 'eu' | 'us';
  userAuth: string;
}

export function postRealtimeTokenRequest(
  requestParams: PostRealtimeTokenRequest,
): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/realtime-token`;
  return new Request(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
