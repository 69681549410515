import React, { useCallback, useState } from 'react';
import { useOnce } from '@mentimeter/react-hooks';
import {
  analyzeInputRequestV3,
  analyzeEventBodySchemaV3,
  StreamDataHandler,
  postGenerationMetaRequest,
} from '@mentimeter/ai-api-streamer';
import type {
  AiApiStreamerError,
  AnalyzeEventBodySchemaV3,
} from '@mentimeter/ai-api-streamer';
import { TrackingContext } from '@mentimeter/http-clients';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import type { AIBuilderStepState } from '../../../hooks';
import {
  aiStreamerErrorBreadcrumb,
  initialPromptRequestBreadcrumb,
  initialPromptResponseBreadcrumb,
  trackUserEvent,
} from '../../../tracking';
import { ErrorScreen } from '../../../components/error-screen/ErrorScreen';
import { LoaderOverlay } from '../../../components/loader/LoaderOverlay';
import { SuggestionTextareaPromptV3 } from '../../../components/suggestion-textarea-prompt-v3/SuggestionTextareaPromptV3';
import { trimTextFromPrompt } from './utils/trim-string-from-prompt';

const TIMEOUT_DURATION_IN_MS = 45_000;

interface InitialPromptStepProps {
  initialPrompt: string;
  suggestions: string[];
  shouldAnimate: boolean;
  ablyChannelId: string | undefined;
  ablyStreamUrl: string | undefined;
  userName?: string | undefined;
  setInitialPrompt: (prompt: string) => void;
  proceedToStep: (step: AIBuilderStepState) => void;
  setAnalyzeResponseState: (state: AnalyzeEventBodySchemaV3) => void;
  onRetry: () => void;
}

export const InitialPromptStep = ({
  initialPrompt,
  suggestions,
  shouldAnimate,
  ablyStreamUrl,
  ablyChannelId,
  userName,
  setInitialPrompt,
  proceedToStep,
  setAnalyzeResponseState,
  onRetry,
}: InitialPromptStepProps) => {
  const [isValidInput, setIsValidInput] = useState(false);
  const [requestState, setRequestState] = useState<
    'loading' | 'error' | 'idle'
  >('idle');
  const initialPromptQuestion = `Hello! Tell us what you want to do and we'll create a first draft.`;
  const [promptQuestion, setPromptQuestion] = useState(initialPromptQuestion);

  const promptStep = isValidInput ? 'nonsense' : 'initial';

  const handleSubmit = useCallback(
    async (
      prompt: string,
      suggestion: string | undefined,
      suggestionIndex: number | undefined,
    ) => {
      try {
        if (!ablyChannelId) {
          setRequestState('error');
          throw new Error('ablyChannelId is not defined');
        }

        setInitialPrompt(prompt);

        trackUserEvent({
          event: 'Submitted prompt AI Menti Builder',
          properties: {
            prompt_length: prompt.length,
            context: TrackingContext.UserHome,
            prompt_step: promptStep,
            suggestion,
            suggestion_position: suggestionIndex,
            modified_suggestion: !!suggestion && suggestion !== prompt,
            version: '3',
          },
        });

        initialPromptRequestBreadcrumb({
          ablyChannelId,
        });

        setRequestState('loading');
        await analyzeInputRequestV3({
          userInput: {
            question: trimTextFromPrompt(promptQuestion, `Hello, ${userName}.`),
            answer: prompt,
          },
          ablyChannelId,
        });
      } catch (error: unknown) {
        aiStreamerErrorBreadcrumb('Unexpected error on submit', {
          promptStep,
          ablyChannelId,
        });
        setRequestState('error');
      }
    },
    [ablyChannelId, setInitialPrompt, promptStep, promptQuestion, userName],
  );

  const handleInvalidInputResponse = useCallback(() => {
    onRetry();
    setPromptQuestion(
      "Please update your instructions and we'll generate a draft for you.",
    );
    setIsValidInput(true);
    setRequestState('idle');
  }, [onRetry]);

  const handleErrors = useCallback(
    (listening: boolean, hasAblyData: boolean) => {
      if (!listening || !hasAblyData) {
        setRequestState('error');
      }
    },
    [],
  );

  const handleAblyEvent = useCallback(
    (ablyEventData: AnalyzeEventBodySchemaV3, listening: boolean) => {
      handleErrors(listening, !!ablyEventData);

      setAnalyzeResponseState(ablyEventData);

      initialPromptResponseBreadcrumb({
        ablyChannelId,
      });

      postGenerationMetaRequest({
        data: {
          prompt_step: promptStep,
          ...ablyEventData,
        },
        aiSessionId: ablyChannelId || '',
        eventName: 'menti_builder/analyze_v3',
      });

      if (ablyEventData.isInvalidInput) {
        handleInvalidInputResponse();
        return;
      } else {
        proceedToStep('follow-up');
      }
    },
    [
      ablyChannelId,
      handleErrors,
      handleInvalidInputResponse,
      proceedToStep,
      promptStep,
      setAnalyzeResponseState,
    ],
  );

  const handleSuggestionClicked = useCallback(
    (suggestion: string, suggestionIndex: number) => {
      trackUserEvent({
        event: 'Clicked prompt suggestion AI Menti Builder',
        properties: {
          context: TrackingContext.UserHome,
          prompt_step: 'initial',
          suggestion,
          suggestion_position: suggestionIndex,
          version: '3',
        },
      });
    },
    [],
  );

  const handleError = useCallback(
    (aiApiStreamerError: AiApiStreamerError) => {
      const errorMessage =
        '[AI Menti Builder] Error while listening for events';

      aiStreamerErrorBreadcrumb(errorMessage, {
        promptStep,
        ablyChannelId,
      });

      captureException(
        new MentiError(errorMessage, {
          feature: 'ai-menti-builder',
          cause: aiApiStreamerError,
        }),
      );
      setRequestState('error');
    },
    [ablyChannelId, promptStep],
  );

  useOnce(requestState === 'error', () => {
    trackUserEvent({
      event: 'Viewed error message AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
        error_step: 'initial',
        version: '3',
      },
    });
  });

  if (requestState === 'error') {
    return (
      <ErrorScreen
        onRetry={() => {
          trackUserEvent({
            event: 'Clicked try again AI Menti Builder',
            properties: {
              context: TrackingContext.UserHome,
              version: '3',
            },
          });
          onRetry();
          setRequestState('idle');
        }}
      />
    );
  }

  if (!ablyStreamUrl) {
    return <LoaderOverlay />;
  }

  return (
    <StreamDataHandler<AnalyzeEventBodySchemaV3>
      schema={analyzeEventBodySchemaV3}
      ablyStreamUrl={ablyStreamUrl}
      onEvent={handleAblyEvent}
      startTimeout={requestState === 'loading'}
      timeoutInMs={TIMEOUT_DURATION_IN_MS}
      onError={handleError}
    >
      {() => (
        <SuggestionTextareaPromptV3
          placeholder="Include things like topic, type of meeting or what you want to achieve..."
          isLoading={requestState === 'loading'}
          label={promptQuestion}
          defaultValue={initialPrompt}
          suggestions={suggestions}
          shouldAnimate={shouldAnimate}
          onSubmit={handleSubmit}
          onSuggestionClicked={handleSuggestionClicked}
        />
      )}
    </StreamDataHandler>
  );
};
