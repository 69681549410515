import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Link } from '@mentimeter/ragnar-ui/link';

export const Layout = ({
  showDisclaimer,
  children,
}: React.PropsWithChildren<{ showDisclaimer?: boolean }>) => {
  return (
    <Box bg="bg" width="100%" height="100%">
      <Box flex="1 1 auto" />
      <Box
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box flex="1 1 auto" maxWidth="calc(90% - 120px)" alignItems="center">
          {children}
        </Box>
      </Box>
      <Box
        flex="1 1 auto"
        alignItems="center"
        justifyContent="end"
        width="100%"
      >
        {showDisclaimer && (
          <Text
            fontSize={2}
            fontWeight="regular"
            color="textWeaker"
            textAlign="center"
            mb={4}
          >
            AI-generated content is always improving and becoming more accurate.{' '}
            <Link
              href="https://help.mentimeter.com/en/articles/9061138-how-to-use-the-ai-menti-builder"
              target="_blank"
            >
              Learn more.
            </Link>
          </Text>
        )}
      </Box>
    </Box>
  );
};
