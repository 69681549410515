'use client';

import React, { useState } from 'react';
import {
  FilteredTemplatesModal,
  type PresentationType,
} from '@mentimeter/templates-modal/FilteredTemplatesModal';
import { useUser } from '@mentimeter/user';
import { useRouter } from '@mentimeter/next-navigation';
import {
  PRESENTATION_TYPE_TO_QUESTION_TYPE,
  getFirstMatchingQuestionByType,
} from '@mentimeter/templates-modal/FilteredTemplatesModal/filter-templates';
import {
  core,
  type PublicTemplateWithSeriesT,
  type Series,
  TrackingContext,
} from '@mentimeter/http-clients';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import {
  Wordcloud,
  MultipleChoice,
  OpenEnded,
  PinOnImage,
  Ranking,
  Scales,
} from '@mentimeter/ragnar-visuals';
import {
  blue500,
  coral300,
  green500,
  purple700,
} from '@mentimeter/ragnar-colors';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { HomeWidget } from '../shared/home-widget';
import { useBreakpoints } from '../shared/use-breakpoints';
import type { PopularFeature } from './types';
import { FeatureCard } from './feature-card';

const POPULAR_FEATURES: PopularFeature[] = [
  {
    presentationType: 'wordcloud',
    title: 'Word cloud',
    heading: 'Word clouds',
    subheading:
      'Collect responses and highlight the most common ones in a beautiful word cloud! A very popular way to start or end a session.',
    descriptor: 'word cloud',
    thumbnail: () => <Wordcloud />,
  },
  {
    presentationType: 'choices',
    title: 'Poll',
    heading: 'Polls',
    subheading:
      'Get votes on options you offer to gather input from participants, make decisions together, or break the ice.',
    descriptor: 'poll',
    thumbnail: () => <MultipleChoice userColor={blue500} />,
  },
  {
    presentationType: 'open-ended',
    title: 'Open ended',
    heading: 'Open ended questions',
    subheading:
      'Participants respond to a question using free text. A great way to voice honest thoughts and opinions!',
    descriptor: 'open ended question',
    thumbnail: () => <OpenEnded userColor={coral300} />,
  },
  {
    presentationType: 'scales',
    title: 'Scales',
    heading: 'Scales questions',
    subheading:
      'Let participants rate options or statements. If you reuse this question you will be able to see trends in the responses over time!',
    descriptor: 'scales question',
    thumbnail: () => <Scales />,
  },
  {
    presentationType: 'ranking',
    title: 'Ranking',
    heading: 'Ranking questions',
    subheading:
      'Let participants prioritize among different options by moving them up or down on their screens.',
    descriptor: 'ranking question',
    thumbnail: () => <Ranking userColor={green500} />,
  },
  {
    presentationType: 'pin-on-image',
    title: 'Pin on Image',
    heading: 'Pin on Image questions',
    subheading: 'Let participants pin points on an image.',
    descriptor: 'pin on image question',
    thumbnail: () => <PinOnImage userColor={purple700} />,
  },
];

const PRESENTATION_TYPE_TO_SERIES_ID: Partial<
  Record<PresentationType, Series['id']>
> = {
  wordcloud: 'almenmwjgr688fkwqofdqu5zrzzdnddf',
  choices: 'alhswjoimpu47tcv41p7om8hhypkukcz',
  'open-ended': 'alyazwh5eay3621au5qyeiovwkyqz7c7',
  scales: 'alkyqhamprxm64mah8uxs8we9z6uajoa',
  ranking: 'aly4m6f6ryn3bx5r3xfad8mdyj34xq1q',
  'pin-on-image': 'almwys9u3h3zaks282mxrqhf8o5a5qmi',
};

export function PopularFeatures({ cardOnBg = false }: { cardOnBg?: boolean }) {
  const [selectedPopularFeature, setSelectedPopularFeature] =
    useState<PopularFeature | null>(null);
  const { user } = useUser();
  const router = useRouter();

  const heading = `Popular features`;

  const { activeBreakpointIndex } = useBreakpoints();
  const itemsPerBreakpoint = [6, 5, 5, 6];
  const popularFeatures = POPULAR_FEATURES.slice(
    0,
    itemsPerBreakpoint[activeBreakpointIndex],
  );

  if (!user) return null;

  return (
    <HomeWidget.Root>
      <HomeWidget.Header heading={heading} />

      <Box
        width="100%"
        display="grid"
        gridTemplateColumns={[
          'repeat(auto-fill, minmax(100px, 1fr))',
          'repeat(5, 1fr)',
          'repeat(5, 1fr)',
          'repeat(6, 1fr)',
        ]}
        gap={['space4', 'space5']}
      >
        {popularFeatures.map((popularFeature, index) => {
          return (
            <FeatureCard
              key={index}
              popularFeature={popularFeature}
              cardOnBg={cardOnBg}
              onClick={async () => {
                trackUser({
                  event: 'Clicked popular feature',
                  properties: {
                    context: TrackingContext.UserHomePopularFeaturesWidget,
                    feature: popularFeature.presentationType,
                  },
                });
                setSelectedPopularFeature(popularFeature);
              }}
            />
          );
        })}
      </Box>

      {selectedPopularFeature && (
        <FilteredTemplatesModal
          open={Boolean(selectedPopularFeature)}
          mainUsage={toTemplatesUseCase(user.flags.onboarding)}
          filter={{
            presentationType: selectedPopularFeature.presentationType,
          }}
          heading="Start from scratch or a template"
          templateHeading={selectedPopularFeature.heading}
          templateSubheading={selectedPopularFeature.subheading}
          createBlankText={`Blank ${selectedPopularFeature.descriptor}`}
          mode="question-templates"
          addTemplateButtonState={undefined}
          modalOpeningContext="homeview"
          trackingContext={TrackingContext.UserHomePopularFeaturesWidget}
          onDismiss={() => {
            setSelectedPopularFeature(null);
          }}
          onAddTemplate={async (template) => {
            try {
              await createSingleQuestionPresentationFromTemplate(
                template,
                selectedPopularFeature.presentationType,
              );
            } catch (error) {
              captureException(
                new MentiError(
                  'createSingleQuestionPresentationFromTemplate failed',
                  {
                    cause: error,
                    feature: 'paid-user-growth',
                  },
                ),
              );
            }
          }}
          onCreateBlank={() => {
            try {
              duplicateBlankPopularFeatureTemplate(
                selectedPopularFeature.presentationType,
              );
            } catch (error) {
              captureException(
                new MentiError('duplicateBlankPopularFeatureTemplate failed', {
                  cause: error,
                  feature: 'paid-user-growth',
                }),
              );
            }
          }}
        />
      )}
    </HomeWidget.Root>
  );

  async function createSingleQuestionPresentationFromTemplate(
    template: PublicTemplateWithSeriesT,
    presentationType: PresentationType,
  ) {
    const questionType = PRESENTATION_TYPE_TO_QUESTION_TYPE[presentationType];

    if (!questionType)
      throw new Error(
        'Unknown presentation type found when mapping to question type: ' +
          presentationType,
      );

    const question = getFirstMatchingQuestionByType(template, questionType);

    if (!question)
      throw new Error(
        'No matching question found for template with id: ' +
          template.id +
          ' and question type: ' +
          questionType,
      );

    const { public_theme_name } = template.series;

    const { data: series } = await core().series.post({
      questions: [question],
      name: template.name,
      ...(public_theme_name && { public_theme_name }),
    });

    trackUser({
      event: 'Imported template',
      properties: {
        'First in series': true,
        Context: TrackingContext.UserHomePopularFeaturesWidget,
        Template: template.name,
        'Series Id': template.series_id,
      },
    });

    const path = buildPresentationPath({
      seriesId: series.id,
      questionId: series.questions[0]?.id ?? '0',
      mode: 'edit',
    });

    router.push(path);
  }

  async function duplicateBlankPopularFeatureTemplate(
    presentationType: PresentationType,
  ) {
    const seriesId = PRESENTATION_TYPE_TO_SERIES_ID[presentationType];

    if (!seriesId)
      throw new Error(
        'Unknown presentation type found when mapping to series id: ' +
          presentationType,
      );

    trackUser({
      event: 'Clicked create presentation',
      properties: {
        context: TrackingContext.UserHomePopularFeaturesWidget,
        placement: 'Popular features',
        'shared presentation': false,
      },
    });

    router.push(`/app/duplicate?series-id=${seriesId}`);
  }
}

function toTemplatesUseCase(
  onboarding: string | undefined,
): 'business' | 'education' {
  if (onboarding === 'education') return 'education';
  return 'business';
}
