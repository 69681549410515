import * as React from 'react';
import type { AnimationConfig } from 'lottie-web';
import lottie from 'lottie-web';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { useReducedMotion } from './utils/ReducedMotion';

export interface LottieT extends BoxT {
  path?: string;
  animationData?: any;
  play?: boolean;
  loop?: boolean;
  autoplay?: boolean;
  t?: number; // a number between 0.0-1.0 that can be used for interpolation
}

export const Lottie = ({
  animationData,
  path,
  play = false,
  loop = false,
  autoplay = false,
  t,
  ...rest
}: LottieT) => {
  const lottieContainer = React.useRef(null);
  const reducedMotion: boolean = useReducedMotion();

  // Initialize Lottie animation
  React.useEffect(() => {
    const goToAndStop = (instance: any, t: number) => {
      const isFrame = true;
      const position = Math.abs(t - 1.0);
      instance?.goToAndStop(position * instance?.totalFrames, isFrame);
    };
    if (!lottieContainer.current) return;
    const options: AnimationConfig = {
      container: lottieContainer.current,
      loop,
      autoplay,
      renderer: 'svg' as const,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin meet',
        hideOnTransparent: true,
        viewBoxOnly: true,
      },
    };

    const newLottieInstance = animationData
      ? lottie.loadAnimation({
          ...options,
          animationData,
        })
      : lottie.loadAnimation({
          ...options,
          path,
        });

    if (play && !reducedMotion) newLottieInstance.play();
    if (t && !reducedMotion) {
      goToAndStop(newLottieInstance, t);
    }
    if (reducedMotion) goToAndStop(newLottieInstance, 0);

    return () => newLottieInstance.destroy();
  }, [animationData, path, play, autoplay, loop, t, reducedMotion]);

  return <Box width="100%" ref={lottieContainer} {...rest} />;
};
