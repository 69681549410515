// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostCreateSeriesRequest {
  region: 'eu' | 'us';
  userAuth: string;
  intent: {
    engagementType?: string;
    topic?: string;
    language?: string;
    objective?: string;
    specificRequests?: string;
    keyPoints?: string;
    contentProficiencyLevel?: string;
    toneOfVoice?: string;
    design?: string;
    numberOfSlides?: number;
    imageKeywords?: Array<string>;
    nonsensical?: string;
    isComplete?: boolean;
    userInputs?: Array<{
      question: string;
      answer: string;
    }>;
  };
  ablyChannelId: string;
  requestId?: string;
}

export function postCreateSeriesRequest(
  requestParams: PostCreateSeriesRequest,
): Request {
  const data = {
    intent: toSnakeKeys(requestParams.intent),
    ably_channel_id: toSnakeKeys(requestParams.ablyChannelId),
    request_id: toSnakeKeys(requestParams.requestId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/ai-api/draft-builder/create-series`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
