// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostRequestAiAccessResponse {
  /** Confirmation message */
  message: string;
}

export async function postRequestAiAccessResponse(
  response: Response,
): Promise<PostRequestAiAccessResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostRequestAiAccessResponse;
}
