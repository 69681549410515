// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostRealtimeTokenResponse {}

export async function postRealtimeTokenResponse(
  response: Response,
): Promise<PostRealtimeTokenResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostRealtimeTokenResponse;
}
