import type React from 'react';
import type { ReactNode } from 'react';
import {
  type TrackingContext,
  TrackingPlacement,
  UserFeaturesTypeEnum,
} from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { gtmTrack } from '@mentimeter/google-tracking';
import { trackUser } from '@api/tracking/client';
import { Paywall } from '../../lib/paywall/Paywall';
import type {
  PaywallSeriesTrackingProperties,
  UpgradeEvent,
} from '../../types';
import { RequestUpgradePopover } from '../../lib/request-upgrade';
import { trackOpenPaywall } from '../../lib/tracking/track-open-paywall';
import { RecommendPlanModal } from '../../lib/self-upgrade/RecommendPlanModal';
import { useRecommendPlanPaywall } from '../../lib/hooks/useRecommendPlanPaywall';
import { ImportPaywallModal } from './ImportPaywallModal';

interface ImportPaywallProps {
  enabled: boolean;
  trackingContext: TrackingContext;
  trackingPlacement: string;
  trackingProperties?: PaywallSeriesTrackingProperties | undefined;
  upgradeEvent: UpgradeEvent;
  upgradeContext: 'Import document quick create button' | 'Import document';
  children: ReactNode;
}

const title =
  'Want to add slides from another tool and present from a single place?';
const description =
  'For you to import slides from Google, Powerpoint, Keynote or to upload a PDF, your account needs to be upgraded by an admin.';

export function ImportPaywallDataHandler({
  enabled,
  trackingContext,
  trackingPlacement,
  upgradeEvent,
  upgradeContext,
  children,
  trackingProperties,
}: ImportPaywallProps) {
  const { data: workspace } = useWorkspace();
  const { user } = useUser();

  const { showRecommendPlanPaywall } = useRecommendPlanPaywall();

  if (!user) return children as React.ReactElement;

  return (
    <Paywall
      enabled={enabled}
      requiredPlan={UserFeaturesTypeEnum.BASIC}
      requestUpgradeContent={
        <RequestUpgradePopover
          title={title}
          description={description}
          trackingContext={trackingContext}
          trackingPlacement={trackingPlacement}
        >
          {children}
        </RequestUpgradePopover>
      }
      selfUpgradeContent={
        showRecommendPlanPaywall ? (
          <RecommendPlanModal
            variant="import"
            trackingContext={trackingContext}
            trackingPlacement={TrackingPlacement.ImportDocumentQuickCreate}
            upgradeEvent={upgradeEvent}
          >
            {children}
          </RecommendPlanModal>
        ) : (
          <ImportPaywallModal
            onOpen={onOpenPaywall}
            trackClickUpgrade={trackClickUpgrade}
          >
            {children}
          </ImportPaywallModal>
        )
      }
    >
      {children}
    </Paywall>
  );

  function onOpenPaywall() {
    trackOpenPaywall({
      trackingContext,
      trackingPlacement,
      upgradeType: 'Self upgrade',
    });
  }

  function trackClickUpgrade() {
    gtmTrack({
      event: 'clickInteraction',
      type: 'upgradeFromApp',
    });

    trackUser({
      event: upgradeEvent,
      properties: {
        context: upgradeContext,
        'current plan': user?.features.type ?? 'registered',
        'active team members': workspace?.memberStats?.active ?? 0,
        ...trackingProperties,
      },
    });
  }
}
