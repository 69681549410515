import React from 'react';
import {
  createSeriesRequest,
  useAblySetup,
  useAi,
} from '@mentimeter/ai-api-streamer';
import { useUser } from '@mentimeter/user';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { SSEProvider } from '@mentimeter/server-sent-events';
import { useRouter } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/editor-essentials/hooks/use-editor-router';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ModalLayout } from './components/ModalLayout';
import { IntentForm } from './components/IntentForm';
import { serializeIntent } from './utils/serialize';
import { aiSeriesSchema } from './schemas/ai-series-schema';
import { useCreateSeriesFromAi } from './hooks/use-create-series-from-ai';

export function PersonalizedBuilderDataHandler({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUser();
  const { ablyChannel, ablyUrl } = useAblySetup(user?.id, {
    feature: 'draft-builder',
  });

  const ready = ablyChannel && ablyUrl;

  return (
    <ModalLayout trigger={children}>
      {ready && (
        <SSEProvider>
          <IntentFormDataHandler
            streamUrl={ablyUrl}
            ablyChannelId={ablyChannel}
          />
        </SSEProvider>
      )}
      {!ready && (
        <Box m="auto">
          <LoaderIcon size={3} />
        </Box>
      )}
    </ModalLayout>
  );
}

function IntentFormDataHandler({
  streamUrl,
  ablyChannelId,
}: {
  streamUrl: string;
  ablyChannelId: string;
}) {
  const router = useRouter();
  const { createSeries, error, loading } = useCreateSeriesFromAi({
    onSuccess: (newSeries) => {
      const path = buildPresentationPath({
        seriesId: newSeries.id,
        mode: 'edit',
      });

      router.push(path);
    },
  });

  const {
    sendRequest,
    isLoading: aiApiLoading,
    error: aiApiError,
  } = useAi('personalized-builder-request', createSeriesRequest, {
    feature: 'ai-menti-builder',
    streamUrl,
    ablyChannelId,
    validationSchema: aiSeriesSchema,
    onMessage: createSeries,
  });

  return (
    <IntentForm
      error={!!aiApiError || error}
      loading={aiApiLoading || loading}
      onSubmit={(elements) => {
        const data = serializeIntent(elements);

        sendRequest({
          intent: data,
          ablyChannelId,
        });
      }}
    />
  );
}
