'use client';

import {
  TrackingContext,
  type PublicTemplateT,
} from '@mentimeter/http-clients';
import { TemplateModal } from '@mentimeter/templates-modal/TemplateModal/TemplateModal';
import capitalize from 'lodash/capitalize';
import shuffle from 'lodash/shuffle';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ModalRoot } from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { ArrowLink } from '../shared/ArrowLink';
import { HomeWidget } from '../shared/home-widget';
import { HoverButtonPreviewCard } from '../shared/hover-card';
import { useBreakpoints } from '../shared/use-breakpoints';
import { useRecommendedTemplates } from './useRecommendedTemplates';
import { useTemplateModalMode } from './useTemplateModalMode';
import { TemplateSlidePreview } from './TemplateSlidePreview';

export const RecommendedTemplates = () => {
  const { templates, mainUsage } = useRecommendedTemplates();
  const { selectedTemplateMode, setSelectedTemplateMode } =
    useTemplateModalMode();

  const { activeBreakpointIndex } = useBreakpoints();

  const selectedTemplates = React.useMemo(() => {
    const itemsPerBreakpoint = [4, 3, 3, 4];
    return shuffle(templates).slice(
      0,
      itemsPerBreakpoint[activeBreakpointIndex],
    );
  }, [activeBreakpointIndex, templates]);

  const heading = `Popular templates in ${capitalize(mainUsage)}`;
  const renderModal = selectedTemplateMode.mode !== 'not-ready';
  const openModal = selectedTemplateMode.mode !== 'hidden';

  return (
    <>
      {renderModal && (
        <ModalRoot onOpenChange={onDismissTemplateModal} open={openModal}>
          <TemplateModal
            key={selectedTemplateMode.mode}
            open={openModal}
            isNewPresentation={false}
            selectedTemplate={
              selectedTemplateMode.mode === 'template'
                ? selectedTemplateMode.data
                : undefined
            }
            selectedCategory={
              selectedTemplateMode.mode === 'template-category'
                ? selectedTemplateMode.data
                : undefined
            }
            trackingContext="User home template widget"
            modalOpeningContext={TrackingContext.UserHome}
          />
        </ModalRoot>
      )}

      <HomeWidget.Root>
        <HomeWidget.Header heading={heading}>
          <ArrowLink
            actionText="See all templates"
            shortActionText="See all"
            onAction={onSeeAllTemplates}
          />
        </HomeWidget.Header>

        <Box
          width="100%"
          display="grid"
          gridTemplateColumns={[
            'repeat(auto-fit, minmax(300px, 1fr))',
            'repeat(3, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
          ]}
          gap={['space8', 'space5']}
        >
          {selectedTemplates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              onSelectTemplate={onSelectTemplate}
            />
          ))}
        </Box>
      </HomeWidget.Root>
    </>
  );

  function onSelectTemplate(template: PublicTemplateT) {
    setSelectedTemplateMode({ mode: 'template', data: template });
  }

  function onDismissTemplateModal() {
    setSelectedTemplateMode({ mode: 'hidden' });
  }

  function onSeeAllTemplates() {
    setSelectedTemplateMode({ mode: 'show-all' });
  }
};

export const TemplateCard = ({
  template,
  onSelectTemplate,
}: {
  template: PublicTemplateT;
  onSelectTemplate: (template: PublicTemplateT) => void;
}) => {
  return (
    <HoverButtonPreviewCard
      onClick={() => onSelectTemplate(template)}
      aria-label={template.name}
    >
      <Box
        borderRadius="lg"
        overflow="hidden"
        width="100%"
        position="relative"
        extend={({ theme }) => ({
          '@media (hover: hover)': {
            ':hover': {
              '&:after': {
                borderRadius: 'inherit',
                boxShadow: `inset 0 0 0 2px ${theme.colors.secondary}`,
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
          },
        })}
      >
        <TemplateSlidePreview
          seriesId={template.series_id}
          displaySlideId={template.preview_question_admin_key}
        />
      </Box>
      <Text fontSize={2} truncate mt="space4" color="text">
        {template.name}
      </Text>
    </HoverButtonPreviewCard>
  );
};
