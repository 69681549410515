import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { core } from '@mentimeter/http-clients';
import React from 'react';
import useSWR from 'swr';
import { cacheKey } from './cache-keys';
import type { AvailableProperties, StepName, Steps } from './types';

const fetcher = async () => {
  try {
    const { data } = await core().get(cacheKey);
    return data;
  } catch (e) {
    captureException(
      new MentiError('Failed to fetch user steps', {
        feature: 'user-success',
        cause: e,
      }),
    );
    throw e;
  }
};

export function useUserJourney<A extends StepName, B extends StepName>(
  stepIds: [A, B],
  enabled = true,
  options?: { byPropertyKey?: AvailableProperties },
) {
  const { data, ...swr } = useSWR<{
    steps: Steps[];
  }>(enabled ? cacheKey : null, fetcher);

  const { fulfilled, inProgress, steps } = React.useMemo(() => {
    if (!data?.steps) return { fulfilled: false, steps: [] };

    // A
    const a = data.steps.find((step) => step?.name === stepIds[0]);

    // B
    const b = data.steps.find((step) => {
      const correctName = step?.name === stepIds[1];

      // If we have a property key, we need to check that they are equal
      const samePropertyConstraint = options?.byPropertyKey
        ? step?.properties[options.byPropertyKey] ===
          a?.properties[options.byPropertyKey]
        : true;

      return correctName && samePropertyConstraint;
    });

    const fulfilled = { a: Boolean(a), b: Boolean(b) };

    return {
      fulfilled: fulfilled.a && fulfilled.b,
      inProgress: fulfilled.a && !fulfilled.b,
      steps: [a, b],
    };
  }, [data?.steps, options?.byPropertyKey, stepIds]);

  return { fulfilled, inProgress, steps, ...swr };
}
