import React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';

export function useBreakpoints() {
  const { theme } = useRagnar();
  const breakpoints = theme.breakpoints;

  const getActiveBreakpoint = React.useCallback(() => {
    return (
      breakpoints.find((breakpoint) => {
        // We need to subtract 1px from the breakpoint to align with other Ragnar sizing.
        return window.matchMedia(`(max-width: calc(${breakpoint} - 1px))`)
          .matches;
      }) ?? breakpoints[breakpoints.length - 1]! // Fallback to the largest breakpoint. Breakpoints are always defined.
    );
  }, [breakpoints]);

  const [activeBreakpoint, setActiveBreakpoint] = React.useState<string>(
    getActiveBreakpoint(),
  );

  React.useEffect(() => {
    const handleResize = () => {
      setActiveBreakpoint(getActiveBreakpoint());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getActiveBreakpoint]);

  return {
    activeBreakpointIndex: breakpoints.indexOf(activeBreakpoint),
    activeBreakpoint,
    breakpoints,
  };
}
