import type { Dispatch, SetStateAction } from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { P, Strong } from '@mentimeter/ragnar-ui/typography';

export interface DeleteSharedTemplateProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  itemName: string;
}
export function DeleteSharedTemplateModal({
  open,
  setOpen,
  itemName,
}: DeleteSharedTemplateProps) {
  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer>
        <ModalHeader>Cannot delete shared presentation</ModalHeader>
        <ModalBody>
          <P>
            You have shared <Strong>{itemName}</Strong> as a template in your
            organization. To delete it, you need to first unshare it.
          </P>
        </ModalBody>
        <ModalFooter>
          <ModalDismiss>
            <Button variant="primary">Got it!</Button>
          </ModalDismiss>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
}
