import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { core } from '@mentimeter/http-clients';
import { AxiosError } from 'axios';
import useSWR, { type SWRConfiguration } from 'swr';

export const useLatestResultsData = (
  seriesId: string | undefined,
  config: SWRConfiguration = {
    revalidateOnFocus: false,
  },
) => {
  return useSWR(
    seriesId ? ['latest-results', seriesId] : null,
    async ([_, id]) => {
      try {
        const { data } = await core().series.get(id);
        return data;
      } catch (e) {
        if (e instanceof AxiosError) {
          captureException(
            new MentiError('Failed to load series for latest results', {
              feature: 'user-success',
              cause: e,
            }),
          );
        }
        throw e;
      }
    },
    config,
  );
};
