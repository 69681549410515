import { CONTENT_PROFICIENCY_LEVEL_MAP } from '../constants/content-proficiency-level';

export function isProficiencyLevel(
  value: string,
): value is keyof typeof CONTENT_PROFICIENCY_LEVEL_MAP {
  return value in CONTENT_PROFICIENCY_LEVEL_MAP;
}

export function getProficiencyLevel(value: string) {
  if (!isProficiencyLevel(value)) {
    throw new Error(`Invalid proficiency level: ${value}`);
  }
  return CONTENT_PROFICIENCY_LEVEL_MAP[value];
}
