// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostMetaResponse {
  status: string;
}

export async function postMetaResponse(
  response: Response,
): Promise<PostMetaResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostMetaResponse;
}
