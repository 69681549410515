import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export interface HomeWidgetRootPropsT {
  children?: React.ReactNode;
}

export function Root({ children }: HomeWidgetRootPropsT) {
  return <Box width="100%">{children}</Box>;
}
