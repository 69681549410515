import { useCallback, useState } from 'react';

export type AIBuilderStepState = 'prompt' | 'follow-up' | 'theme' | 'building';
const StateOrder: Array<AIBuilderStepState> = [
  'prompt',
  'follow-up',
  'theme',
  'building',
];

const canGoBackMap: Record<AIBuilderStepState, boolean> = {
  prompt: false,
  'follow-up': true,
  theme: false,
  building: false,
};

export const useAiBuilderSteps = (
  initialState: AIBuilderStepState = 'prompt',
) => {
  const [step, setStep] = useState<AIBuilderStepState>(initialState);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(true);

  const back = useCallback(() => {
    const currentStateIndex = StateOrder.indexOf(step);
    const previousState = StateOrder[currentStateIndex - 1] ?? 'prompt';
    setShouldAnimate(false);
    setStep(previousState);
    return previousState;
  }, [step]);

  const proceedToStep = useCallback(
    (stateToTransitionTo: AIBuilderStepState) => {
      setShouldAnimate(true);
      setStep(stateToTransitionTo);
    },
    [],
  );

  return {
    step,
    back: canGoBackMap[step] ? back : undefined,
    proceedToStep,
    shouldAnimate,
  };
};
