/* eslint-disable menti-react/filename-convention--jsx */
import { Link } from '@mentimeter/next-navigation';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading, Media, Paragraph, Section } from './styles';

export function CreatingYourFirstMenti() {
  return (
    <Box>
      <Section>
        <Media
          mediaType="embed"
          mediaUrl="https://www.youtube-nocookie.com/embed/on_Ib7SP6Go"
        />
      </Section>
      <Section>
        <Heading>Start from scratch or with a template</Heading>
        <Paragraph>
          On the Home page you can create a{' '}
          <Link href="https://menti.new/">new Menti</Link> from scratch or
          quickstart a presentation, quiz, or survey. You can also{' '}
          <Link href="https://www.mentimeter.com/templates">
            browse our templates
          </Link>{' '}
          to get inspiration.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Add slides to your Menti</Heading>
        <Paragraph>
          Build your Menti with questions, content, and more. Add a fun Quiz
          competition, or upload slides from other online tools, such as{' '}
          <Link href="https://help.mentimeter.com/en/articles/6445677-embed-a-powerpoint-presentation-into-mentimeter">
            PowerPoint
          </Link>
          ,{' '}
          <Link href="https://help.mentimeter.com/en/articles/6445389-embed-a-google-slides-presentation-into-mentimeter">
            Google Slides
          </Link>{' '}
          or{' '}
          <Link href="https://www.mentimeter.com/templates/p/miro-integrations-for-brainstorming">
            Miro
          </Link>
          .
        </Paragraph>
      </Section>

      <Section>
        <Heading>Want more help getting started?</Heading>
        <Paragraph>
          If you want some more help getting started with Mentimeter, you can
          view our <Link href="https://www.youtube.com/@Mentimeter">Video</Link>{' '}
          tutorials or enroll in our free self-paced courses in the{' '}
          <Link href="https://academy.mentimeter.com/">Mentimeter Academy</Link>
          .
        </Paragraph>
      </Section>
    </Box>
  );
}
