import { type ReactFlagEvaluationOptions } from '@openfeature/react-sdk';
import { useFlag as useOpenFeatureFlag } from '../../../openfeature/client';
import type { FlagKey, FlagValue } from '../types';

export function useFlag<K extends FlagKey>(
  key: K,
  defaultFlagValue: FlagValue<K>,
  options?: ReactFlagEvaluationOptions,
) {
  const { value } = useOpenFeatureFlag<K, FlagValue<K>>(
    key,
    defaultFlagValue,
    options,
  );

  return {
    value,
  };
}
