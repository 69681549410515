import React from 'react';
import { PlusIcon, Piece } from '@mentimeter/ragnar-visuals';
import type { TrackingContext } from '@mentimeter/http-clients';
import { TrackingPlacement } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Layout } from '../Layout';

export const FeatureAtCapacity = ({
  trackingContext,
}: {
  trackingContext: TrackingContext;
}) => {
  return (
    <Layout>
      <Box alignItems="center">
        <Box width="150px" height="150px">
          <Piece variant="purple" />
        </Box>
        <Text
          textAlign="center"
          color="text"
          mb="space3"
          fontSize="125"
          fontFamily="heading"
          lineHeight="150"
        >
          Sorry, this feature is at capacity. <br />
          Please check back later.
        </Text>

        <Text
          fontSize={2}
          fontWeight="regular"
          color="textWeaker"
          textAlign="center"
          mb={4}
        >
          In the meantime, you can also make a Menti with one of our templates.
        </Text>

        <Button
          href="/app/new"
          iconLeading={PlusIcon}
          variant="primary"
          onClick={trackClickNewMenti}
        >
          New Menti
        </Button>
      </Box>
    </Layout>
  );

  function trackClickNewMenti() {
    trackUser({
      event: 'Clicked create presentation',
      properties: {
        context: trackingContext,
        placement: TrackingPlacement.AIFeatureAtCapacityModal,
        target: 'New Menti',
      },
    });
  }
};
