import type React from 'react';
import { captureException } from '@mentimeter/errors/sentry';
import { AiApiStreamerError } from '../errors';

export function clearAsyncRequestTimeout(
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
) {
  if (timeoutRef.current) clearTimeout(timeoutRef.current);
}

export function createErrorHandlerTimeout(
  timeoutInMs: number,
  handleError?: (timeoutError: AiApiStreamerError) => void | undefined,
) {
  return setTimeout(() => {
    const error = new AiApiStreamerError('AI Stream listener timed out', {
      feature: 'user-success',
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    handleError && handleError(error);
    captureException(error);
  }, timeoutInMs);
}
