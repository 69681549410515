import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { HoverClickableCard } from '../../shared/hover-card';
import type { PopularFeature } from '../types';

export interface FeatureCardProps {
  popularFeature: PopularFeature;
  onClick: () => void;
  cardOnBg?: boolean;
}

export function FeatureCard({
  popularFeature,
  onClick,
  cardOnBg = false,
}: FeatureCardProps) {
  const Thumbnail = popularFeature.thumbnail;

  return (
    <HoverClickableCard
      aria-label={popularFeature.heading}
      onClick={onClick}
      borderRadius="2xl"
    >
      <Box
        overflow="hidden"
        bg="bg"
        mb="space3"
        alignItems="center"
        justifyContent="center"
        borderRadius="2xl"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={cardOnBg ? 'borderWeak' : 'bg'}
        width="100%"
        p="space4"
        extend={({ theme }) => ({
          '@media (hover: hover)': {
            '&:hover': {
              borderColor: theme.colors.secondary,
              boxShadow: `0 0 0 1px ${theme.colors.secondary} inset`,
            },
          },
          '& svg': {
            width: '100%',
            maxWidth: '160px',
            height: 'auto',
          },
        })}
      >
        <Thumbnail />
      </Box>

      <Text
        variant="dashboardBodyMd"
        color="text"
        truncate
        title={popularFeature.title}
        textAlign="center"
        width="100%"
      >
        {popularFeature.title}
      </Text>
    </HoverClickableCard>
  );
}
