import { MentiError } from '@mentimeter/errors/sentry';
import User from '@mentimeter/user';

export function getUserAuth(): { region: 'eu' | 'us'; userToken: string } {
  const region = User.region;
  const userToken = User.getToken();
  if (!region || !userToken)
    throw new MentiError('User not authenticated', {
      feature: 'user-success',
    });
  return { region, userToken };
}
