import type { CreateSeriesEventBodySchemaV3 } from '@mentimeter/ai-api-streamer';
import {
  type Question,
  type SeriesPost,
  TrackingContext,
} from '@mentimeter/http-clients';
import { useOnce } from '@mentimeter/react-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { PostSeriesResponse } from '@core-api/ai-builder/ai-builder/series';
import { getSlideDeckPublicKeyFromResponse } from '@mentimeter/migration-utils';
import {
  aiBuilderErrorBreadcrumb,
  coreCreateSeriesResponseBreadcrumb,
  trackUserEvent,
} from '../../../../tracking';
import { ThemePicker } from '../../../../components/theme-picker/ThemePicker';
import { ProgressBar } from '../../../../components/progress-bar/ProgressBar';
import {
  transformSlidesFromAiSchemaToCoreQuestionSchema,
  uploadImagesFromSeriesQuestions,
} from './utils';
import { postCreateSeriesWithQuestions } from './http/create-series';
import { calculateGenerationTime } from './utils/calculate-generation-time';
import { mapQuestionsToUpload } from './utils/map-series-questions-to-questions';

interface SeriesBuilderProps {
  onSeriesCreationDone: (
    seriesId: string,
    questionId: string,
    generationLoadTime: number,
  ) => void;
  shouldAnimate: boolean;
  onStart: () => void;
  data: CreateSeriesEventBodySchemaV3 | undefined | null;
  loaderDurationInMs?: number;
  onError: () => void;
}

export const SeriesBuilder = ({
  data,
  onSeriesCreationDone,
  onStart,
  loaderDurationInMs,
  shouldAnimate,
  onError,
}: SeriesBuilderProps) => {
  const [questions, setQuestions] = useState<Array<Partial<Question>>>([]);
  const [seriesData, setSeriesData] = useState<Partial<SeriesPost>>({});
  const [isSeriesCreated, setIsSeriesCreated] = useState(false);

  const startedRef = useRef(false);
  const generationTimeStart = useRef<Date | undefined>();

  const [themeId, setThemeId] = useState<string | number | undefined>();

  const handleThemePicked = useCallback(
    async ({
      themeId,
      publicThemeName,
    }: {
      themeId: number | undefined;
      publicThemeName: string | undefined;
    }) => {
      setThemeId(publicThemeName ?? themeId);
    },
    [],
  );

  useEffect(() => {
    if (!startedRef.current) {
      onStart();
      generationTimeStart.current = new Date();
      startedRef.current = true;
    }
  }, [onStart]);

  useEffect(() => {
    try {
      if (!data || !data.slides) {
        return;
      }

      const slides = data.slides;

      const resultQuestions = transformSlidesFromAiSchemaToCoreQuestionSchema(
        slides,
        questions,
      );
      setQuestions(resultQuestions);

      setSeriesData({
        name: data.name,
        pace: data.pace ? 'audience' : 'presenter',
        qfa_active: !!data.qna_feature,
        comments_enabled: !!data.comments,
      });
    } catch (error) {
      const errorMessage =
        '[AI Menti Builder] Failed to transform AI slide schema to Core question schema.';

      aiBuilderErrorBreadcrumb(errorMessage, {
        data,
      });

      captureException(
        new MentiError(errorMessage, {
          feature: 'user-success',
          cause: error,
        }),
      );
      onError();
    }
  }, [data, questions, onError]);

  const hasQuestions = questions.length > 0;

  useOnce(hasQuestions && !!themeId, async () => {
    try {
      const responseSeriesWithSlideDeck = await postCreateSeriesWithQuestions({
        seriesData,
        questions,
        themeIdOrPublicName: themeId,
      });

      const getQuestionsImage = (
        seriesOrSlideDeck: PostSeriesResponse,
      ): Question[] => {
        if (seriesOrSlideDeck.series) {
          return seriesOrSlideDeck.series.questions.map(
            (seriesQuestion): Question =>
              ({
                id: seriesQuestion.id,
              }) as Question,
          );
        }

        return [];
      };

      const seriesId = getSlideDeckPublicKeyFromResponse(
        responseSeriesWithSlideDeck,
        {
          feature: 'ai-menti-builder',
        },
      );
      const coreApiResultQuestions = getQuestionsImage(
        responseSeriesWithSlideDeck,
      );

      coreCreateSeriesResponseBreadcrumb({ seriesId });

      await uploadImagesFromSeriesQuestions(
        seriesId,
        mapQuestionsToUpload(questions, coreApiResultQuestions),
      );

      const [firstQuestion, ..._rest] = coreApiResultQuestions;

      if (!firstQuestion) {
        throw new Error('Created empty series');
      }

      const generationLoadingTime = calculateGenerationTime(
        generationTimeStart.current,
      );

      setIsSeriesCreated(true);
      onSeriesCreationDone(seriesId, firstQuestion.id, generationLoadingTime);
    } catch (error: unknown) {
      const errorMessage =
        '[AI Menti Builder] Question Producer failed to create series and slides.';

      aiBuilderErrorBreadcrumb(errorMessage, {
        seriesData,
      });

      captureException(
        new MentiError(errorMessage, {
          feature: 'ai-menti-builder',
          cause: error,
        }),
      );
      throw error;
    }
  });

  useOnce(!!themeId, () => {
    trackUserEvent({
      event: 'Viewed slides generator loading AI Menti Builder',
      properties: {
        context: TrackingContext.UserHome,
        version: '3',
      },
    });
  });

  if (!themeId) {
    return (
      <ThemePicker
        shouldAnimate={shouldAnimate}
        onThemePicked={handleThemePicked}
        version="3"
      />
    );
  }

  return (
    <ProgressBar
      shouldFinish={isSeriesCreated}
      progressText="Generating your draft now..."
      loaderDurationInMs={loaderDurationInMs}
    />
  );
};
