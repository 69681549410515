import React from 'react';
import {
  AlignLeftIcon,
  CheckIcon,
  PlaySolidIcon,
} from '@mentimeter/ragnar-visuals';
import { useRagnar } from '@mentimeter/ragnar-react';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { HoverClickableCard } from '../shared/hover-card';
import type { GuideT } from './useGuide';

export function GuideCard({
  guide,
  onRead,
}: {
  guide: GuideT;
  onRead(guide: GuideT): void;
} & BoxT) {
  const { renderer } = useRagnar();
  const { read, readRecently, timeUntilRead } = getReadTimingInfo(guide.readAt);
  const cardColor = read ? 'positiveWeakest' : 'secondaryWeakest';
  const textColor = read ? 'onPositiveWeakest' : 'onSecondaryWeakest';

  const keyframes =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '0%': {
          transform: 'scale(0)',
          opacity: 0,
        },
        '25%': {
          transform: 'scale(1.6)',
        },
        '50%': {
          transform: 'scale(.8)',
          opacity: 1,
        },
        '70%': {
          transform: 'scale(1.2)',
        },
        '100%': {
          transform: 'scale(1)',
          opacity: 1,
        },
      }),
      {},
    );

  return (
    <HoverClickableCard
      aria-label={guide.title}
      onClick={() => {
        onRead(guide);
      }}
      borderRadius="xl"
      flexDirection={['row', 'row', 'column']}
      alignItems={['center', 'flex-start']}
      px="space5"
      py="space5"
      bg={cardColor}
      extend={({ theme }) => ({
        '@media (hover: hover)': {
          ':hover': {
            opacity: 1,
            boxShadow: `0 0 0 2px ${
              read ? theme.colors.positive : theme.colors.secondary
            } inset`,
          },
        },
      })}
    >
      <Box
        width="40px"
        height="40px"
        flexDirection="row"
        alignItems={['center', 'flex-start']}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          justifyContent="center"
          overflow="hidden"
          borderRadius={3}
          extend={() => ({
            opacity: read ? 0 : 1,
            transition: 'opacity .3s ease',
          })}
        >
          {guide.type === 'text' && (
            <AlignLeftIcon size={4} color={textColor} />
          )}
          {guide.type === 'video' && (
            <Box left="-3px">
              <PlaySolidIcon size={4} color={textColor} />
            </Box>
          )}
        </Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          justifyContent="center"
          overflow="hidden"
          borderRadius={3}
          extend={() => ({
            ...(timeUntilRead > 0 || readRecently
              ? {
                  opacity: 0,
                  transform: 'scale(0)',

                  animationDelay:
                    timeUntilRead > 0 ? `${timeUntilRead}ms` : '0ms',
                  animationDuration: '1000ms',
                  animationIterationCount: '1',
                  animationTimingFunction: 'linear',
                  animationName: keyframes,
                  animationFillMode: 'forwards',
                }
              : read
                ? { opacity: 1, transform: 'scale(1)' }
                : { opacity: 0, transform: 'scale(0)' }),
          })}
        >
          <CheckIcon size={4} color={textColor} />
        </Box>
      </Box>
      <Box mx={['space2', 'space0', 'space0']} flex="1 1 auto">
        <Text color={textColor} variant="dashboardLabelMd" textAlign="left">
          {guide.title}
        </Text>
        <Text color="textWeaker" variant="dashboardBodySm">
          {guide.estimatedReadingTimeMinutes} min{' '}
          {guide.type === 'text' && 'read'}
          {guide.type === 'video' && 'watch'}
        </Text>
      </Box>
    </HoverClickableCard>
  );
}

function getReadTimingInfo(readAt: string | undefined) {
  if (!readAt) {
    return {
      read: false,
      readRecently: false,
      timeUntilRead: 0,
    };
  } else {
    const timeSinceRead = Date.now() - Date.parse(readAt);
    const timeUntilRead = timeSinceRead > 0 ? 0 : -timeSinceRead;
    const readRecently = timeSinceRead > 0 && Math.abs(timeSinceRead) < 2000;

    return { read: true, readRecently, timeUntilRead };
  }
}
