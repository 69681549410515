const DEFAULT_THEMES = [
  'Menti Light',
  'Menti Dark',
  'True blue',
  'Lovely lavender',
  'Leafy green',
  'Mellow yellow',
  'Perfect pink',
  'Soft shadows',
];

export function getRandomTheme() {
  const theme =
    DEFAULT_THEMES[Math.floor(Math.random() * DEFAULT_THEMES.length)];

  return theme ?? 'Menti Light';
}
